import React from "react"
import { useNavigate } from "react-router-dom"
import classNames from "classnames"
import { CustomButton } from "../../common"
import { GA_EVENTS } from "../../../constants/analytics"
import { FormattedMessage } from "react-intl"

const TrialConfirmationPage = () => {
  const navigate = useNavigate()

  const handleNext = () => {
    navigate("/job-match/select-cv")
  }

  return (
    <div className="payment-page payment-page--confirmation">
      <div className="payment-page__wrapper">
        <div className="payment-page__content">
          <div className="payment-confirmation">
            <p className="payment-confirmation__title">
              <span>
                <FormattedMessage id="common.thankYou" />,
              </span>
              <br /> <FormattedMessage id="common.freeWeekStarted" />
              <br />
            </p>

            <p className="payment-confirmation__subscription-info">
              <FormattedMessage id="message.jobMatchInfo" />
            </p>
            <p className="payment-confirmation__subscription-info">
              <FormattedMessage id="message.pricingPlanChoice" />
            </p>

            <p className="payment-confirmation__welcome-text">
              <FormattedMessage id="message.enjoyFribl" />
            </p>

            <CustomButton
              className={classNames(
                "payment-confirmation__next-btn-trial btn primary btn--small",
              )}
              onClick={handleNext}
              trackingEvent={GA_EVENTS.USER_REGISTRATION.FREE_TRIAL_CONFIRM}
            >
              <span className="payment-confirmation__next">
                <FormattedMessage id="common.next" />
              </span>
              &nbsp; <FormattedMessage id="common.uploadCVsToStart" />
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrialConfirmationPage
