import { createIntl, createIntlCache } from "react-intl"
import messages_en from "./lang/en.json"
import messages_fr from "./lang/fr.json"
import messages_es from "./lang/es.json"
import messages_de from "./lang/de.json"
import messages_it from "./lang/it.json"
import { getCookie } from "../util/util"

const messages = {
  en: messages_en,
  fr: messages_fr,
  es: messages_es,
  de: messages_de,
  it: messages_it,
}

const cache = createIntlCache()
const currentLanguage = () => getCookie("NEXT_LOCALE") || "en"

const intl = () =>
  createIntl(
    {
      locale: currentLanguage(),
      messages: messages[currentLanguage()],
    },
    cache,
  )
export default intl
