import React, { useState } from "react"
import { useForm } from "react-hook-form"

import { Checkbox, CustomButton, SignupInput } from "../../../common"
import { BLACKLIST_EMAIL_DOMAINS } from "../../../../constants"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import classNames from "classnames"
import { signup } from "../../../../services/auth"
import { setForm } from "../../../../redux/slices/signupSlice"
import { GA_EVENTS } from "../../../../constants/analytics"
import { FormattedMessage, useIntl } from "react-intl"

const FirstStep = ({ next }) => {
  const signupForm = useSelector((state) => state.signup)
  const [loading, setLoading] = useState(false)
  const { formatMessage } = useIntl()

  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
    getValues,
    setError,
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: signupForm.name ?? "",
      email: signupForm.email ?? "",
      password: signupForm.password ?? "",
      agreed: false,
    },
  })

  watch(["name", "email", "password", "agreed"])

  const onFormSubmit = (values) => {
    setLoading(true)
    signup({
      email: values.email,
      name: values.name,
      password: values.password,
    })
      .then(() => {
        toast.success(formatMessage({ id: "toast.checkEmailToCompleteSignUp" }))
        dispatch(setForm({ name: "email", value: values.email }))
        next?.()
      })
      .catch(() => {
        setError("email", {
          message: formatMessage({ id: "common.emailAlreadyExists" }),
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const values = getValues()

  return (
    <div className="create-account">
      <div className="create-account__wrapper">
        <h1 className="create-account__title mb-15">
          <FormattedMessage id="common.welcomeToFribl" />
        </h1>

        <div className="create-account__content">
          <div className="create-account__inputs">
            <SignupInput
              register={register("name", {
                required: formatMessage({ id: "common.nameRequired" }),
                pattern: {
                  value: /^[a-zA-Z]+ [a-zA-Z]+(?: [a-zA-Z]+)* *$/,
                  message: formatMessage({
                    id: "message.enterBothFirstAndLastName",
                  }),
                },
              })}
              placeholder={formatMessage({ id: "common.yourName" })}
              error={errors.name?.message}
              touched={touchedFields.name}
              value={values.name}
              label={formatMessage({ id: "common.name" })}
            />
            <SignupInput
              register={register("email", {
                required: formatMessage({ id: "common.emailRequired" }),
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                  message: formatMessage({
                    id: "common.somethingDoesntLookRight",
                  }),
                },
                validate: (value) => {
                  const domain = value.split("@")[1]

                  if (BLACKLIST_EMAIL_DOMAINS.includes(domain)) {
                    return formatMessage({ id: "message.notGmailOrPersonal" })
                  }
                },
              })}
              placeholder={formatMessage({ id: "common.workEmail" })}
              error={errors.email?.message}
              touched={touchedFields.email}
              value={values.email}
              helperText={formatMessage({ id: "message.notGmailOrPersonal" })}
              type="email"
              label={formatMessage({ id: "common.email" })}
            />
            <SignupInput
              register={register("password", {
                required: formatMessage({ id: "common.passwordRequired" }),
                pattern: {
                  value: /^(?=.*\d).{8,}$/,
                  message: formatMessage({
                    id: "message.checkPasswordRequirements",
                  }),
                },
              })}
              placeholder={formatMessage({ id: "common.chooseYourPassword" })}
              error={errors.password?.message}
              touched={touchedFields.password}
              value={values.password}
              type="password"
              helperText={formatMessage({
                id: "message.passwordSafetyRequirement",
              })}
            />

            <Checkbox
              register={register("agreed", {
                required: formatMessage({ id: "common.agreedRequired" }),
                validate: (value) => value === true,
              })}
              label={
                <>
                  <FormattedMessage id="message.confirmReadAgreed" />{" "}
                  <a
                    target={"_blank"}
                    rel={"noreferrer"}
                    href={"/terms-of-use"}
                  >
                    <FormattedMessage id="common.termsAndConditionsAndPrivacyPolicies" />
                  </a>
                </>
              }
              value={values.agreed}
            />
          </div>

          <CustomButton
            trackingEvent={GA_EVENTS.USER_REGISTRATION.CREATE_CONTINUE}
            className={classNames("btn btn--small primary mt-15", {
              disabled: !isValid || loading,
            })}
            onClick={handleSubmit(onFormSubmit)}
          >
            <FormattedMessage id="common.continue" />
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

export default FirstStep
