import React from "react"
import classNames from "classnames"

const Spinner = ({ className, variant = "primary" }) => {
  return (
    <div className={classNames("spinner__container", className)}>
      <div
        className={classNames(
          "spinner",
          {
            "spinner--primary": variant === "primary",
            "spinner--secondary": variant === "secondary",
          },
          className,
        )}
      ></div>
    </div>
  )
}

export default Spinner
