import React from "react"
import Select from "react-select"
import Flag from "react-flagkit"
import { COUNTRIES } from "../../../../constants"

const CountryFlagSelect = ({ value, error, onChange }) => {
  const options = COUNTRIES.map((country) => ({
    value: country.code,
    label: country.mobileCode,
  }))

  // Custom option component with country flag
  const CustomOption = ({ innerProps, label, value }) => (
    <div {...innerProps}>
      <span className="signup-input__select-option">
        <Flag country={value} />
        {label} ({value})
      </span>
    </div>
  )

  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      isSearchable
      openMenuOnFocus
      styles={{
        container: (provided, state) => ({
          width: 80,
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "var(--darkest, #002060)",
          fontSize: 17,
          fontWeight: 700,
          textAlign: "center",
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          height: 60,
          width: 65,
        }),
        control: (provided, state) => ({
          ...provided,
          border: "none",
          outline: error
            ? "6px solid var(--warning-orange, #f63)"
            : state.isFocused
            ? "6px solid var(--strong, #5ec58f)"
            : "none",
          borderRadius: 9,
        }),
      }}
      components={{ Option: CustomOption, IndicatorsContainer: () => null }}
    />
  )
}

export default CountryFlagSelect
