import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import {
  getIntegrationsAction,
  getMoreThirdPartyCandidatesAction,
  getThirdPartyCandidatesAction,
  getThirdPartyJobsAction,
  importThirdPartyCandidatesAction,
} from "../../../redux/slices/integrationSlice"
import { Checkbox, ConfirmationDialog, Select, Spinner } from "../../common"
import { Candidate } from "./components"
import classNames from "classnames"
import { getCredits } from "../../../services/creditService"
import { setCredits } from "../../../redux/slices/creditSlice"
import { setCvs } from "../../../redux/slices/cvSlice"
import { PER_PAGE_GREENHOUSE_CANDIDATES } from "../../../constants"
import { FormattedMessage, useIntl } from "react-intl"

const ImportCVPage = () => {
  const credits = useSelector((state) => state.credit)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const {
    integrations,
    loading,
    candidates,
    hasMoreCandidates,
    jobs,
    candidatesLoading,
    jobsLoading,
    importCandidatesLoading,
    fetchMoreCandidatesLoading,
  } = useSelector((state) => state.integrations)
  const [selectedJob, setSelectedJob] = useState(null)
  const [selectedCandidates, setSelectedCandidates] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (!integrations) {
      dispatch(getIntegrationsAction())
    }
  }, [])

  useEffect(() => {
    // Navigate to integration page if no integrations
    const hasGreenhouseIntegration = integrations?.find(
      (int) => int.name === "greenhouse",
    )

    if (integrations) {
      if (integrations.length === 0 || !hasGreenhouseIntegration)
        navigate("/ats-integration")
    }

    if (hasGreenhouseIntegration) {
      dispatch(getThirdPartyJobsAction())
    }
  }, [integrations, dispatch, navigate])

  useEffect(() => {
    if (selectedJob) {
      dispatch(getThirdPartyCandidatesAction({ job_id: selectedJob.value }))
    }
  }, [selectedJob, dispatch])

  const handleCandidateSelect = (id) => {
    const index = selectedCandidates.findIndex((i) => i === id)
    if (index !== -1) {
      const newCandidates = [...selectedCandidates]
      newCandidates.splice(index, 1)
      setSelectedCandidates(newCandidates)
    } else {
      setSelectedCandidates([...selectedCandidates, id])
    }
  }

  const handleImportCVs = async () => {
    setOpenConfirmationDialog(false)
    const response = await dispatch(
      importThirdPartyCandidatesAction({
        candidates: selectedCandidates.map((candidate) =>
          candidates.find((c) => c.id === candidate),
        ),
      }),
    )

    dispatch(setCvs(null))

    if (response.payload.success) {
      toast.success(
        formatMessage({
          id: "toast.candidatesImportedSuccessfully",
          values: {
            number: response.payload.success,
          },
        }),
      )

      // Refetch credits
      getCredits().then((credits) => {
        dispatch(setCredits(credits))
      })

      setSelectedCandidates([])
    }

    response.payload.failed.forEach((candidate) => {
      toast.error(
        formatMessage({
          id: "toast.candidateCVAlreadyExists",
          values: { candidateName: candidate.name },
        }),
      )
    })
  }

  const fetchMoreCandidates = () => {
    dispatch(
      getMoreThirdPartyCandidatesAction({
        job_id: selectedJob.value,
        page: Math.ceil(candidates.length / PER_PAGE_GREENHOUSE_CANDIDATES) + 1,
      }),
    )
  }

  const handleSelectAllChange = (e) =>
    e.target.checked
      ? setSelectedCandidates(candidates.map((c) => c.id))
      : setSelectedCandidates([])

  const jobOptions = jobs?.map((job) => ({ label: job.information.title, value: job.id }))

  const importDisabled =
    selectedCandidates.length === 0 ||
    importCandidatesLoading ||
    selectedCandidates.legth > credits.cvCredits

  return (
    <div className="import-cv">
      <div className="import-cv__wrapper">
        {loading && <Spinner />}

        {integrations?.length > 0 && (
          <>
            <p className="h3 import-cv__title">
              {" "}
              <FormattedMessage id="common.importCVs" />
            </p>

            {jobsLoading && <Spinner />}

            {jobs?.length === 0 && (
              <p className="p">
                <FormattedMessage id="message.noJobsFoundInATS" />
              </p>
            )}

            {jobs?.length > 0 && (
              <>
                <p className="p p--bold text-fribl-blue">
                  <FormattedMessage id="common.selectJobToPullCandidatesFrom" />:
                </p>
                <Select
                  value={selectedJob}
                  onChange={(newValue) => setSelectedJob(newValue)}
                  name={"job"}
                  placeholder={formatMessage({
                    id: "common.job",
                  })}
                  options={jobOptions}
                  label={formatMessage({
                    id: "common.job",
                  })}
                />

                <div className="import-cv__candidates">
                  {candidatesLoading && <Spinner />}

                  {selectedJob && (
                    <>
                      {!candidatesLoading && candidates?.length === 0 && (
                        <p className="p p--bold text-fribl-blue">
                          <FormattedMessage id="message.noCandidatesFoundInATS" />
                        </p>
                      )}
                      {!candidatesLoading && candidates?.length > 0 && (
                        <>
                          <p className="p text-fribl-blue">
                            <FormattedMessage id="common.selectCandidatesToImport" />
                          </p>
                          <div className="import-cv__candidates-list">
                            <div className="select-all">
                              <Checkbox
                                variant="secondary"
                                value={
                                  selectedCandidates.length ===
                                  candidates.length
                                }
                                label={formatMessage({
                                  id: "common.selectAll",
                                })}
                                onChange={handleSelectAllChange}
                              />
                            </div>

                            {candidates?.map((candidate) => (
                              <Candidate
                                selectedCandidates={selectedCandidates}
                                candidate={candidate}
                                key={candidate.id}
                                handleSelect={handleCandidateSelect}
                              />
                            ))}

                            {fetchMoreCandidatesLoading ? (
                              <Spinner />
                            ) : (
                              hasMoreCandidates && (
                                <button
                                  onClick={fetchMoreCandidates}
                                  className="btn quinary btn--small"
                                >
                                  <FormattedMessage id="common.fetchMore" />
                                </button>
                              )
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}

            {importCandidatesLoading && <Spinner />}

            <div className="import-cv__actions">
              <button
                disabled={importDisabled}
                className={classNames("btn primary btn--small", {
                  disabled: importDisabled,
                })}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                <FormattedMessage
                  id="common.importSelectedCVs"
                  values={{
                    numberSelectedCandidates: selectedCandidates.length,
                  }}
                />
              </button>

              <p>
                <FormattedMessage
                  id="common.useCVSCredits"
                  values={{
                    numberSelectedCandidates: selectedCandidates.length,
                    cvCredits: credits.cvCredits,
                  }}
                />
              </p>
            </div>
          </>
        )}
      </div>
      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        onConfirm={handleImportCVs}
        title={formatMessage({
          id: "common.importCVs",
        })}
        message={formatMessage({
          id: "message.confirmImportCVs",
          values: {
            numberSelectedCandidates: selectedCandidates.length,
          },
        })}
      />
    </div>
  )
}

export default ImportCVPage
