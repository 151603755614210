import React from "react"
import { Controller } from "react-hook-form"
import classNames from "classnames"
import CountryFlagSelect from "./components/CountryFlagSelect"
import { FormattedMessage, useIntl } from "react-intl"

const PhoneNumberInput = ({
  errors,
  isValidating = false,
  isValid,
  touchedFields,
  values,
  phoneNumberRegister,
  control,
  showEditButton = false,
  editable,
  setEditable,
  label,
  disabled = false,
}) => {
  const { formatMessage } = useIntl()
  const showPhoneNumberSuccessSvg =
    isValid &&
    touchedFields.phoneNumber &&
    !errors.phoneNumber &&
    values.phoneNumber &&
    (showEditButton ? editable : true)

  const showCountryCodeSuccessSvg =
    isValid &&
    !errors.countryCode &&
    values.countryCode &&
    (showEditButton ? editable : true)

  return (
    <div className="signup-input">
      <div className="signup-input__phone-wrapper">
        <Controller
          control={control}
          rules={{
            required: formatMessage({ id: "common.countryCodeRequired" }),
          }}
          name="countryCode"
          render={({ field: { onChange, value } }) => (
            <CountryFlagSelect
              error={errors.countryCode}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <input
          className={classNames({ error: !isValidating && (errors.phoneNumber) })}
          placeholder={formatMessage({ id: "common.phoneNumber" })}
          id={"phoneNumber"}
          name={"phoneNumber"}
          disabled={(showEditButton && !editable) || disabled || isValidating}
          {...phoneNumberRegister}
        />
        <label htmlFor="phoneNumber">{label}</label>
      </div>

      {errors.phoneNumber && (
        <p className="signup-input__error-message">
          {errors.phoneNumber?.message}
        </p>
      )}
      {errors.countryCode && (
        <p className="signup-input__error-message">
          {errors.countryCode?.message}
        </p>
      )}

      {showPhoneNumberSuccessSvg && showCountryCodeSuccessSvg && successSvg}
      {showEditButton && !editable && (
        <p
          className="signup-input__password-toggle"
          role={"button"}
          onClick={() => setEditable(true)}
          tabIndex={0}
          aria-label={"edit"}
        >
          <FormattedMessage id="common.edit" />
        </p>
      )}
    </div>
  )
}

const successSvg = (
  <svg width="25" height="26" viewBox="0 0 25 26" fill="none">
    <rect y="0.739258" width="25" height="25" rx="12.5" fill="#5EC58F" />
    <path
      d="M12.5788 18.1119L16.7598 10.8702L15.1828 9.95976L11.8104 15.8008L9.0507 14.2075L8.24205 15.6081L12.5788 18.1119Z"
      fill="white"
    />
  </svg>
)

export default PhoneNumberInput
