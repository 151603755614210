import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { CustomButton, CustomLink, SignupInput } from "../../common"
import { forgotPassword } from "../../../services/auth"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import classNames from "classnames"
import { GA_EVENTS } from "../../../constants/analytics"
import { FormattedMessage, useIntl } from "react-intl"

function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
    getValues,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  })

  watch(["email"])

  const values = getValues()

  const onSubmit = (data) => {
    if (loading) {
      return
    }
    setLoading(true)
    forgotPassword(data)
      .then(() => {
        toast.success(formatMessage({ id: "toast.passwordResetEmailSent" }))
        navigate("/login")
      })
      .catch((err) => {
        const error = err.response.data?.message
        toast.error(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="login-page">
      <div className="login-page__wrapper">
        <h1 className="login-page__title ">
          <FormattedMessage id="common.forgottenYourPassword" />, <br />
          <span>
            <FormattedMessage id="common.letUsHelpYou" />
          </span>
        </h1>
        <form className="login-page__content" onSubmit={handleSubmit(onSubmit)}>
          <div className="login-page__inputs">
            <SignupInput
              register={register("email", {
                required: formatMessage({ id: "common.emailRequired" }),
              })}
              placeholder={formatMessage({ id: "common.email" })}
              error={errors.email?.message}
              touched={touchedFields.email}
              value={values.email}
              type="email"
            />
            <CustomLink
              trackingEvent={GA_EVENTS.USER_SESSION.BACK_TO_LOGIN}
              className={"login-page__link"}
              to={"/login"}
            >
              <FormattedMessage id="common.backToLogin" />
            </CustomLink>
          </div>

          <CustomButton
            trackingEvent={GA_EVENTS.USER_SESSION.FORGOT_PASSWORD}
            className={classNames("btn btn--small primary", {
              disabled: !isValid || loading,
            })}
            onClick={handleSubmit(onSubmit)}
          >
            <FormattedMessage id="common.forgotPassword" />
          </CustomButton>
        </form>
      </div>
    </div>
  )
}

export default ForgotPasswordPage
