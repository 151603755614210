import React, { useState } from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"

import { Checkbox, Modal, Spinner } from "../../../common"
import { fromNow } from "../../../../util/util"
import { deleteCvs } from "../../../../services/cv"
import { setCvs } from "../../../../redux/slices/cvSlice"
import { statuses } from "../SelectCVPage"
import { setSelectedCVs } from "../../../../redux/slices/jobSlice"
import { FormattedMessage, useIntl } from "react-intl"

const CVItem = ({
  cv,
  selected,
  onChange,
  isFile,
  setFiles,
  viewLink,
  readOnly = false,
}) => {
  const selectedCVs = useSelector((state) => state.jobs.selectedCVs)

  const [hovered, setHovered] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const cvs = useSelector((state) => state.cv.cvs)
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)

  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const handleDeleteConfirm = async () => {
    try {
      const id = showDeleteConfirmModal

      if (!id) {
        return
      }
      setDeleteLoading(true)

      await deleteCvs([id])
      const thisCv = cvs.find((cv) => cv.id === id)
      toast.success(
        formatMessage(
          { id: "toast.cvDeleted" },
          {
            fileName: `${thisCv.information.first_name ?? ""} ${
              thisCv.information.last_name ?? ""
            }'s CV`,
          },
        ),
      )

      dispatch(setSelectedCVs(selectedCVs.filter((cv) => cv !== id)))
      dispatch(setCvs(cvs.filter((cv) => cv.id !== id)))
    } catch (err) {
      toast.error(
        err?.response?.data?.message ??
          formatMessage({ id: "toast.failedToDeleteCV" }),
      )
    }
    setShowDeleteConfirmModal(false)
    setDeleteLoading(false)
  }

  const handleDeleteClick = () => {
    if (isFile) {
      setFiles((files) => files.filter((file) => file.fileName !== cv.fileName))
    } else {
      setShowDeleteConfirmModal(cv.id)
    }
  }

  const source = cv.source?.greenhouse
    ? formatMessage({ id: "common.importedFromGreenhouse" })
    : cv.source?.lever
    ? formatMessage({ id: "common.importedFromLever" })
    : null

  return (
    <>
      <div
        onMouseLeave={() => setHovered(false)}
        onMouseEnter={() => setHovered(true)}
        className={classNames("cv-item", {
          "cv-item--pending": cv.status !== "COMPLETED",
          "cv-item--with-source": source,
          "cv-item--readonly": readOnly,
        })}
        onClick={(e) => {
          if (
            ["P", "DIV"].includes(e.target.tagName) &&
            cv.status === "COMPLETED"
          )
            onChange({ id: cv.id, checked: !selected })
        }}
      >
        {(hovered || selected) && cv.status === "COMPLETED" && !readOnly && (
          <Checkbox
            variant="secondary"
            value={selected}
            onChange={(e) => onChange({ id: cv.id, checked: e.target.checked })}
          />
        )}

        <div className="cv-item__info">
          <p
            className={classNames("cv-item__name", {
              "cv-item__name--selected": selected,
            })}
          >
            {cv.information.file_name
              ? cv.information.file_name
              : `${cv.information.first_name ?? ""} ${
                  cv.information.last_name
                }`}
          </p>

          {source && <span className="cv-item__source">{source}</span>}

          {hovered && cv.status === "COMPLETED" && (
            <Link className="cv-item__link" to={viewLink}>
              <FormattedMessage id="common.view" />
            </Link>
          )}
        </div>

        <div className="cv-item__actions">
          {isFile ? (
            <p
              className={classNames({
                "cv-item__status--failed": cv.status === statuses.FAILED,
              })}
            >
              {cv.status}
            </p>
          ) : cv.status !== "COMPLETED" ? (
            <p className="cv-item__status">
              <FormattedMessage id="common.processing" />
            </p>
          ) : (
            !hovered &&
            cv.date && (
              <p
                className={classNames({
                  "cv-item__status--selected": selected,
                })}
              >
                {fromNow(cv.date)}
              </p>
            )
          )}

          {readOnly && cv.date && hovered && (
            <p
              className={classNames({
                "cv-item__status--selected": selected,
              })}
            >
              {fromNow(cv.date)}
            </p>
          )}

          {hovered && !readOnly && (
            <button
              onClick={handleDeleteClick}
              className="btn btn--small btn--icon"
            >
              {deleteIcon}
            </button>
          )}
        </div>
      </div>
      <Modal
        open={Boolean(showDeleteConfirmModal)}
        setOpen={setShowDeleteConfirmModal}
        className={"delete-dialog"}
      >
        <div className="delete-dialog__container">
          <h2 className="text-2xl font-bold">
            <FormattedMessage id="common.confirmDelete" />
          </h2>
          <p>
            <FormattedMessage id="common.areYouSure" />
          </p>
          {deleteLoading && <Spinner />}
          <div className="flex gap-2 delete-dialog__buttons">
            <button
              className={classNames("btn btn--small primary mr-2", {
                disabled: deleteLoading,
              })}
              onClick={handleDeleteConfirm}
              disabled={deleteLoading}
            >
              <FormattedMessage id="common.yes" />
            </button>
            <button
              className="btn btn--small"
              onClick={() => setShowDeleteConfirmModal(false)}
            >
              <FormattedMessage id="common.no" />
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const deleteIcon = (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.106934 3.6873H1.56533H13.2325" fill="white" />
    <path
      d="M11.7741 3.6873V13.8961C11.7741 14.2829 11.6205 14.6538 11.347 14.9273C11.0735 15.2008 10.7025 15.3545 10.3157 15.3545H3.02373C2.63694 15.3545 2.26599 15.2008 1.99249 14.9273C1.71898 14.6538 1.56533 14.2829 1.56533 13.8961V3.6873M3.75293 3.6873V2.22891C3.75293 1.84211 3.90658 1.47117 4.18008 1.19766C4.45359 0.92416 4.82454 0.770508 5.21133 0.770508H8.12812C8.51492 0.770508 8.88587 0.92416 9.15937 1.19766C9.43287 1.47117 9.58652 1.84211 9.58652 2.22891V3.6873"
      fill="white"
    />
    <path
      d="M0.106934 3.6873H1.56533M1.56533 3.6873H13.2325M1.56533 3.6873V13.8961C1.56533 14.2829 1.71898 14.6538 1.99249 14.9273C2.26599 15.2008 2.63694 15.3545 3.02373 15.3545H10.3157C10.7025 15.3545 11.0735 15.2008 11.347 14.9273C11.6205 14.6538 11.7741 14.2829 11.7741 13.8961V3.6873M3.75293 3.6873V2.22891C3.75293 1.84211 3.90658 1.47117 4.18008 1.19766C4.45359 0.92416 4.82454 0.770508 5.21133 0.770508H8.12812C8.51492 0.770508 8.88586 0.92416 9.15937 1.19766C9.43287 1.47117 9.58652 1.84211 9.58652 2.22891V3.6873M5.21133 7.3333V11.7085M8.12812 7.3333V11.7085"
      stroke="#D1CFCF"
      strokeWidth="1.32582"
    />
  </svg>
)

export default CVItem
