import React from "react"

import { LandingPageNavbar } from "../../components"
import { ErrorBoundary } from "react-error-boundary"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"

const CreateAccountLayout = ({ children }) => {
  return (
    <div style={{ position: "relative", overflow: "hidden", maxWidth: "100%" }}>
      <LandingPageNavbar
        showNavbarArt={false}
        showBackground={true}
        signUpUrl={`/create-account`}
        loginUrl={"/login"}
      />
      <ErrorBoundary
        fallback={
          <div className="error-msg">
            <p className="h3">
              <FormattedMessage id="common.somethingWentWrong" />
            </p>
            <Link className="btn primary btn--small" to="/">
              <FormattedMessage id="common.goBackToHomepage" />
            </Link>
          </div>
        }
      ></ErrorBoundary>
      {children}
    </div>
  )
}

export default CreateAccountLayout
