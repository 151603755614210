import React from "react"
import Modal from "../Modal"
import { FormattedMessage, useIntl } from "react-intl"

const ConfirmationDialog = ({
  title = null,
  open,
  setOpen,
  message,
  onConfirm,
}) => {
  const { formatMessage } = useIntl()
  // Set default value for title
  title = title ?? formatMessage({ id: "common.areYouSure" })

  const onYesClick = async () => {
    await onConfirm?.()
  }

  return (
    <>
      <Modal open={open} setOpen={setOpen} className={"delete-dialog"}>
        <div className="delete-dialog__container">
          <h2 className="text-2xl font-bold">{title}</h2>
          <p>{message}</p>
          <div className="flex gap-2 delete-dialog__buttons">
            <button className="btn btn--small primary" onClick={onYesClick}>
              <FormattedMessage id="common.yes" />
            </button>
            <button className="btn btn--small" onClick={() => setOpen(false)}>
              <FormattedMessage id="common.no" />
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmationDialog
