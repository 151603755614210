import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  cvCredits: null,
  jobAdvertCredits: null,
}

const creditSlice = createSlice({
  name: "credit",
  initialState,
  reducers: {
    setCredits: (state, action) => {
      const payload = action.payload
      state.cvCredits = payload.cvCredits
      state.jobAdvertCredits = payload.jobAdvertCredits
    },
    resetCreditSlice: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
  },
})

export const { setCredits, resetCreditSlice } = creditSlice.actions
export default creditSlice.reducer
