import React, { useContext } from "react"
import { IntlProvider } from "react-intl"
import messages_en from "./lang/en.json"
import messages_fr from "./lang/fr.json"
import messages_es from "./lang/es.json"
import messages_de from "./lang/de.json"
import messages_it from "./lang/it.json"
import { LanguageContext } from "./LanguageProvider"

const messages = {
  en: messages_en,
  fr: messages_fr,
  es: messages_es,
  de: messages_de,
  it: messages_it,
}

const WrapIntlProvider = ({ children }) => {
  const { language } = useContext(LanguageContext)

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      {children}
    </IntlProvider>
  )
}

export default WrapIntlProvider
