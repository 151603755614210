import React from "react"

// util function that puts first character of a string to uppercase
const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const Skill = (props) => {
  return (
    <div className="skill flex-baseline">
      <div
        className="score middle"
        style={{ opacity: Math.max(props.score / 10, 0.1) }}
      >
        {Math.round(props.score)}
      </div>
      <div className="skill-wrap flex-col">
        <h4>
          {capitalize(props.skill)} <span className="tag">{props.tag}</span>
        </h4>

        <p>{props.context}</p>
      </div>
    </div>
  )
}

export default Skill
