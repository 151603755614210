import React, { createContext, useState, useEffect, useRef } from "react";
import { LANGUAGE_CODES, SUPPORTED_LANGUAGES } from "../constants";
import { getCookie, setCookie } from "../util/util";

const LanguageContext = createContext();

const DEFAULT_LANGUAGE = LANGUAGE_CODES.EN;

const getInitialLanguage = () => {
  const savedLanguage = getCookie("NEXT_LOCALE");
  const returnLanguage = savedLanguage || navigator.language;

  if (SUPPORTED_LANGUAGES.map(({ code }) => code).includes(returnLanguage)) {
    return returnLanguage;
  }
  return DEFAULT_LANGUAGE;
}

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(getInitialLanguage);
  const prevLanguageRef = useRef();

  useEffect(() => {
    setCookie("NEXT_LOCALE", language);
    prevLanguageRef.current = language;
  }, [language]);

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  }

  return (
    <LanguageContext.Provider value={{ language, prevLanguage: prevLanguageRef.current, changeLanguage  }}>
      {children}
    </LanguageContext.Provider>
  )
}

export { LanguageContext, LanguageProvider }
