import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { getProducts } from "../../../services/productService"
import {
  ChoosePricingSection,
  Features,
  Spinner,
  SubscriptionButton
} from "../../common"
import { buyMore } from "../../../services/paymentService"
import {
  setClientSecret,
  setInvoiceId,
  setProduct,
  setQuantity,
} from "../../../redux/slices/signupSlice"
// import { BillingItems } from "./components"
import { FormattedMessage, useIntl } from "react-intl"

const BuyMorePage = () => {
  const [loading, setLoading] = useState(false)
  const [productsLoading, setProductsLoading] = useState(true)
  const [hires, setHires] = useState(1);
  const [planType, setPlanType] = useState('self-service');
  const [products, setProducts] = useState([])
  const { formatMessage } = useIntl()

  const auth = useSelector((state) => state.auth)

  const hasSubscription = Boolean(auth.team.stripeSubscriptionId)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit = async (quantity = 1) => {
    const selectedProduct = products.find(
      (product) => product.productName.includes(`${hires} Hire`)
    );

    if (!selectedProduct) {
      toast.error(formatMessage({ id: "toast.somethingWentWrong" }));
      return;
    }

    try {
      setLoading(true)
      const data = await buyMore({ productId: selectedProduct.id, quantity })
      dispatch(setClientSecret(data.clientSecret))
      dispatch(setInvoiceId(data.invoiceId))
      dispatch(setProduct(selectedProduct))
      dispatch(setQuantity(quantity))

      navigate("/payment")
    } catch (err) {
      toast.error(formatMessage({ id: "toast.somethingWentWrong" }))
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchProducts = async () => {
      setProductsLoading(true)
      getProducts()
        .then((data) => {
          const allProducts = [...data.products]
          allProducts.reverse()
          setProducts(
            allProducts
              .filter(({ productType }) => productType === "OneTime")
              .sort((a, b) => b.id - a.id),
          )
        })
        .finally(() => {
          setProductsLoading(false)
        })
    }

    fetchProducts()
  }, [])

  if (productsLoading) {
    return (
      <div className="choose-pricing">
        <div className="choose-pricing__loader">
          <Spinner />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="buy-more">
        {!hasSubscription && (
          <div className="ran-out">
            <div className="ran-out__wrapper">
              <div className="ran-out__content">
                <p className="h4">
                  <FormattedMessage id="common.runOutAlready" />
                </p>
                <p className="p">
                  <FormattedMessage id="message.twelveMonthSubscription" />
                </p>
              </div>
              <SubscriptionButton
                isLowcost={false}
                onClick={() => navigate("/change-plan")}
                text={formatMessage({ id: "common.chooseSubscription" })}
              />
            </div>
          </div>
        )}
        {/* <BillingItems
          products={products}
          handleSubmit={handleSubmit}
          loading={loading}
        /> */}

        <ChoosePricingSection
          oneTimeOnly
          hires={hires}
          planType={planType}
          setHires={setHires}
          handleContinueClick={handleSubmit}
          loading={loading}
        />
      </div>
    </>
  )
}

export default BuyMorePage
