import React from "react"
import { BillingItem } from "../../ChoosePricingPage/components"
import { pricingInfo } from "../../../../constants"
import { FormattedMessage, useIntl } from "react-intl"

const BillingItems = ({ products, handleSubmit, loading }) => {
  const { formatMessage } = useIntl()
  const handleProductSelect = (product) => {
    handleSubmit(product)
  }

  return (
    <div className="billing-items">
      <div className="billing-items__wrapper">
        <p className="h3 billing-items__title">
          <FormattedMessage id="common.chooseYourPlan" />
        </p>
        <div className="billing-items__grid">
          {products?.map((item, i) => {
            const itemInfo = pricingInfo[item.productName]
            if (!itemInfo) {
              return null
            }

            const thisItemInfo = {
              ...itemInfo,
              buttonText: formatMessage({ id: "common.upgrade" }),
            }

            return (
              <BillingItem
                item={item}
                itemInfo={thisItemInfo}
                handleProductSelect={handleProductSelect}
                key={`Billing Item - ${i}`}
                loading={loading}
                isPopular={itemInfo.isPopular}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BillingItems
