import classNames from "classnames"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { setForm } from "../../../../redux/slices/signupSlice"
import { SubscriptionButton } from "../../../common"
import { FormattedMessage, useIntl } from "react-intl"

const BillingOptions = ({
  idealBillingOption,
  onSelect,
  loading,
  setValue,
}) => {
  const auth = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const trialCompletedAlready = auth.onboardingStage === "Trialing"

  const billingOptions = [
    {
      title: formatMessage({ id: "common.payAsYouGo" }),
      texts: [
        {
          label: formatMessage({ id: "message.noCommitmentAddMoreWhenNeeded" }),
          highlight: false,
        },
        {
          label: formatMessage({ id: "message.analyzeFrom1To250CVs" }),
          highlight: false,
        },
        {
          label: formatMessage({ id: "message.matchUpTo3JobsPerPack" }),
          highlight: false,
        },
      ],
      button: formatMessage({ id: "common.chooseStarterPack" }),
      type: "PayAsYouGo",
    },
    {
      title: "Subscription",
      texts: [
        {
          label: formatMessage({ id: "message.twelveMonthSubscription" }),
          highlight: false,
        },
        {
          label: `${formatMessage({ id: "message.saveUpTo40Percent" })}${
            trialCompletedAlready
              ? ""
              : ", " + formatMessage({ id: "message.andFirstWeekFree" })
          }`,
          highlight: true,
          bold: true,
        },
        {
          label: formatMessage({
            id: "message.analyzeFrom100To500CVsPerMonth",
          }),
          highlight: false,
        },
        {
          label: formatMessage({ id: "message.matchUpTo4JobsPerMonth" }),
          highlight: false,
        },
      ],
      button: formatMessage({ id: "common.chooseSubscription" }),
      type: "Subscription",
    },
    {
      title: formatMessage({ id: "common.enterprise" }),
      texts: [
        {
          label: formatMessage({
            id: "message.forLargerCompaniesMakingMoreThan100HiresPerYear",
          }),
          highlight: false,
        },
        {
          label: formatMessage({
            id: "common.veryLowCostPerHire",
          }),
          highlight: true,
          bold: true,
        },
        {
          label: formatMessage({
            id: "common.fullyIntegratedSolution",
          }),
          highlight: false,
        },
        {
          label: formatMessage({
            id: "common.seamlessly",
          }),
          highlight: false,
        },
      ],
      button: formatMessage({
        id: "navbar.talkToUs",
      }),
      buttonLink: `${process.env.REACT_APP_LANDING_URL}/talk-to-us`,
      type: "Enterprise",
    },
  ]

  const onClick = (item) => {
    if (item.buttonLink) {
      return navigate(item.buttonLink)
    }
    dispatch(setForm({ name: "subscriptionType", value: item.type }))
    onSelect()
  }

  return (
    <div className="billing-options">
      {billingOptions.map((billingOption) => (
        <div className="billing-option">
          {idealBillingOption === billingOption.type && (
            <div className="billing-option__ideal">
              <FormattedMessage id="common.idealForYourCompany" />
            </div>
          )}
          <p className="billing-option__title">{billingOption.title}</p>
          <div className="billing-option__texts">
            {billingOption.texts.map((text) => (
              <p
                className={classNames(`billing-option__text`, {
                  "billing-option__text--highlight": text.highlight,
                  "billing-option__text--bold": text.bold,
                })}
              >
                {text.label}
              </p>
            ))}
          </div>
          <SubscriptionButton
            onClick={() => onClick(billingOption)}
            text={billingOption.button}
            isIdeal={
              billingOption.isIdeal || idealBillingOption === billingOption.type
            }
            loading={loading}
          />
        </div>
      ))}
    </div>
  )
}

export default BillingOptions
