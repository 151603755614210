import React from "react"
import DocViewer from "@cyntler/react-doc-viewer"

const CV = ({ url }) => {
  const isPdf = url.includes(".pdf?")

  return (
    <div className="cv-render">
      <DocViewer
        config={{
          header: {
            disableHeader: true,
          },
        }}
        style={{ minHeight: 800 }}
        theme={{}}
        documents={[
          {
            uri: url,
            fileType: isPdf
              ? "application/pdf"
              : "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          },
        ]}
      />
    </div>
  )
}

export default CV
