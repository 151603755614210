import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { getProducts } from "../../../services/productService"
import { Spinner, SubscriptionButton } from "../../common"
import {
  changeSubscription,
  createSubscription,
  getSubscription,
} from "../../../services/paymentService"
import {
  setClientSecret,
  setInvoiceId,
  setIsPurchaseAfterSignup,
  setProduct,
} from "../../../redux/slices/signupSlice"
import {
  CreateSubscriptionBillingItems,
  UpgradeDowngradeSubscriptionBillingItems,
} from "./components"
import { me } from "../../../services/auth"
import { getToken } from "../../../util/util"
import { loginAction } from "../../../redux/slices/authSlice"
import { FormattedMessage, useIntl } from "react-intl"

const ChangePlanPage = () => {
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [subscription, setSubscription] = useState(null)
  const { formatMessage } = useIntl()
  const auth = useSelector((state) => state.auth)

  const hasSubscription = Boolean(auth.team.stripeSubscriptionId)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleCreateSubscription = async (selectedProduct) => {
    try {
      setSubmitting(true)
      const data = await createSubscription({ productId: selectedProduct.id })
      if (data.clientSecret) {
        dispatch(setClientSecret(data.clientSecret))
        dispatch(setProduct(selectedProduct))
        dispatch(setIsPurchaseAfterSignup(true))
        dispatch(setInvoiceId(data.invoiceId))

        navigate("/payment")
      } else {
        // Subscription creation successfull already has payment setup no need to redirect
        const data = await me({ token: getToken() })
        toast.success(
          formatMessage({ id: "toast.subscriptionCreatedSuccessfully" }),
        )
        dispatch(loginAction(data))
      }
    } catch (err) {
      toast.error(formatMessage({ id: "toast.somethingWentWrong" }))
    } finally {
      setSubmitting(false)
    }
  }

  const handleChangeSubscription = async (selectedProduct, payload) => {
    try {
      setSubmitting(true)
      await changeSubscription({
        productId: selectedProduct.id,
        switchNow: payload.switchNow,
      })

      const [subscriptionData, authData] = await Promise.allSettled([
        getSubscription(),
        me({ token: getToken() }),
      ])

      if (subscriptionData.status === "fulfilled") {
        setSubscription(subscriptionData.value.subscription)
      }

      if (authData.status === "fulfilled") {
        dispatch(loginAction(authData.value))
      }

      toast.error(
        formatMessage({ id: "toast.subscriptionChangedSuccessfully" }),
      )
    } catch (err) {
      toast.error(formatMessage({ id: "toast.somethingWentWrong" }))
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const [subscriptionData, productsData] = await Promise.allSettled([
        getSubscription(),
        getProducts(),
      ])

      if (subscriptionData.status === "fulfilled") {
        setSubscription(subscriptionData.value.subscription)
      }

      if (productsData.status === "fulfilled") {
        const allProducts = [...productsData.value.products]
        allProducts.reverse()
        setProducts(
          allProducts
            .filter(({ productType }) => productType === "Subscription")
            .sort((a, b) => b.id - a.id),
        )
      }

      setLoading(false)
    }

    fetchData()
  }, [])

  if (loading) {
    return (
      <div className="choose-pricing">
        <div className="choose-pricing__loader">
          <Spinner />
        </div>
      </div>
    )
  }

  return (
    <div className="change-plan">
      <div className="ran-out">
        <div className="ran-out__wrapper">
          <div className="ran-out__content">
            <p className="h4">
              <FormattedMessage id="common.runOutAlready" />
            </p>
            <p className="p">
              <FormattedMessage id="message.justAsk" />
            </p>
          </div>
          <SubscriptionButton
            isLowcost={false}
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_LANDING_URL}/talk-to-us`,
                "_blank",
              )
            }
            text={formatMessage({ id: "common.contactSales" })}
            loading={submitting}
          />
        </div>
      </div>

      {hasSubscription ? (
        <UpgradeDowngradeSubscriptionBillingItems
          products={products}
          handleSubmit={handleChangeSubscription}
          loading={submitting}
          subscription={subscription}
        />
      ) : (
        <CreateSubscriptionBillingItems
          products={products}
          handleSubmit={handleCreateSubscription}
          loading={submitting}
        />
      )}
    </div>
  )
}

export default ChangePlanPage
