import React, { useCallback, useEffect, useState } from "react"
import {
  ConfirmationDialog,
  Select,
  SignupInput,
  Spinner,
} from "../../../common"
import { Controller, useForm } from "react-hook-form"
import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import {
  getThirdPartyJobsAction,
  updateLeverIntegrationConfigAction,
} from "../../../../redux/slices/integrationSlice"
import intl from "../../../../i18n/intl"
import { FormattedMessage, useIntl } from "react-intl"
// import { triggerLeverWorkflow } from "../../../../services/integrationService"
// import { toast } from "react-toastify"

const triggerOptions = () => {
  const { formatMessage } = intl()
  return [
    {
      label: formatMessage({ id: "common.countBased" }),
      value: "count-based",
    },
    {
      label: formatMessage({ id: "common.timeBased" }),
      value: "time-based",
    },
  ]
}

const timeBasedTriggerOptions = () => {
  const { formatMessage } = intl()
  return [
    {
      label: formatMessage({ id: "common.daily" }),
      value: "daily",
    },
  ]
}

const LeverConfigurationForm = ({ data }) => {
  const [editable, setEditable] = useState({
    scoreThreshold: false,
    triggerType: false,
    triggerByCvThreshold: false,
    triggerByTimeOption: false,
    selectedJobs: false,
  })
  const upateLeverConfigLoading = useSelector(
    (state) => state.integrations.upateLeverConfigLoading,
  )
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const { formatMessage } = useIntl()

  const { jobs } = useSelector((state) => state.integrations)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      scoreThreshold: data?.scoreThreshold,
      triggerType: data?.triggerType,
      triggerByCvThreshold: data?.triggerByCvThreshold,
      triggerByTimeOption: data?.triggerByTimeOption,
      selectedJobs: [],
    },
  })

  const dispatch = useDispatch()

  useEffect(() => {
    !jobs && dispatch(getThirdPartyJobsAction())
  }, [])

  watch([
    "triggerType",
    "scoreThreshold",
    "triggerByCvThreshold",
    "triggerByTimeOption",
    "selectedJobs",
  ])

  const setValues = useCallback(
    (data) => {
      setValue(
        "selectedJobs",
        data?.selectedJobs?.map(({ id, title }) => ({
          value: id,
          label: title,
        })),
      )
      setValue("scoreThreshold", data?.scoreThreshold)
      setValue(
        "triggerType",
        triggerOptions().find((val) => val.value === data?.triggerType),
      )
      setValue("triggerByCvThreshold", data?.triggerByCvThreshold)
      setValue(
        "triggerByTimeOption",
        timeBasedTriggerOptions().find(
          (val) => val.value === data?.triggerByTimeOption,
        ),
      )
    },
    [setValue],
  )

  useEffect(() => {
    setValues(data)
  }, [data, setValues])

  const values = getValues()

  const triggerType = values.triggerType?.value

  const onSubmit = async (values, proceed) => {
    if (!proceed) {
      setOpenConfirmationDialog({
        message: formatMessage({ id: "common.areYouSureUpdateLeverConfig" }),
        onConfirm: () => onSubmit(values, true),
      })
      return
    }
    setOpenConfirmationDialog(false)

    const body = {
      scoreThreshold: Number(values.scoreThreshold),
      triggerType: values.triggerType.value,
      selectedJobs: values.selectedJobs.map(({ value }) =>
        jobs.find((job) => job.id === value),
      ),
    }

    if (triggerType === "count-based") {
      body.triggerByCvThreshold = Number(values.triggerByCvThreshold)
    }
    if (triggerType === "time-based") {
      body.triggerByTimeOption = values.triggerByTimeOption.value
    }
    dispatch(updateLeverIntegrationConfigAction(body))
  }

  const handleCancel = () => {
    setValues(data)
    setEditable({
      scoreThreshold: false,
      triggerType: false,
      triggerByCvThreshold: false,
      triggerByTimeOption: false,
    })
  }

  // const handleTriggerLeverWorkflow = () => {
  //   triggerLeverWorkflow()
  //     .then((data) =>
  //       toast.success(data?.message || "Lever workflow triggered"),
  //     )
  //     .catch((error) =>
  //       toast.error(
  //         error?.response?.data?.message || "Failed to trigger Lever workflow",
  //       ),
  //     )
  //   setOpenConfirmationDialog(false)
  // }

  return (
    <>
      <div className="ats__wrapper">
        <div className="lever-config">
          <p className="h3 lever-config__title">
            <FormattedMessage id="common.leverConfiguration" />
          </p>

          <div className="lever-config__content">
            <SignupInput
              register={register("scoreThreshold", {
                required: formatMessage({
                  id: "common.scoreThresholdRequired",
                }),
              })}
              label={formatMessage({ id: "common.scoreThreshold" })}
              placeholder={formatMessage({ id: "common.scoreThreshold" })}
              error={errors.scoreThreshold?.message}
              touched={touchedFields.scoreThreshold}
              value={values.scoreThreshold}
              type="number"
              min={0}
              max={10}
              step={0.1}
              showEditButton={true}
              editable={editable.scoreThreshold}
              setEditable={() =>
                setEditable({ ...editable, scoreThreshold: true })
              }
            />

            <Controller
              control={control}
              rules={{
                required: formatMessage({ id: "common.triggerTypeRequired" }),
              }}
              name="triggerType"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  name={"triggerType"}
                  placeholder={formatMessage({ id: "common.triggerType" })}
                  options={triggerOptions()}
                  label={formatMessage({ id: "common.triggerType" })}
                  error={errors.triggerType?.message}
                  editButtonText={formatMessage({
                    id: "common.edit",
                  }).toLocaleLowerCase()}
                  showEditButton={true}
                  editable={editable.triggerType}
                  setEditable={() =>
                    setEditable({ ...editable, triggerType: true })
                  }
                  disabled={!editable.triggerType}
                />
              )}
            />

            {triggerType === "count-based" && (
              <SignupInput
                register={register("triggerByCvThreshold", {
                  required: formatMessage({ id: "common.cvThresholdRequired" }),
                })}
                label={formatMessage({ id: "common.cvThreshold" })}
                placeholder={formatMessage({ id: "common.cvThreshold" })}
                error={errors.triggerByCvThreshold?.message}
                touched={touchedFields.triggerByCvThreshold}
                value={values.triggerByCvThreshold}
                type="number"
                showEditButton={true}
                editable={editable.triggerByCvThreshold}
                setEditable={() =>
                  setEditable({ ...editable, triggerByCvThreshold: true })
                }
              />
            )}
            {triggerType === "time-based" && (
              <Controller
                control={control}
                name="triggerByTimeOption"
                rules={{
                  required: formatMessage({
                    id: "common.triggerByTimeOptionRequired",
                  }),
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    name={"triggerByTimeOption"}
                    placeholder={formatMessage({
                      id: "common.whenToRunWorkflow",
                    })}
                    options={timeBasedTriggerOptions()}
                    label={formatMessage({ id: "common.whenToRunWorkflow" })}
                    error={errors.triggerByTimeOption?.message}
                    editButtonText={formatMessage({
                      id: "common.edit",
                    }).toLocaleLowerCase()}
                    showEditButton={true}
                    editable={editable.triggerByTimeOption}
                    setEditable={() =>
                      setEditable({ ...editable, triggerByTimeOption: true })
                    }
                    disabled={!editable.triggerByTimeOption}
                  />
                )}
              />
            )}

            <Controller
              control={control}
              name="selectedJobs"
              rules={{
                required: formatMessage({ id: "common.selectedJobsRequired" }),
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  name={"selectedJobs"}
                  placeholder={formatMessage({
                    id: "common.jobsToRunWorkflow",
                  })}
                  options={jobs?.map(({ id, title }) => ({
                    value: id,
                    label: title,
                  }))}
                  label={formatMessage({ id: "common.jobsToRunWorkflow" })}
                  error={errors.selectedJobs?.message}
                  editButtonText={formatMessage({
                    id: "common.edit",
                  }).toLocaleLowerCase()}
                  showEditButton={true}
                  editable={editable.selectedJobs}
                  setEditable={() =>
                    setEditable({ ...editable, selectedJobs: true })
                  }
                  disabled={!editable.selectedJobs}
                  allowMultipleSelect={true}
                />
              )}
            />
          </div>
          {upateLeverConfigLoading && <Spinner />}

          <div className="lever-config__actions">
            <button
              className={classNames("btn btn--small", {
                disabled: upateLeverConfigLoading,
              })}
              onClick={handleCancel}
              disabled={upateLeverConfigLoading}
            >
              <FormattedMessage id="common.cancel" />
            </button>
            <button
              className={classNames("btn btn--small primary", {
                disabled: upateLeverConfigLoading,
              })}
              onClick={handleSubmit(() => onSubmit(values))}
              disabled={upateLeverConfigLoading}
            >
              <FormattedMessage id="common.save" />
            </button>
          </div>
        </div>
      </div>

      {/* <div className="ats__wrapper">
        <div className="lever-config">
          <p className="h3 lever-config__title">Trigger Workflow</p>
          <div className="lever-config__content">
            <p className="lever-config__description">
              You can manually trigger the workflow by clicking the button below
            </p>

            <button
              className="btn btn--small error"
              onClick={() =>
                setOpenConfirmationDialog({
                  message:
                    "Are you sure you want to trigger the lever workflow?",
                  onConfirm: handleTriggerLeverWorkflow,
                })
              }
            >
              Trigger Workflow
            </button>
          </div>
        </div>
      </div> */}
      <ConfirmationDialog
        title={formatMessage({ id: "common.areYouSure" })}
        open={Boolean(openConfirmationDialog)}
        setOpen={setOpenConfirmationDialog}
        message={openConfirmationDialog?.message}
        onConfirm={openConfirmationDialog?.onConfirm}
      />
    </>
  )
}

export default LeverConfigurationForm
