import classNames from "classnames"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { Link, useLocation } from "react-router-dom"
import React from "react"

const stages = ["SelectCV", "AddJobSpec", "Requirements", "FindMatches"]

const JobMatchSidebar = () => {
  const selectedCVs = useSelector((state) => state.jobs.selectedCVs)
  const { formatMessage } = useIntl()
  const location = useLocation()
  const activeStage = location.pathname.includes("/select-cv")
    ? "SelectCV"
    : location.pathname.includes("/job-spec")
    ? "AddJobSpec"
    : location.pathname.includes("/requirements")
    ? "Requirements"
    : location.pathname.includes("/matches")
    ? "FindMatches"
    : ""

  const highlightAddSpecLink =
    selectedCVs.length > 0 && activeStage === "SelectCV"

  const items = [
    {
      name:
        selectedCVs.length > 0
          ? formatMessage(
              { id: "common.cvsSelected" },
              { count: selectedCVs.length },
            )
          : formatMessage({ id: "sidebar.selectCVs" }),
      active: activeStage === "SelectCV",
      done: stages.indexOf(activeStage) > 0,
    },
    {
      name: formatMessage({ id: "sidebar.addJobSpec" }),
      active: activeStage === "AddJobSpec",
      done: stages.indexOf(activeStage) > 1,
      highlight: highlightAddSpecLink,
      link: "/job-match/job-spec",
    },
    {
      name: formatMessage({ id: "sidebar.requirements" }),
      active: activeStage === "Requirements",
      done: stages.indexOf(activeStage) > 2,
    },
    {
      name: formatMessage({ id: "sidebar.findMatches" }),
      active: activeStage === "FindMatches",
      done: stages.indexOf(activeStage) > 3,
    },
  ]

  return (
    <div className="job-match">
      {items.map((item, i) =>
        item.highlight ? (
          <Link
            to={item.link}
            key={`Item - ${i}`}
            className={classNames("job-match__item", {
              "job-match__item--highlight": item.highlight,
            })}
          >
            {highlightSvg}
            {item.name}
          </Link>
        ) : (
          <p
            key={`Item - ${i}`}
            className={classNames("job-match__item", {
              "job-match__item--active": item.active,
              "job-match__item--completed": item.done,
            })}
          >
            {item.done ? doneSvg : <span>{i + 1}</span>}
            {item.name}
          </p>
        ),
      )}
    </div>
  )
}

const highlightSvg = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
    <rect width="25" height="25" rx="12.5" fill="white" />
    <path
      d="M9 12.5H16M16 12.5L13 8.5M16 12.5L13 16.5"
      stroke="#5EC58F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const doneSvg = (
  <span>
    <svg width="11" height="10" viewBox="0 0 11 10" fill="none">
      <path
        d="M5.58458 9.78764L10.1216 1.92932L8.41032 0.941321L4.75082 7.27976L1.7561 5.55076L0.8786 7.07064L5.58458 9.78764Z"
        fill="#002060"
      />
    </svg>
  </span>
)

export default JobMatchSidebar
