import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isAuthenticated: false,
  type: null,
  user: null,
  team: null,
  teamUser: null,
  role: null,
  token: null,
  onboardingStage: null,
  enabledAts: [],
  atsEnabled: false,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginAction: (state, action) => {
      const payload = action.payload
      const type = payload.data.type
      const team = payload.data.team
      const teamUser = payload.data.teamUser

      state.isAuthenticated = true
      state.type = type
      state.user = payload.data.user
      state.team = team
      state.teamUser = teamUser
      state.role = payload.data.role
      state.token = payload.token
      state.onboardingStage = payload.data.onboardingStage

      state.atsEnabled = team?.allowedIntegrations?.length > 0
      state.enabledAts = team?.allowedIntegrations || []
    },
    logoutAction: (state, action) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeOnboardingStageAction: (state, action) => {
      state.onboardingStage = action.payload
    },
    changeDetailsAction: (state, action) => {
      state.teamUser.position = action.payload.position
      state.user.name = action.payload.name
      state.user.email = action.payload.email
      state.user.phoneNumber = action.payload.phoneNumber
    },
    changeTeamDetailsAction: (state, action) => {
      state.team.name = action.payload.name
      state.team.registrationNumber = action.payload.registrationNumber
    },
  },
})

export const {
  loginAction,
  logoutAction,
  changeOnboardingStageAction,
  changeDetailsAction,
  changeTeamDetailsAction,
} = authSlice.actions
export default authSlice.reducer
