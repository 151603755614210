import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { getInvoices, getSubscription } from "../../../services/paymentService"
import { Invoices, Subscription } from "./components"

const PlanAndBillingPage = () => {
  const [invoices, setInvoices] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    if (auth.role?.role !== "TeamOwner") return

    getInvoices().then((data) => {
      setInvoices(data.invoices)
    })

    getSubscription()
      .then((data) => {
        setSubscription({ data: data.subscription })
      })
      .catch(() => {
        setSubscription({ data: null })
      })
  }, [])

  if (auth.role?.role !== "TeamOwner") {
    return (
      <div className="plan-and-billing-page">
        <p>You're not authorized.</p>
      </div>
    )
  }

  return (
    <div className="plan-and-billing-page">
      <Subscription subscription={subscription} />
      <Invoices invoices={invoices} />
    </div>
  )
}

export default PlanAndBillingPage
