import axios from "../api"
import { getToken } from "../util/util"

export const postJobRequirements = (body) =>
  axios.post("/jobs/requirements", body, {
    headers: { Authorization: `Bearer ${getToken()}` },
  })

export const getJobInfoByFile = (body) =>
  axios
    .post("/jobs/requirements/file", body, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => res.data)

export const getJobRequirements = ({ ad_id }) =>
  axios.get("/jobs/requirements", {
    params: {
      ad_id,
    },
    headers: { Authorization: `Bearer ${getToken()}` },
  })

export const getJobDetail = ({ ad_id }) =>
  axios.get("/jobs/detail", {
    params: {
      ad_id,
    },
    headers: { Authorization: `Bearer ${getToken()}` },
  })

export const selectCandidates = (body) =>
  axios.post("/jobs/select-candidates", body, {
    headers: { Authorization: `Bearer ${getToken()}` },
  })

export const getMatchedRequirements = ({ ad_id }) =>
  axios.get("/jobs/requirements_match", {
    params: {
      ad_id,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  })

export const postMatchRequirements = (body) =>
  axios.post("/jobs/requirements_match", body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  })

export const getJobMatches = () =>
  axios
    .get("/jobs/list", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) =>
      res.data
        .map((job) => ({
          ...job,
          candidates: job.candidates?.map(({ M }) => ({ ...M })),
        }))
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)),
    )
