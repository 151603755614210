import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { socket } from "./socket"
import { fetchCvs } from "./utils/fetchCVs"

export const useProcessedCVsWebSocket = (connectionCondition = false) => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  
  useEffect(() => {
    const webSocket = socket(auth?.token);

    if (connectionCondition) {
      webSocket.connect()

      webSocket.on("cv-processed", () => {
        console.log("cv-processed!");
        fetchCvs(dispatch)
      })

      webSocket.on("message", (text) => console.log(text))
    } else {
      webSocket.disconnect()
    }

    return () => {
      webSocket.disconnect()
    }
  }, [auth?.token, dispatch, connectionCondition])
}
