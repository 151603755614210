import React, { useMemo, useState } from "react"

import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { SignupInput } from "../../common"
import { completeInvitation } from "../../../services/auth"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { decodeJwt } from "../../../util/util"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"

const CompleteInvitationPage = () => {
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const token = searchParams.get("token")
  const { formatMessage } = useIntl()

  const tokenInfo = useMemo(() => token && decodeJwt(token), [token])

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: tokenInfo?.email,
    },
  })

  const values = getValues()

  if (!tokenInfo) {
    return <Navigate to="/" />
  }

  const onSubmit = (data) => {
    setLoading(true)
    const params = {
      ...data,
      invitationToken: token,
    }

    completeInvitation(params)
      .then(() => {
        setLoading(false)

        toast.success(formatMessage({ id: "toast.accountCreatedSuccessfully" }))
        navigate("/login")
      })
      .catch((err) => {
        const error = err.response.data?.message
        toast.error(error)
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="login-page">
      <div className="login-page__wrapper">
        <h1 className="login-page__title ">
          Hi {tokenInfo?.invite?.name} <br />
          <span>{tokenInfo?.invite?.message}</span>
        </h1>
        <form className="login-page__content" onSubmit={handleSubmit(onSubmit)}>
          <div className="login-page__inputs">
            <SignupInput
              register={register("email", {
                required: formatMessage({ id: "common.emailRequired" }),
              })}
              placeholder={formatMessage({ id: "common.email" })}
              error={errors.email?.message}
              touched={touchedFields.email}
              value={values.email}
              editable={false}
              type="email"
              disabled={true}
            />
            <SignupInput
              register={register("phoneNumber", {
                required: formatMessage({ id: "common.phoneNumberRequired" }),
              })}
              placeholder={formatMessage({ id: "common.phoneNumber" })}
              error={errors.phoneNumber?.message}
              touched={touchedFields.phoneNumber}
              value={values.phoneNumber}
            />
            <SignupInput
              register={register("password", {
                required: formatMessage({ id: "common.passwordRequired" }),
              })}
              placeholder={formatMessage({ id: "common.chooseYourPassword" })}
              error={errors.password?.message}
              touched={touchedFields.password}
              value={values.password}
              type="password"
              helperText={formatMessage({
                id: "message.passwordSafetyRequirement",
              })}
            />
          </div>

          <button
            className={classNames("btn btn--small primary", {
              disabled: !isValid || loading,
            })}
            onClick={handleSubmit(onSubmit)}
          >
            <FormattedMessage id="common.join" /> {tokenInfo?.team?.name}
          </button>
        </form>
      </div>
    </div>
  )
}

export default CompleteInvitationPage
