import React from "react"
import { Spinner } from "../../../common"
import moment from "moment"
import { Link } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"

const Subscription = ({ subscription }) => {
  const loading = !Boolean(subscription)
  const hasSubscription = Boolean(subscription?.data)
  const { formatMessage } = useIntl()

  return (
    <div className="subscription">
      {loading ? (
        <Spinner variant="secondary" />
      ) : hasSubscription ? (
        <div className="subscription__content">
          <div className="subscription__info">
            <p className="h3 subscription__plan">
              <FormattedMessage id="common.yourPlan" />
            </p>
            <p className="h4 ">
              <FormattedMessage
                id="common.friblSubscription"
                values={{ subscriptionName: subscription.data.name }}
              />
            </p>
            <p>
              {subscription.data.isTrialing &&
                `${formatMessage({ id: "common.trialActive" })} | `}{" "}
              <FormattedMessage id="common.nextBilling" />{" "}
              {moment(subscription.data.nextBillingDate).format("DD MMM YYYY")}
            </p>
          </div>
          <div className="subscription__buttons">
            <Link to={"/buy-more"} className="btn btn--small primary">
              {userSvg}&nbsp;&nbsp;
              <FormattedMessage id="common.buyAddOn" />
            </Link>
            <Link to={"/change-plan"} className="btn btn--small quinary">
              {changePlanSvg}&nbsp;&nbsp;{" "}
              <FormattedMessage id="common.changePlan" />
            </Link>
          </div>
        </div>
      ) : (
        <div className="subscription__content">
          <div className="subscription__info">
            <p className="h4 ">
              <FormattedMessage id="common.noActiveSubscription" />
            </p>
          </div>
          <div className="subscription__buttons">
            <Link to={"/buy-more"} className="btn btn--small primary">
              {userSvg}&nbsp;&nbsp;
              <FormattedMessage id="common.buyAddOn" />
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

const userSvg = (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 19V17C16 15.9391 15.5786 14.9217 14.8284 14.1716C14.0783 13.4214 13.0609 13 12 13H5C3.93913 13 2.92172 13.4214 2.17157 14.1716C1.42143 14.9217 1 15.9391 1 17V19M20 6V12M23 9H17M12.5 5C12.5 7.20914 10.7091 9 8.5 9C6.29086 9 4.5 7.20914 4.5 5C4.5 2.79086 6.29086 1 8.5 1C10.7091 1 12.5 2.79086 12.5 5Z"
      stroke="#002060"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const changePlanSvg = (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M15 19V3C15 2.46957 14.7893 1.96086 14.4142 1.58579C14.0391 1.21071 13.5304 1 13 1H9C8.46957 1 7.96086 1.21071 7.58579 1.58579C7.21071 1.96086 7 2.46957 7 3V19M3 5H19C20.1046 5 21 5.89543 21 7V17C21 18.1046 20.1046 19 19 19H3C1.89543 19 1 18.1046 1 17V7C1 5.89543 1.89543 5 3 5Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Subscription
