import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import classNames from "classnames"

import { CustomButton, CustomLink } from "../../../common"
import { GA_EVENTS } from "../../../../constants/analytics"
import { FormattedMessage, useIntl } from "react-intl"

const UploadCVActions = ({
  onUploadClick,
  variant = "button",
  triggerText = null,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { formatMessage } = useIntl()
  // Set default value for triggerText
  triggerText = triggerText ?? formatMessage({ id: "common.uploadCVs" })

  const { atsEnabled, enabledAts } = useSelector((state) => state.auth)

  const dropdownRef = useRef(null)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", closeDropdown)

    return () => {
      document.removeEventListener("click", closeDropdown)
    }
  }, [])

  if (!atsEnabled || !enabledAts.includes("greenhouse")) {
    return (
      <div className="upload-cv-actions upload-cv-actions">
        <CustomButton
          className={classNames(
            "upload-cv-actions__trigger upload-cv-actions__trigger--main",
            {
              "upload-cv-actions__trigger--text": variant === "text",
            },
          )}
          onClick={onUploadClick}
          trackingEvent={GA_EVENTS.SELECT_CV.UPLOAD}
        >
          <span>{triggerText}</span>
        </CustomButton>
      </div>
    )
  }

  return (
    <div
      ref={dropdownRef}
      className={classNames("upload-cv-actions upload-cv-actions", {
        isOpen: "upload-cv-actions--open",
      })}
    >
      <CustomButton
        className={classNames("upload-cv-actions__trigger", {
          "upload-cv-actions__trigger--text": variant === "text",
        })}
        onClick={toggleDropdown}
        trackingEvent={GA_EVENTS.SELECT_CV.TOGGLE_DROPDOWN}
      >
        {triggerText}
      </CustomButton>
      {isOpen && (
        <div className="upload-cv-actions__content" onClick={toggleDropdown}>
          <CustomButton
            className="upload-cv-actions__item"
            onClick={onUploadClick}
            trackingEvent={GA_EVENTS.SELECT_CV.UPLOAD}
          >
            <FormattedMessage id="common.uploadCVs" />
          </CustomButton>
          {atsEnabled && (
            <CustomLink
              className="upload-cv-actions__item"
              to={"/import-cvs"}
              trackingEvent={GA_EVENTS.SELECT_CV.IMPORT_GREENHOUSE}
            >
              <FormattedMessage id="common.importFromGreenhouse" />
            </CustomLink>
          )}
        </div>
      )}
    </div>
  )
}

export default UploadCVActions
