import { BLACKLIST_EMAIL_DOMAINS } from "../constants";

export const NAME_REGEX = /^[a-zA-ZÀ-ÿ]+ [a-zA-ZÀ-ÿ]+(?: [a-zA-ZÀ-ÿ]+)* *$/;

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const PASSWORD_REGEX = /^(?=.*\d).{8,}$/;

export const COMPANY_NAME_REGEX = /^[a-zA-Z0-9À-ÿ]+(?: [a-zA-Z0-9À-ÿ]+)* *$/;

export const PHONE_NUMBER_REGEX = /^[0-9\s-]+$/;

export const emailBlacklistValidation = (value, message = "Email not valid") => {
  if (!value) return message;

  const domain = value.split("@")[1]

  if (BLACKLIST_EMAIL_DOMAINS.includes(domain)) {
    return message;
  }
}