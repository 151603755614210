import RSelect from "react-select"
import React from "react"
import classNames from "classnames"
import { useIntl } from "react-intl"

const successSvg = (
  <svg
    className="success"
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
  >
    <rect y="0.739258" width="25" height="25" rx="12.5" fill="#5EC58F" />
    <path
      d="M12.5788 18.1119L16.7598 10.8702L15.1828 9.95976L11.8104 15.8008L9.0507 14.2075L8.24205 15.6081L12.5788 18.1119Z"
      fill="white"
    />
  </svg>
)

const CustomOption = ({ innerProps, label, isFocused, isSelected }) => (
  <p
    {...innerProps}
    className={classNames("select__option", {
      "select__option--active": isFocused || isSelected,
    })}
  >
    {label}
  </p>
)

const Select = ({
  name,
  value,
  onChange,
  placeholder,
  error,
  showEditButton = false,
  editable = true,
  setEditable,
  options,
  editButtonText = null,
  disabled,
  label,
  allowMultipleSelect,
  getOptionLabel = undefined,
}) => {
  const { formatMessage } = useIntl()
  editButtonText =
    editButtonText ?? formatMessage({ id: "common.edit" }).toLocaleLowerCase()

  const showSuccessSvg = !error && value && (showEditButton ? editable : true)

  return (
    <div className="select">
      <RSelect
        isMulti={allowMultipleSelect}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        isDisabled={disabled}
        placeholder={placeholder}
        options={options}
        isSearchable
        openMenuOnFocus={disabled ? false : true}
        styles={{
          singleValue: (provided, state) => ({
            ...provided,
            color: "var(--darkest, #002060)",
            fontSize: 17,
            fontWeight: 700,
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            minHeight: 60,
            height: allowMultipleSelect ? "unset" : 60,
          }),
          control: (provided, state) => ({
            ...provided,
            border: "none",
            outline: error
              ? "6px solid var(--warning-orange, #f63)"
              : state.isFocused
              ? "6px solid var(--strong, #5ec58f)"
              : "none",
            background: "white",
            borderRadius: 9,
          }),
          menuList: (provided) => ({
            ...provided,
            margin: 0,
            borderRadius: 9,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
            zIndex: 100,
          }),
          menu: (provided) => ({
            ...provided,
            borderRadius: 9,
            background: "#9a8787",
            border: "none",
            paddingBottom: 0,
          }),
        }}
        getOptionLabel={getOptionLabel}
        components={{ Option: CustomOption, IndicatorsContainer: () => null }}
      />
      {showSuccessSvg && successSvg}
      {showEditButton && !editable
        ? null
        : label && value && <label htmlFor={name}>{label}</label>}

      {error && <p className="select__error-message">{error}</p>}

      {showEditButton && !editable && (
        <p
          className="select__password-toggle"
          role={"button"}
          onClick={() => setEditable(true)}
          tabIndex={0}
          aria-label={"edit"}
        >
          {editButtonText}
        </p>
      )}
    </div>
  )
}

export default Select
