import api from "../api"
import { PER_PAGE_GREENHOUSE_CANDIDATES } from "../constants"
import { getToken } from "../util/util"

export const getIntegrations = async () =>
  api
    .get("/integrations/getAll", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const createIntegration = async (body) =>
  api
    .post("/integrations/create", body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const updateLeverConfig = async (body) =>
  api
    .put("/integrations/lever", body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const triggerLeverWorkflow = async (body) =>
  api
    .post("/integrations/trigger-lever-workflow", body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const getThirdPartyJobs = async () =>
  api
    .get("/integrations/get-jobs", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const getThirdPartyJobPosts = async () =>
  api
    .get("/integrations/get-job-posts", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const getThirdPartyCandidates = async (params) =>
  api
    .get("/integrations/get-candidates", {
      params: { ...params, per_page: PER_PAGE_GREENHOUSE_CANDIDATES },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const deleteIntegration = async (name) =>
  api
    .delete(`/integrations/${name}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
