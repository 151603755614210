import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, Navigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"

import { getProducts } from "../../../services/productService"
import { ChoosePricingSection, Features, Spinner } from "../../common"
import {
  setClientSecret,
  setInvoiceId,
  setProduct,
  setQuantity,
  setSetupIntentId,
  setSubscriptionId,
} from "../../../redux/slices/signupSlice"
import { Hero } from "./components"
import { addPayment } from "../../../services/auth"

const ChoosePricingPage = () => {
  const [loading, setLoading] = useState(false);
  const [hires, setHires] = useState(1);
  const [planType, setPlanType] = useState('self-service');
  const [productsLoading, setProductsLoading] = useState(true)
  const [products, setProducts] = useState({ oneTime: [], subscription: [] })
  const { formatMessage } = useIntl()

  const auth = useSelector((state) => state.auth)

  const trialCompletedAlready = auth.user?.didTrial

  const navigate = useNavigate()
  const dispatch = useDispatch()


  const handleSelectedProductChange = (product, quantity) => {
    dispatch(setProduct(product))
    dispatch(setQuantity(quantity))
  }

  const handleSubmit = async (quantity = 1) => {
    let productsList = [];
    if (hires <= 10) {
      productsList = products.oneTime;
    } else {
      productsList = products.subscription;
    }
    const selectedProduct = productsList.find(
      (product) => product.productName.includes(`${hires} Hire`)
    );

    if (!selectedProduct) {
      toast.error(formatMessage({ id: "toast.somethingWentWrong" }));
      console.error("Selected product not found");
      return;
    }

    handleSelectedProductChange(selectedProduct, quantity)

    // Handle Payment thing
    setLoading(true)
    try {
      const data = await addPayment({
        productId: selectedProduct.id,
        productQuantity: quantity,
      })
      dispatch(setClientSecret(data.clientSecret))
      dispatch(setSubscriptionId(data.subscriptionId || null))
      dispatch(setSetupIntentId(data.setupIntentId || null))
      dispatch(setInvoiceId(data.invoiceId || null))

      setTimeout(() => {
        navigate("/payment")
      }, 100)
    } catch (err) {
      toast.error(formatMessage({ id: "toast.somethingWentWrong" }))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchProducts = async () => {
      setProductsLoading(true)
      getProducts()
        .then((data) => {
          const allProducts = [...data.products]
          setProducts(allProducts)
          setProducts({
            oneTime: allProducts
              .filter(({ productType }) => productType === "OneTime")
              .sort((a, b) => a.totalPrice - b.totalPrice),
            subscription: allProducts
              .filter(({ productType }) => productType === "Subscription")
              .sort((a, b) => a.totalPrice - b.totalPrice),
          })
        })
        .finally(() => {
          setProductsLoading(false)
        })
    }

    fetchProducts()
  }, [])

  if (auth.isAuthenticated && auth.onboardingStage === "Complete") {
    return <Navigate to={"/job-match/select-cv"} />
  }

  if (auth.onboardingStage !== "ChoosePrice") {
    return <Navigate to={"/"} />
  }

  if (productsLoading) {
    return (
      <div className="choose-pricing">
        <div className="choose-pricing__loader">
          <Spinner />
        </div>
      </div>
    )
  }

  console.log({loading});

  return (
    <>
      <div className="choose-pricing">
        {/* TODO: Add French translations */}
        <Hero
          hires={hires}
          setHires={setHires}
          planType={planType}
          setPlanType={setPlanType}
        />

        {/* <BillingItems
          subscriptionType={subscriptionType}
          products={products?.[subscriptionType]}
          handleSubmit={handleSubmit}
          loading={loading}
          trialCompletedAlready={trialCompletedAlready}
        /> */}

        {/* TODO: Add French translations */}
        <ChoosePricingSection
          hires={hires}
          planType={planType}
          setHires={setHires}
          handleContinueClick={handleSubmit}
          loading={loading}
          topOffset={-200}
          trialCompletedAlready={trialCompletedAlready}
        />
        <Features
          trialCompletedAlready={trialCompletedAlready}
        />
      </div>
    </>
  )
}

export default ChoosePricingPage
