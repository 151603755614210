import React from "react"
import { Checkbox } from "../../../common"

const Candidate = ({ candidate, selectedCandidates, handleSelect }) => {
  const selected = Boolean(
    selectedCandidates.find((cand) => cand === candidate.id),
  )

  return (
    <div className="candidate">
      <Checkbox
        variant="secondary"
        value={selected}
        onChange={() => handleSelect(candidate.id)}
      />

      <p>{candidate.name}</p>
    </div>
  )
}

export default Candidate
