import React from "react"
import { Link, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { LandingPageNavbar } from "../../components"
import { ErrorBoundary } from "react-error-boundary"
import { FormattedMessage } from "react-intl"

const BasicLayout = ({
  children,
  showNavbarArt = true,
  navBackground,
  allowAuthenticatedUsers,
}) => {
  const auth = useSelector((state) => state.auth)

  if (allowAuthenticatedUsers) {
    return (
      <div
        style={{ position: "relative", overflow: "hidden", maxWidth: "100%" }}
      >
        <LandingPageNavbar
          showNavbarArt={showNavbarArt}
          showBackground={navBackground}
          signUpUrl={`/create-account`}
          loginUrl={"/login"}
          showConsole={true}
        />
        <ErrorBoundary
          fallback={
            <div className="error-msg">
              <p className="h3">
                <FormattedMessage id="common.somethingWentWrong" />
              </p>
              <Link className="btn primary btn--small" to="/">
                <FormattedMessage id="common.goBackToHomepage" />
              </Link>
            </div>
          }
        >
          {children}
        </ErrorBoundary>
      </div>
    )
  }

  if (auth.onboardingStage === "Complete") {
    return <Navigate to={"/job-match/select-cv"} />
  }

  if (auth.onboardingStage === "AccountCreation") {
    return <Navigate to={"/company-info"} />
  }

  if (auth.onboardingStage === "ChoosePrice") {
    return <Navigate to={"/choose-pricing"} />
  }

  if (auth.onboardingStage === "PaymentPending") {
    return <Navigate to={"/pending-payment"} />
  }

  return (
    <div style={{ position: "relative", overflow: "hidden", maxWidth: "100%" }}>
      <LandingPageNavbar
        showNavbarArt={showNavbarArt}
        showBackground={navBackground}
        signUpUrl={`/create-account`}
        loginUrl={"/login"}
      />
      <ErrorBoundary
        fallback={
          <div className="error-msg">
            <p className="h3">
              <FormattedMessage id="common.somethingWentWrong" />
            </p>
            <Link className="btn primary btn--small" to="/">
              <FormattedMessage id="common.goBackToHomepage" />
            </Link>
          </div>
        }
      >
        {children}
      </ErrorBoundary>
    </div>
  )
}

export default BasicLayout
