import classNames from "classnames"
import React from "react"

import { SORT_OPTIONS } from "../../../../constants"
import { FormattedMessage } from "react-intl"

const sortSwitch = {
  ASC: SORT_OPTIONS.DESC,
  DESC: SORT_OPTIONS.ASC,
}

const text = {
  [SORT_OPTIONS.ASC]: "common.oldest",
  [SORT_OPTIONS.DESC]: "common.newest",
}

const Sort = ({ sort, setSort }) => {
  return (
    <div className="sort">
      <span className="sort__label">
        <FormattedMessage id="common.show" />
      </span>
      <button
        className={classNames("sort__button", {
          "sort__button--asc": sort === SORT_OPTIONS.ASC,
        })}
        onClick={() => setSort(sortSwitch[sort])}
      >
        <span>
          <FormattedMessage id={text[sort]} />
        </span>
        {icon}
      </button>
    </div>
  )
}

const icon = (
  <svg
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66985 7.8125L8.99998 0.3125H0.339722L4.66985 7.8125Z"
      fill="#9A8787"
    />
  </svg>
)

export default Sort
