import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {FormattedMessage } from "react-intl";

import { formatPrice } from "../../../util/format";
import { SubscriptionButton } from "..";
import { HIRES, PLAN_TYPES, PRICE_TIERS } from "../../../constants/pricingData";
import { GA_EVENTS } from "../../../constants/analytics"

const ChoosePricingSection = ({
  hires = 1,
  setHires,
  handleContinueClick,
  loading,
  oneTimeOnly = false,
  planType,
  topOffset = 0,
  trialCompletedAlready = true,
}) => {
  const {minHires, max} = PLAN_TYPES[planType];
  const hireTier = PRICE_TIERS[planType]?.[hires || minHires];
  const isEnterprise = planType === "enterprise";
  const proceedToPayment = hireTier && !isEnterprise;

  const handleHiresChange = ({target}) => {
    const {value} = target;
    setHires(value < 1 ? '' : value);
  }

  const planDescription = hireTier ? (
    <>
      <p>
        <FormattedMessage id='choosePricing.planDescription.cvs1' />
        {' '}{hireTier.cvAnalysis}{' '}
        <FormattedMessage id='choosePricing.planDescription.cvs2' />
      </p>
      <p>
        <FormattedMessage id='choosePricing.planDescription.matches1' />
        {' '}{hireTier.jobMatches}{' '}
        <FormattedMessage id='choosePricing.planDescription.matches2' /><br/>
        <span className="small">
          <FormattedMessage id='choosePricing.planDescription.matchesDetail' />
        </span>
      </p>
    </>
  ) : (
    <>
      <p><b><FormattedMessage id='choosePricing.planDescription.noHiresPlan' /></b></p>
      {!isEnterprise && <p><FormattedMessage id='choosePricing.planDescription.talkToUs' /></p>}
    </>
  );
  
  let priceMessage;
  switch (hireTier?.type) {
    case "one-off":
      priceMessage = (
        <>
          <b>{formatPrice(hireTier.price)}{' €'}</b>{' '}
          <span className="normal">
            <FormattedMessage id='choosePricing.priceMessage.oneOff1' />

            {/* Free trial message */}
            {!trialCompletedAlready && (
              <>
                {', '}
                <b><FormattedMessage id='choosePricing.priceMessage.oneOff2' /></b> 
              </>
            )}
          </span>
        </>
      );
      break;
    case "subscription":
      priceMessage = (
        <>
          <b>{formatPrice(hireTier.price)}{' €'}</b>{' '}
          <span className="normal">
            <FormattedMessage id='choosePricing.priceMessage.subscription1' />
            {isEnterprise && <FormattedMessage id='choosePricing.priceMessage.subscription2'/>}
          </span>
        </>
      );
      break;
    default:
      priceMessage = <></>;
  }

  return (
    <section id="choose-pricing-section" >
      <div id="choose-pricing-container" style={{ top: topOffset }}>

        <div id="choose-pricing-container-header">
          <p className="h3"><FormattedMessage id='choosePricing.header.title1' /></p>
          <input
            type="number"
            placeholder={minHires}
            value={hires}
            onChange={handleHiresChange}
          />
          <p className="h3"><FormattedMessage id='choosePricing.header.title2' /></p>
          <p><b><FormattedMessage id='choosePricing.header.subtitle' /></b></p>
        </div>

        <div id="hires-slider">
          <Slider
            onChange={(nextValues) => setHires(nextValues)}
            onChangeComplete={(value) => setHires(value)}
            min={oneTimeOnly ? HIRES.ONE_TIME_ONLY.MIN : minHires}
            max={oneTimeOnly ? HIRES.ONE_TIME_ONLY.MAX : max}
            value={hires}
            step={1}
          />
        </div>

        <div id="plan-description">
          {planDescription}
          
          <p className="green">{priceMessage}</p>

          {isEnterprise && (
            <p className="green normal">
              <b><FormattedMessage id='choosePricing.planDescription.enterpriseDesc1' /></b>
              {' '}<FormattedMessage id='choosePricing.planDescription.enterpriseDesc2' />
            </p>
          )}

          <SubscriptionButton
            className='primary btn'
            onClick={handleContinueClick}
            loading={loading}
            text={
              <FormattedMessage
                id={`choosePricing.button.${proceedToPayment ? 'continue' : 'talkToUs'}`}
              />
            }
            trackingEvent={{
              ...GA_EVENTS.CHOOSE_PRICING.BILLING_SELECTED,
              label: `${planType}: ${hires} hires`,
            }}
          />

          {proceedToPayment
            ? <p>
                <FormattedMessage id='choosePricing.proceedToPayment.payment1' />{' '}
                <b><FormattedMessage id='choosePricing.proceedToPayment.payment2' /></b>
              </p>
            : <p className="green normal">
                <b><FormattedMessage id='choosePricing.proceedToPayment.ats1' /></b>{' '}
                <FormattedMessage id='choosePricing.proceedToPayment.ats2' />
              </p>
          }

          {isEnterprise && (
            <p className="green normal">
              <FormattedMessage id='choosePricing.planDescription.enterpriseDesc3' />
            </p>
          )}
        </div>

        {!trialCompletedAlready && proceedToPayment && (
          hireTier.type === "one-off" ? (
            <p><b><FormattedMessage id='choosePricing.billing.oneOff1' /></b>{' '}
            <FormattedMessage id='choosePricing.billing.oneOff2' /></p>
          ) : (
            <>
              <p><b><FormattedMessage id='choosePricing.billing.subscription1' /></b>
              {' '}<FormattedMessage id='choosePricing.billing.subscription2' /></p>
              <br/>
              <p className="green normal">
                <b><FormattedMessage id='choosePricing.billing.subscription3' /></b>
                {' '}<FormattedMessage id='choosePricing.billing.subscription4' />
              </p>
            </>
          )
        )}
      </div>
    </section>
  );
}

export default ChoosePricingSection;