import React, { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import { CVItem, Pagination, Search, Sort } from "./../SelectCVPage/components"
import { Spinner } from "../../common"
import { useParams } from "react-router-dom"

export const statuses = {
  FAILED: "Failed",
  PROCESSING: "Processing",
  DONE: "Done",
}

const JobCandidatesPage = () => {
  const selectedCVs = useSelector((state) => state.jobs.selectedCVs)
  const cvs = useSelector((state) => state.cv.cvs)
  const jobs = useSelector((state) => state.jobs)
  const { candidates } = jobs

  const [search, setSearch] = useState("")

  const { jobId } = useParams()

  const [page, setPage] = useState(1)
  const [perPage] = useState(25)

  const [sort, setSort] = useState("DESC")
  const filteredCvs = useMemo(() => {
    const searchFiltered =
      cvs?.filter(({ information: { first_name, last_name }, id }) => {
        const isSelected = selectedCVs.includes(id)
        if (!isSelected) return false

        const searchLowered = search.toLowerCase()
        return (
          first_name?.toLowerCase().includes(searchLowered) ||
          last_name?.toLowerCase().includes(searchLowered)
        )
      }) ?? []

    if (sort === "ASC") {
      searchFiltered.reverse()
    }

    return searchFiltered
  }, [search, sort, selectedCVs, cvs])

  const totalPages = useMemo(
    () => Math.ceil((filteredCvs?.length || 0) / perPage),
    [filteredCvs, perPage],
  )

  useEffect(() => setPage(1), [filteredCvs])

  if (!cvs) {
    return (
      <div>
        <Spinner />
      </div>
    )
  }

  return (
    <div className="select-cv">
      <div className="select-cv__cv-wrapper">
        {cvs?.length > 0 && (
          <div className="cv-item cv-item--no-bg">
            <div />
            <div className="flex items-center">
              <Search search={search} setSearch={setSearch} />
              <Pagination
                currentPage={page}
                setCurrentPage={setPage}
                totalPages={totalPages}
              />
              <Sort sort={sort} setSort={setSort} />
            </div>
          </div>
        )}

        {!candidates && (
          <div>
            <Spinner />
          </div>
        )}

        {filteredCvs?.slice(perPage * (page - 1), perPage * page).map((cv) => (
          <CVItem
            viewLink={`/job-match/${jobId}/candidates/${cv.id}`}
            cv={cv}
            key={cv.id}
            selected={selectedCVs.includes(cv.id)}
            onChange={() => {}}
            readOnly={true}
          />
        ))}
      </div>
    </div>
  )
}

export default JobCandidatesPage
