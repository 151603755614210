import classNames from "classnames"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { deleteIntegrationAction } from "../../../../redux/slices/integrationSlice"
import { ConfirmationDialog, Spinner } from "../../../common"
import { toTitleCase } from "../../../../util/util"
import { Link } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"

const ActiveIntegration = ({ active }) => {
  const { deleteIntegrationLoading } = useSelector(
    (state) => state.integrations,
  )
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const handleDelete = () => {
    setOpenConfirmationDialog(false)
    dispatch(deleteIntegrationAction(active.name))
  }

  const name = toTitleCase(active?.name)

  return (
    <div className="active-integration">
      <p className="h3">
        <FormattedMessage id="ats.connectedYourAccount" values={{ name }} />
      </p>
      <p className="p">
        <FormattedMessage id="ats.importExportData" values={{ name }} />
      </p>
      {deleteIntegrationLoading && <Spinner />}

      <div className="active-integration__actions">
        <button
          disabled={deleteIntegrationLoading}
          className={classNames("btn btn--small primary", {
            disabled: deleteIntegrationLoading,
          })}
          onClick={() => setOpenConfirmationDialog(true)}
        >
          <FormattedMessage id="common.forgetLoginDetails" />
        </button>

        {active.name === "greenhouse" && (
          <Link
            to={"/import-cvs"}
            className={classNames("btn btn--small tertiary")}
          >
            <FormattedMessage id="common.importCVs" />
          </Link>
        )}
      </div>

      <ConfirmationDialog
        title={formatMessage({ id: "common.areYouSure" })}
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        message={formatMessage({ id: "common.areYouSureForgetLogin" })}
        onConfirm={handleDelete}
      />
    </div>
  )
}

export default ActiveIntegration
