import classNames from "classnames"
import React from "react"

const Checkbox = ({
  name,
  label,
  register,
  value,
  onChange,
  onClick,
  variant = "primary",
  error,
}) => {
  if (!register) {
    return (
      <div>
        <label
          className={classNames("checkbox", {
            "checkbox--primary": variant === "primary",
            "checkbox--secondary": variant === "secondary",
            "checkbox--tertiary": variant === "tertiary",
          })}
          onClick={onClick}
        >
          <input
            name={name}
            type="checkbox"
            className="checkbox__input"
            checked={value}
            onChange={onChange}
          />
          {value ? selectedSvg(variant) : unSelectedSvg(variant)}
          {label && <span className="checkbox__label">{label}</span>}
        </label>
        {error && <p className="signup-input__error-message">{error}</p>}
      </div>
    )
  }

  return (
    <div>
      <label
        className={classNames("checkbox", {
          "checkbox--primary": variant === "primary",
          "checkbox--secondary": variant === "secondary",
          "checkbox--tertiary": variant === "tertiary",
        })}
      >
        <input
          name={name}
          type="checkbox"
          className="checkbox__input"
          {...register}
        />
        {value ? selectedSvg(variant) : unSelectedSvg(variant)}
        <span className="checkbox__label">{label}</span>
      </label>
      {error && <p className="signup-input__error-message">{error}</p>}
    </div>
  )
}

const selectedSvg = (variant) => (
  <svg
    width="33"
    height="34"
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/000/svg"
  >
    <rect
      x="3"
      y="3.23926"
      width="27"
      height="27"
      rx={variant === "primary" ? "6" : "2"}
      fill={variant === "tertiary" ? "none" : "currentColor"}
      stroke={variant === "tertiary" ? "#fff" : "currentColor"}
      strokeWidth={variant === "primary" ? "6" : "2"}
    />
    <path
      d="M15.76 22.331L21.344 12.6592L19.2378 11.4432L14.7338 19.2443L11.048 17.1163L9.96802 18.987L15.76 22.331Z"
      fill="white"
    />
  </svg>
)

const unSelectedSvg = (variant) => (
  <svg
    width="33"
    height="34"
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="3.23926"
      width="27"
      height="27"
      rx={variant === "primary" ? "6" : "2"}
      fill={variant === "tertiary" ? "none" : "white"}
      stroke={
        variant === "primary"
          ? "currentColor"
          : variant === "tertiary"
          ? "#fff"
          : "#D1CFCF"
      }
      strokeWidth={variant === "primary" ? "6" : "2"}
    />
  </svg>
)

export default Checkbox
