import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useCookies } from "react-cookie"
import classNames from "classnames"

import { me } from "../../../services/auth"
import { loginAction } from "../../../redux/slices/authSlice"
import { verifyPayment } from "../../../services/paymentService"
import { getCredits } from "../../../services/creditService"
import { setCredits } from "../../../redux/slices/creditSlice"

import { CustomButton, Spinner } from "../../common"
import { GA_EVENTS } from "../../../constants/analytics"
import { FormattedMessage } from "react-intl"

const PaymentConfirmationPage = () => {
  const [_, setCookie] = useCookies(["token"])
  const auth = useSelector((state) => state.auth)
  const urlParams = new URLSearchParams(window.location.search)
  const invoiceId = urlParams.get("invoiceId")
  const setupIntentId = urlParams.get("setupIntentId")

  const [data, setData] = useState(null)

  const [success, setSuccess] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleNext = () => {
    navigate("/job-match/select-cv")
  }

  useEffect(() => {
    let interval
    const fetchInfo = () => {
      me({ token: auth.token })
        .then((data) => {
          if (data.data.onboardingStage === "Complete") {
            dispatch(loginAction(data))

            setCookie("token", data.token, {
              path: "/",
              expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
            })
            clearInterval(interval)
          }
        })
        .catch(() => {})
    }

    fetchInfo()
    interval = setInterval(fetchInfo, 10000)

    return () => clearInterval(interval)
  }, [auth.onboardingStage])

  useEffect(() => {
    let interval

    const fetchPaymentInfo = async () => {
      const body = { invoiceId, setupIntentId }
      verifyPayment(body).then((data) => {
        if (!data.error) {
          const creditData = {
            cvQuantity: 0,
            jobSearchQuantity: 0,
          }

          data.credits?.forEach((credit) => {
            if (credit.type === "JobAdvert") {
              creditData.jobSearchQuantity += credit.quantity
            } else {
              creditData.cvQuantity += credit.quantity
            }
          })

          setData(creditData)
          setSuccess(true)
          // Fetch credits again
          getCredits().then((credits) => {
            dispatch(setCredits(credits))
          })
          clearInterval(interval)
        }
      })
    }

    fetchPaymentInfo()
    interval = setInterval(() => {
      fetchPaymentInfo()
    }, 10000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.onboardingStage])

  if (!success || auth.onboardingStage !== "Complete") {
    return (
      <div className="payment-page">
        <div className="payment-page__wrapper">
          <div className="payment-page__content">
            <div className="payment-confirmation">
              <p className="payment-confirmation__title">
                <span>
                  <FormattedMessage id="common.thankYou" />,
                </span>

                <br />
              </p>

              <p className="payment-confirmation__subscription-info">
                <FormattedMessage id="common.pleaseHavePatienceAddingCredits" />
              </p>

              <Spinner />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="payment-page">
      <div className="payment-page__wrapper">
        <div className="payment-page__content">
          <div className="payment-confirmation">
            <p className="payment-confirmation__title">
              <span>
                <FormattedMessage id="common.thankYou" />,
              </span>
              <br /> <FormattedMessage id="common.youAreAllSetUp" />
              <br />
            </p>
            {invoiceId ? (
              <p className="payment-confirmation__subscription-info">
                <FormattedMessage
                  id="message.addedCVsAndJobAdMatch"
                  values={{
                    cvQuantity: data?.cvQuantity,
                    jobSearchQuantity: data?.jobSearchQuantity,
                  }}
                />
              </p>
            ) : (
              <p className="payment-confirmation__subscription-info">
                <FormattedMessage id="common.paymentMethodAdded" />
              </p>
            )}
            <p className="payment-confirmation__welcome-text">
              <FormattedMessage id="message.enjoyFriblAnyQuestions" />
            </p>

            <CustomButton
              className={classNames(
                "payment-confirmation__next-btn btn primary btn--small",
                { disabled: auth.onboardingStage !== "Complete" },
              )}
              onClick={handleNext}
              disabled={!success}
              trackingEvent={GA_EVENTS.PAYMENT.CONFIRMATION_NEXT}
            >
              <span className="payment-confirmation__next">
                <FormattedMessage id="common.next" />
              </span>
              &nbsp; <FormattedMessage id="common.uploadCVs" />
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentConfirmationPage
