import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter as Router } from "react-router-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import store from "./redux/store"
import { Provider } from "react-redux"
import { LanguageProvider } from "./i18n/LanguageProvider"
import WrapIntlProvider from "./i18n/WrapIntlProvider"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <LanguageProvider>
          <WrapIntlProvider>
            <App />
          </WrapIntlProvider>
        </LanguageProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
