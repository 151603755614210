import React, { useState } from "react"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { SignupInput } from "../../../common"
import { updatePersonalDetails } from "../../../../services/auth"
import { changeDetailsAction } from "../../../../redux/slices/authSlice"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"

const DetailsForm = () => {
  const [loading, setLoading] = useState(false)
  const auth = useSelector((state) => state.auth)
  const [editable, setEditable] = useState({
    name: false,
    phoneNumber: false,
    email: false,
    position: false,
  })

  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: auth.user.name,
      phoneNumber: auth.user.phoneNumber,
      email: auth.user.email,
      position: auth.teamUser.position,
    },
  })

  watch(["name", "phoneNumber", "email", "position"])

  const values = getValues()

  const onSubmit = async () => {
    if (loading) return

    //
    try {
      const toUpdate = {}

      if (editable.name) toUpdate.name = values.name
      if (editable.phoneNumber) toUpdate.phoneNumber = values.phoneNumber
      if (editable.email) toUpdate.email = values.email
      if (editable.position) toUpdate.position = values.position

      if (Object.keys(toUpdate).length === 0) return

      setLoading(true)
      await updatePersonalDetails(toUpdate)
      dispatch(changeDetailsAction(values))
      toast.success(formatMessage({ id: "toast.detailsUpdatedSuccessfully" }))

      setEditable({
        name: false,
        phoneNumber: false,
        email: false,
        position: false,
      })
    } catch {
      toast.error(formatMessage({ id: "toast.somethingWentWrongTryAgain" }))
    }
    setLoading(false)
    //
  }

  const handleCancel = () => {
    setEditable({
      name: false,
      phoneNumber: false,
      email: false,
      position: false,
    })

    // Set previous defaults
    setValue("name", auth.user.name)
    setValue("phoneNumber", auth.user.phoneNumber)
    setValue("email", auth.user.email)
    setValue("position", auth.teamUser.position)
  }

  return (
    <form className="details-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="details-form__inputs">
        <SignupInput
          {...{
            name: "name",
            placeholder: formatMessage({ id: "common.whatsYourName" }),
            register: register("name", {
              required: formatMessage({ id: "common.nameRequired" }),
            }),
            error: errors.name?.message,
            touched: touchedFields.name,
            value: values.name,
            showEditButton: true,
            editable: editable.name,
            setEditable: () => setEditable({ ...editable, name: true }),
            label: formatMessage({ id: "common.name" }),
          }}
        />
        <SignupInput
          {...{
            name: "phoneNumber",
            placeholder: formatMessage({
              id: "common.bestPhoneNumberToCallBack",
            }),
            register: register("phoneNumber", {
              required: formatMessage({ id: "common.phoneNumberRequired" }),
              pattern: {
                value: /^[0-9\s-]+$/,
                message: formatMessage({
                  id: "common.somethingDoesntLookRight",
                }),
              },
            }),
            error: errors.phoneNumber?.message,
            touched: touchedFields.phoneNumber,
            value: values.phoneNumber,
            showEditButton: true,
            editable: editable.phoneNumber,
            setEditable: () => setEditable({ ...editable, phoneNumber: true }),
            label: formatMessage({ id: "common.phoneNumber" }),
          }}
        />
        <SignupInput
          {...{
            name: "email",
            placeholder: formatMessage({ id: "common.orYourEmail" }),
            register: register("email", {
              pattern: {
                value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                message: formatMessage({ id: "common.invalidEmailAddress" }),
              },
            }),
            error: errors.email?.message,
            touched: touchedFields.email,
            value: values.email,
            showEditButton: true,
            editable: editable.email,
            setEditable: () => setEditable({ ...editable, email: true }),
            label: formatMessage({ id: "common.email" }),
          }}
        />
        <SignupInput
          {...{
            name: "position",
            placeholder: formatMessage({ id: "common.whatsYourPosition" }),
            register: register("position", {
              required: formatMessage({ id: "common.roleRequired" }),
              pattern: {
                value: /^[a-zA-Z -]+$/,
                message: formatMessage({
                  id: "common.somethingDoesntLookRight",
                }),
              },
            }),
            error: errors.position?.message,
            touched: touchedFields.position,
            value: values.position,
            showEditButton: true,
            editable: editable.position,
            setEditable: () => setEditable({ ...editable, position: true }),
            label: formatMessage({ id: "common.role" }),
          }}
        />
      </div>
      <div className="details-form__buttons">
        <button
          className={classNames("btn btn--small primary", {
            disabled: loading,
          })}
        >
          <FormattedMessage id="common.saveChanges" />
        </button>
        <button
          className="btn btn--small tertiary"
          type="button"
          onClick={handleCancel}
        >
          <FormattedMessage id="common.cancel" />
        </button>
      </div>
    </form>
  )
}

export default DetailsForm
