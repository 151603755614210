import axios from "../api"
import { getToken } from "../util/util"

axios.defaults.timeout = 1000000

export const uploadCV = (body) =>
  axios.post("/cv/upload", body, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getToken()}`,
    },
  })

export const getCV = (key) =>
  axios
    .get(`/cv/get?key=${key}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })

export const deleteCvs = (keys) =>
  axios.post(
    `/cv/delete`,
    { keys },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  )

export const getCvs = () =>
  axios
    .post(`/cv/getAll`, null, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data.data.sort((a, b) => new Date(b.date) - new Date(a.date))
    })

export const importCvs = (body) =>
  axios
    .post(`/cv/import-cv`, body, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => res.data)
