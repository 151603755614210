import React, { useState } from "react"
import classNames from "classnames"
import CustomButton from "../CustomButton"
import { GA_EVENTS } from "../../../constants/analytics"
import { useIntl } from "react-intl"


const SubscriptionButton = ({
  onClick,
  isPopular,
  isLowcost,
  isIdeal,
  text,
  loading,
  quantityInput,
  showArrow = true,
  trackingEvent = GA_EVENTS.GENERAL.SUBSCRIPTION_SELECTED,
  variant = "primary",
}) => {
  const [quantity, setQuantity] = useState("")
  const { formatMessage } = useIntl()
  const handleClick = (e) => {
    // Return null of input is clicked
    if (e.target.tagName === "INPUT") {
      return
    }

    if (quantityInput && !quantity) {
      return
    }
    if (quantityInput) {
      onClick(quantity)
    } else {
      onClick(1)
    }
  }

  if (variant === "primary") {
    return (
      <CustomButton
        onClick={handleClick}
        className={classNames("subscription-button ", {
          "subscription-button--popular": isPopular,
          "subscription-button--lowcost": isLowcost,
          "subscription-button--ideal": isIdeal,
          "subscription-button--loading": loading,
        })}
        disabled={loading}
        trackingEvent={trackingEvent}
      >
        <span className="subscription-button__text">{text}</span>
        {showArrow && arrowSvg}
      </CustomButton>
    )
  }

  if (variant === "secondary") {
    return (
      <CustomButton
        onClick={handleClick}
        className={classNames(
          "subscription-button subscription-button--secondary",
          {
            "subscription-button--popular": isPopular,
            "subscription-button--lowcost": isLowcost,
            "subscription-button--ideal": isIdeal,
            "subscription-button--loading": loading,
          },
        )}
        disabled={loading}
        trackingEvent={{
          ...trackingEvent,
          value: quantity
        }}
      >
        <input
          className="subscription-button__text subscription-button__text--secondary"
          placeholder={formatMessage({ id: "common.chooseHowMany" })}
          value={quantity}
          onChange={(e) => {
            const quantity = e.target.value ? parseInt(e.target.value) : 1
            // Clamp quantity between 1 and 49
            if (quantity > 49) {
              setQuantity(49)
              return
            }
            if (quantity < 1) {
              setQuantity(1)
              return
            }
            setQuantity(quantity)
          }}
          type={"number"}
          min={1}
          max={49}
        />

        <span className="subscription-button__svg-wrapper">{arrowSvg}</span>
      </CustomButton>
    )
  }

  return null
}

const arrowSvg = (
  <svg
    width="36"
    height="16"
    viewBox="0 0 36 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8H35M35 8L28 1M35 8L28 15"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SubscriptionButton
