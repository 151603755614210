import React, { useState } from "react"
import { Navbar, Sidebar } from "../../components"
import { Link, Navigate, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { ErrorBoundary } from "react-error-boundary"
import ReactGA from "react-ga4"
import { FormattedMessage } from "react-intl"

const AuthenticatedLayout = ({
  children,
  Sidebar: Sbar,
  roles,
  allowOnboardingUsers = false,
  hideForTrialingUsers = false,
}) => {
  const [user, setUser] = useState(null);
  const auth = useSelector((state) => state.auth)
  const { pathname } = useLocation()

  if (!auth.isAuthenticated) {
    if (user) {
      setUser(null);
      ReactGA.set({ userId: null })
    }

    return <Navigate to={"/login"} />
  }

  if (!user) {
    ReactGA.set({ userId: auth.user.id })
    setUser(auth.user.id)
  }

  if (hideForTrialingUsers) {
    if (auth.onboardingStage === "Trialing") {
      return <Navigate to={"/job-match/select-cv"} />
    }
  }

  if (
    !allowOnboardingUsers &&
    !["Complete", "Trialing"].includes(auth.onboardingStage)
  ) {
    return <Navigate to={"/job-match/select-cv"} />
  }

  if (
    auth.onboardingStage === "PaymentPending" &&
    pathname !== "/pending-payment"
  ) {
    return <Navigate to={"/pending-payment"} />
  }

  if (
    auth.isAuthenticated &&
    !["Complete", "Trialing"].includes(auth.onboardingStage)
  ) {
    return <Navigate to={"/choose-pricing"} />
  }

  if (roles && !roles.includes(auth.role?.role)) {
    return <Navigate to={"/job-match/select-cv"} />
  }

  return (
    <main className="app">
      {Sbar || <Sidebar />}
      <div className="content-container">
        <Navbar />
        <ErrorBoundary
          fallback={
            <div className="error-msg">
              <p className="h3">
                <FormattedMessage id="common.somethingWentWrong" />
              </p>
              <Link className="btn primary btn--small" to="/">
                <FormattedMessage id="common.goBackToHomepage" />
              </Link>
            </div>
          }
        >
          {children}
        </ErrorBoundary>
      </div>
    </main>
  )
}

export default AuthenticatedLayout
