import React, { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { Select, Spinner } from "../../../common"
import classNames from "classnames"

import { getLanguage, updateLanguage } from "../../../../services/team"
import { reset as resetCVsState } from "../../../../redux/slices/cvSlice"
import { reset as resetJobState } from "../../../../redux/slices/jobSlice"
import { LanguageContext } from "../../../../i18n/LanguageProvider"
import { SUPPORTED_LANGUAGES } from "../../../../constants"

const languageOptions = SUPPORTED_LANGUAGES.map(({ code, name }) => ({
  value: code,
  label: name,
}))

const DefaultLanguageForm = () => {
  const dispatch = useDispatch();

  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const auth = useSelector((state) => state.auth)
  const [editable, setEditable] = useState(false)
  const { changeLanguage } = useContext(LanguageContext)

  const isReadOnly = auth.role.role === "TeamMember"

  const [currentLanguage, setCurrentLanguage] = useState(languageOptions[0])
  const { formatMessage } = useIntl()

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      language: currentLanguage,
    },
  })

  useEffect(() => {
    getLanguage().then((team) => {
      const language = languageOptions.find(
        (lang) => team.language === lang.value,
      )
      setCurrentLanguage(language)
      setValue("language", language)
      setInitialLoading(false)
    })
  }, [setValue])

  watch(["language"])

  const onSubmit = (values) => {
    if (loading) return;

    if (currentLanguage?.value === values.language?.value) return;

    setLoading(true)

    updateLanguage({ language: values.language?.value })
      .then(() => {
        setLoading(false);
        setEditable(false);
        setCurrentLanguage(values.language);
        changeLanguage(values.language.value);

        // Clean CVs and Jobs state
        dispatch(resetCVsState());
        dispatch(resetJobState());
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      })
  }

  const handleCancel = () => {
    setEditable(false);
    setValue("language", currentLanguage);
  }

  return (
    <form className="details-form" onSubmit={handleSubmit(onSubmit)}>
      {initialLoading && <Spinner className={"pt-4 pb-4"} />}
      {!initialLoading && (
        <div className="details-form__inputs">
          <Controller
            control={control}
            name="language"
            rules={{
              required: formatMessage({ id: "common.languageRequired" }),
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={onChange}
                name={"language"}
                placeholder={formatMessage({ id: "common.language" })}
                options={languageOptions}
                showEditButton={!isReadOnly}
                editButtonText={formatMessage({ id: "common.changeLanguage" })}
                editable={editable}
                setEditable={() => setEditable(true)}
                error={errors.language?.message}
                touched={touchedFields.language}
                disabled={isReadOnly || !editable}
              />
            )}
          />
        </div>
      )}
      {!isReadOnly && (
        <div className="details-form__buttons">
          <button
            className={classNames("btn btn--small primary", {
              disabled: loading,
            })}
          >
            <FormattedMessage id="common.saveChanges" />
          </button>
          <button
            className="btn btn--small tertiary"
            type="button"
            onClick={handleCancel}
          >
            <FormattedMessage id="common.cancel" />
          </button>
        </div>
      )}
    </form>
  )
}

export default DefaultLanguageForm
