import React from "react"
import { useSelector } from "react-redux"
import { CustomLink } from "../../../common"
import { GA_EVENTS } from "../../../../constants/analytics"
import { FormattedMessage } from "react-intl"

const SentToEmail = () => {
  const email = useSelector((state) => state.signup.email)

  return (
    <div className="create-account">
      <div className="create-account__wrapper">
        <h1 className="create-account__title mb-15">
          <FormattedMessage id="common.thankYou" />, <br />
          <span>
            <FormattedMessage id="common.checkYourEmail" />
          </span>
        </h1>

        <div className="create-account__content sent-to-email">
          <p>
            <span>
              <FormattedMessage
                id="message.sentLinkToEmail"
                values={{ email }}
              />
            </span>
          </p>

          <p>
            <FormattedMessage id="message.checkJunkFolder" />
          </p>
          <CustomLink
            to={"/login"}
            className="btn btn--small primary mt-15 sent-to-email__button"
            trackingEvent={GA_EVENTS.USER_REGISTRATION.EMAIL_SENT_GO_BACK}
          >
            <FormattedMessage id="common.goBack" />
          </CustomLink>
        </div>
      </div>
    </div>
  )
}

export default SentToEmail
