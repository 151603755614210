import React from "react"
import { FormattedMessage } from "react-intl"

const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {
  return (
    <div className="pagination">
      <button
        className=""
        onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
      >
        {frontArrow}
      </button>

      <span>
        <FormattedMessage id="common.page" /> {currentPage}/{totalPages}
      </span>

      <button
        onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
      >
        {nextArrow}
      </button>
    </div>
  )
}

const nextArrow = (
  <svg width="9" height="10" viewBox="0 0 9 10" fill="none">
    <path
      d="M8.33972 5.23237L0.839721 0.902245L0.839722 9.5625L8.33972 5.23237Z"
      fill="currentColor"
    />
  </svg>
)

const frontArrow = (
  <svg
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.339722 5.23237L7.83972 0.902245L7.83972 9.5625L0.339722 5.23237Z"
      fill="currentColor"
    />
  </svg>
)

export default Pagination
