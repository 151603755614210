import axios from "../api"
import { getToken } from "../util/util"

export const buyMore = ({ productId, quantity }) =>
  axios
    .post(
      "/payments/buy-more",
      { productId, quantity },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    )
    .then((res) => res.data)

export const createSubscription = ({ productId }) =>
  axios
    .post(
      "/payments/create-subscription",
      { productId },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    )
    .then((res) => res.data)

export const changeSubscription = ({ productId, switchNow }) =>
  axios
    .post(
      "/payments/change-subscription",
      { productId, switchNow },
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      },
    )
    .then((res) => res.data)

export const verifyPayment = (body) =>
  axios
    .post("/payments/verify", body, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => res.data)

export const getPendingPayment = () =>
  axios
    .get("/payments/pending-payment", {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => res.data)

export const getInvoices = () =>
  axios
    .get("/payments/invoices", {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => res.data)

export const getSubscription = () =>
  axios
    .get("/payments/active-subscription", {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => res.data)
