import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  email: "",

  type: "Starter",
  subscriptionType: "PayAsYouGo",

  submit: false,

  clientSecret: null,
  subscriptionId: null,
  setupIntentId: null,
  invoiceId: null,

  product: null,
  productQuantity: 1,

  isPurchaseAfterSignup: false,
}

const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setForm: (state, action) => {
      const { name, value } = action.payload
      state[name] = value
    },
    setClientSecret: (state, action) => {
      state.clientSecret = action.payload
    },
    setProduct: (state, action) => {
      state.product = action.payload
    },
    setQuantity: (state, action) => {
      state.productQuantity = action.payload
    },
    setSubscriptionId: (state, action) => {
      state.subscriptionId = action.payload
    },
    setIsPurchaseAfterSignup: (state, action) => {
      state.isPurchaseAfterSignup = action.payload
    },
    setSetupIntentId: (state, action) => {
      state.setupIntentId = action.payload
    },
    setInvoiceId: (state, action) => {
      state.invoiceId = action.payload
    },
  },
})

export const {
  setForm,
  setClientSecret,
  setProduct,
  setQuantity,
  setSubscriptionId,
  setIsPurchaseAfterSignup,
  setSetupIntentId,
  setInvoiceId,
} = signupSlice.actions
export default signupSlice.reducer
