import classNames from "classnames"
import React from "react"

const Tabs = ({ variant = "primary", selected, setSelected, items }) => {
  return (
    <div className={"tabs"}>
      {items.map(({ value, label }) => (
        <button
          key={value}
          className={classNames(`tabs__item`, {
            selected: value === selected,
            "tabs__item--primary": variant === "primary",
            "tabs__item--secondary": variant === "secondary",
          })}
          onClick={() => setSelected(value)}
        >
          {label}
        </button>
      ))}
    </div>
  )
}

export default Tabs
