import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { EmailVerificationSuccess, FirstStep, SentToEmail } from "./components"

const CreateAccountPage = () => {
  // Get step search param from url
  const searchParams = new URLSearchParams(window.location.search)
  const token = searchParams.get("token")
  const [step, setStep] = useState(0)
  const auth = useSelector((state) => state.auth)

  if (auth.isAuthenticated && auth.onboardingStage !== "AccountCreation") {
    return <Navigate to={"/"} />
  }

  const mode = token ? "complete-signup" : "signup"

  return (
    <>
      {mode === "signup" && (
        <>
          {step === 0 && <FirstStep next={() => setStep(1)} />}
          {step === 1 && <SentToEmail to={"/"} />}
        </>
      )}
      {mode === "complete-signup" && <EmailVerificationSuccess token={token} />}
    </>
  )
}

export default CreateAccountPage
