import api from "../api"
import { getToken } from "../util/util"

export const createInvite = async (body) =>
  api
    .post("/invites/create", body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const getInvites = async (body) =>
  api
    .post("/invites/getAll", body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const generateInviteToken = async (id) =>
  api
    .post(`/invites/generate-token/${id}`, null, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
