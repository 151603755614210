import { Buffer } from "buffer"
import store from "./../redux/store"
import moment from "moment"

const md5 = require("md5")

export const getToken = () => {
  const state = store.getState()
  return state.auth.token
}

export const toTitleCase = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase())
}

export function isTokenExpired(token) {
  if (!token) {
    return true // Token is considered expired if not provided
  }

  try {
    const decodedToken = JSON.parse(atob(token.split(".")[1])) // Decode the payload

    // Check if the current time is greater than or equal to the expiration time
    return Date.now() >= decodedToken.exp * 1000
  } catch {
    return true
  }
}

export const fromNow = (date) => {
  const now = moment()
  const diff = now.diff(moment(date).startOf("day"), "days", true)

  const fromNowString = moment(date).fromNow()

  if (fromNowString.includes("minutes")) {
    return fromNowString.replace("minutes", "mins")
  } else if (diff < 1) {
    return "Today"
  } else {
    return fromNowString
      .replace(" ago", "")
      .replace(/ hours?/g, "h")
      .replace(/ days?/g, "d")
      .replace(/ months?/g, "mth")
      .replace(/ years?/g, "yrs")
      .replace("a", "1")
  }
}

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
}

export const getTeamId = () => {
  const state = store.getState()
  return state.auth.team?.id
}

export async function calculateHash(file) {
  // Ensure the input is a File object
  if (!(file instanceof File)) {
    throw new Error("The input is not a File object.")
  }

  // Read the file as an ArrayBuffer
  const arrayBuffer = await file.arrayBuffer()

  const fileBuffer = Buffer.from(arrayBuffer, "base64") //

  const extension = file.name.split(".").pop()
  const name = "m" + md5(fileBuffer) + "." + extension
  return name
}

// Decode jwt
export const decodeJwt = (jwtToken) => {
  const base64Url = jwtToken.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const buff = Buffer.from(base64, "base64")
  const payloadinit = buff.toString("ascii")
  const payload = JSON.parse(payloadinit)
  return payload
}

export function getLocalStorage(key, defaultValue) {
  const stickyValue = localStorage.getItem(key)

  return stickyValue !== null && stickyValue !== "undefined"
    ? JSON.parse(stickyValue)
    : defaultValue
}

export function setLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function clamp(value, [min, max]) {
  if (!value) {
    return value
  }
  return Math.min(Math.max(value, min), max)
}

export function getDiffDays(date) {
  const now = moment()
  const diff = Math.floor(moment(date).diff(now, "days", true))

  switch (diff) {
    case 0:
      return "today"
    case 1:
      return "tomorrow"
    default:
      return `in ${diff} days`
  }
}

const ratings = [
  { min: 0, max: 3, label: "Poor match" },
  { min: 3, max: 5, label: "Fair match" },
  { min: 5, max: 7, label: "Good match" },
  { min: 7, max: 9, label: "Very Good match" },
  { min: 9, max: 10, label: "Excellent match" },
]

export function getRating(value) {
  const rating = ratings.find((r) => value >= r.min && value < r.max)
  return rating ? rating.label : ""
}

export function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(";").shift()
}

export function setCookie(name, value, expires) {
  document.cookie = `${name}=${value}; path=/ ${expires ? "; " + expires : ""}`
}
