import React, { useState } from "react"
import SignupInput from "../SignupInput"
import Modal from "../Modal"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { createInvite } from "../../../services/inviteService"
import { BLACKLIST_EMAIL_DOMAINS } from "../../../constants"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"

const InviteTeamMemberDialog = ({ onSuccess, open, setOpen }) => {
  const [loading, setLoading] = useState(false)
  const { formatMessage } = useIntl()
  const onFormSubmit = (data) => {
    setLoading(true)

    createInvite(data)
      .then(() => {
        onSuccess?.()
        setLoading(false)
        setOpen(false)
        toast.success(formatMessage({ id: "common.userInvitedSuccessfully" }))
      })
      .catch((err) => {
        setLoading(false)
        const error = err.response.data?.message
        toast.error(error)
      })
  }

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
    getValues,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {},
  })

  watch(["name", "email", "position", "message"])

  const values = getValues()

  return (
    <Modal open={open} setOpen={setOpen} closeButton={true}>
      <div className="form">
        <h1 className="form__title">
          <FormattedMessage id="common.inviteTeamMemberTitle" />
        </h1>

        <div className="form__content">
          <div className="form__inputs">
            <SignupInput
              register={register("name", {
                required: formatMessage({ id: "common.nameRequired" }),
              })}
              placeholder={formatMessage({ id: "common.teamMemberName" })}
              error={errors.name?.message}
              touched={touchedFields.name}
              value={values.name}
            />
            <SignupInput
              register={register("email", {
                required: formatMessage({ id: "common.emailRequired" }),
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                  message: formatMessage({ id: "common.invalidEmailAddress" }),
                },
                validate: (value) => {
                  const domain = value.split("@")[1]

                  if (BLACKLIST_EMAIL_DOMAINS.includes(domain)) {
                    return formatMessage({ id: "common.pleaseUseCompanyEmail" })
                  }
                },
              })}
              placeholder={formatMessage({ id: "common.email" })}
              error={errors.email?.message}
              touched={touchedFields.email}
              value={values.email}
              type="email"
            />
            <SignupInput
              register={register("position", {
                required: formatMessage({ id: "common.positionRequired" }),
              })}
              placeholder={formatMessage({ id: "common.teamMemberPosition" })}
              error={errors.position?.message}
              touched={touchedFields.position}
              value={values.position}
            />

            <SignupInput
              register={register("message", {
                required: formatMessage({ id: "common.messageRequired" }),
              })}
              placeholder={formatMessage({ id: "common.message" })}
              error={errors.message?.message}
              touched={touchedFields.message}
              value={values.message}
              type={"textarea"}
            />
          </div>

          <button
            className={classNames("btn btn--small primary mt-10 ml-auto", {
              disabled: !isValid || loading,
            })}
            onClick={handleSubmit(onFormSubmit)}
          >
            <FormattedMessage id="common.invite"/>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default InviteTeamMemberDialog
