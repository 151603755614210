import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { toast } from "react-toastify"
import { Navigate } from "react-router-dom"

import {
  setAtsJobPosts,
  setJobSpec,
  setTitle,
} from "../../../redux/slices/jobSlice"
import {
  getJobInfoByFile,
  postJobRequirements,
  selectCandidates,
} from "../../../services/jobs"
import { getCredits } from "../../../services/creditService"
import { setCredits } from "../../../redux/slices/creditSlice"
import {
  CustomButton,
  CustomLink,
  Select,
  SignupInput,
  Spinner,
} from "../../common"
import { useForm } from "react-hook-form"
import { getThirdPartyJobPosts } from "../../../services/integrationService"
import { useDropzone } from "react-dropzone"
import { GA_EVENTS } from "../../../constants/analytics"
import { FormattedMessage, useIntl } from "react-intl"

const AddJobSpecPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  const [fileUploadLoading, setFileUploadLoading] = useState(false)
  const [active, setActive] = useState("paste")

  const { selectedCVs, atsJobPosts } = useSelector((state) => state.jobs)

  const credit = useSelector((state) => state.credit)
  const cvs = useSelector((state) => state.cv.cvs)

  const [selectedJobPost, setSelectedJobPost] = useState(null)

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    getValues,
    clearErrors,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      title: "",
      description: "",
    },
  })

  useEffect(() => {
    // Fetch job posts
    // If user has active integration
    getThirdPartyJobPosts()
      .then((res) => {
        dispatch(setAtsJobPosts(res.jobPosts));
      })
      // No need to handle. User can still create a job spec
      .catch(() => {});
  }, [dispatch])

  const values = getValues()

  watch(["title", "description"])

  const handleCheckRequirements = async () => {
    if (!values.title || !values.description) {
      return toast.error(
        formatMessage({ id: "toast.pleaseFillInJobTitleAndDescription" }),
      )
    }
    setLoading(true)

    dispatch(setJobSpec(values.description))
    dispatch(setTitle(values.title))

    try {
      const response = await postJobRequirements({
        title: values.title,
        job: values.title + "\n" + values.description,
      })

      getCredits().then((credits) => {
        dispatch(setCredits(credits))
      })

      const adId = response.data.task_id

      // Set selected_candidates
      await selectCandidates({
        selected_candidates: selectedCVs,
        ad_id: adId,
      })

      navigate(`/job-match/${adId}/requirements`)
    } catch (error) {
      setLoading(false)
      toast.error(
        error?.response?.data?.message ||
          formatMessage({ id: "toast.errorOccurred" }),
      )
    }
  }

  const handleImport = () => {
    if (selectedJobPost) {
      const thisPost = atsJobPosts.find(
        (jobPost) => jobPost.id === selectedJobPost.value,
      )
      setValue("title", thisPost.title)
      const description = thisPost.description

      const el = document.createElement("div")
      el.innerHTML = description

      const text = el.innerText
      setValue("description", text)

      clearErrors()
    }
  }

  const onDrop = async (acceptedFiles) => {
    try {
      setFileUploadLoading(true)

      const formData = new FormData()
      formData.append("file", acceptedFiles[0])

      const data = await getJobInfoByFile(formData)
      setValue("title", data.title, { shouldTouch: true })
      setValue("description", data.description, { shouldTouch: true })
      clearErrors(["title", "description"])
    } catch {
      toast.error(formatMessage({ id: "toast.errorParsingFile" }))
    } finally {
      setFileUploadLoading(false)
    }
  }

  const { getInputProps, open: openFileSelect } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      // Docx
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      // Doc
      "application/msword": [".doc"],
    },
    disabled: loading || !credit.jobAdvertCredits || fileUploadLoading,
    multiple: false,
  })

  if (selectedCVs.length === 0) {
    return <Navigate to={"/job-match/select-cv"} />
  }

  const disabled =
    !values.title ||
    !values.description ||
    !credit.jobAdvertCredits ||
    cvs?.length === 0 ||
    loading ||
    fileUploadLoading

  const atsJobPostOptions = atsJobPosts?.map((jobPost) => ({
    value: jobPost.id,
    label: jobPost.title,
  }))

  return (
    <div className="add-job-spec">
      <input {...getInputProps()} multiple={true} />

      <h3 className="add-job-spec__title h3">
        <FormattedMessage id="common.findThe" />{" "}
        <span>
          <FormattedMessage id="common.bestMatchedCandidates" />
        </span>
      </h3>

      <div className="add-job-spec__content">
        {atsJobPosts && (
          <div className="add-job-spec__pull-from-ats">
            <p className="h4">
              <FormattedMessage id="common.importJobPostFromGreenhouse" />
            </p>
            <div className="add-job-spec__ats-options">
              <Select
                value={selectedJobPost}
                onChange={(newValue) => setSelectedJobPost(newValue)}
                name={"job"}
                placeholder={"Job"}
                options={atsJobPostOptions}
                label={"Job Post"}
              />
              <CustomButton
                onClick={handleImport}
                className="btn primary btn--small"
                trackingEvent={GA_EVENTS.JOB_SPEC.IMPORT_FROM_ATS}
              >
                <FormattedMessage id="common.import" />
              </CustomButton>
            </div>
          </div>
        )}

        <p className="h4">
          <FormattedMessage id="common.addAJobDescription" />
        </p>

        <div className="add-job-spec__buttons">
          <CustomButton
            className={classNames({ selected: active === "paste" })}
            onClick={() => setActive("paste")}
            trackingEvent={GA_EVENTS.JOB_SPEC.PASTE_TEXT}
          >
            <FormattedMessage id="common.pasteText" />
          </CustomButton>
          <CustomButton
            className={classNames({ selected: active === "upload" })}
            onClick={() => {
              setActive("upload")
              openFileSelect?.()
            }}
            trackingEvent={GA_EVENTS.JOB_SPEC.UPLOAD}
          >
            <FormattedMessage id="common.uploadFromMyComputer" />
          </CustomButton>
        </div>

        {active === "upload" && (
          <p>
            <FormattedMessage id="common.chooseDocOrPdfFile" />
          </p>
        )}

        <div className="flex justify-center w-full">
          {fileUploadLoading && <Spinner />}
        </div>

        <div className="add-job-spec__form">
          <SignupInput
            register={register("title", {
              required: formatMessage({ id: "common.titleRequired" }),
              minLength: {
                value: 5,
                message: formatMessage({ id: "common.titleMinLength" }),
              },
            })}
            placeholder={formatMessage({ id: "common.jobTitle" })}
            error={errors.title?.message}
            touched={touchedFields.title}
            value={values.title}
          />
          <SignupInput
            register={register("description", {
              required: formatMessage({ id: "common.descriptionRequired" }),
              minLength: {
                value: 100,
                message: formatMessage({ id: "common.descriptionMinLength" }),
              },
            })}
            placeholder={formatMessage({ id: "common.pasteJobDescription" })}
            error={errors.description?.message}
            touched={touchedFields.description}
            value={values.description}
            type="textarea"
            row={30}
          />
        </div>

        {cvs?.length === 0 && (
          <p>
            <FormattedMessage id="common.pleaseUploadSomeCVsFirst" />
          </p>
        )}

        {loading && (
          <div className="w-full flex flex-col items-center">
            <Spinner />
          </div>
        )}

        <CustomButton
          onClick={handleSubmit(handleCheckRequirements)}
          className={classNames("btn primary btn--small", { disabled })}
          disabled={disabled}
          trackingEvent={GA_EVENTS.JOB_SPEC.CREATE_JOB_MATCH}
        >
          <FormattedMessage id="common.createJobMatch" />{" "}
          <span className="text-normal">
            <FormattedMessage id="common.uses1JobMatch" />
          </span>
        </CustomButton>

        <p className="add-job-spec__info">
          {credit.jobAdvertCredits}{" "}
          <FormattedMessage id="common.jobMatchesAvailable" />
          {" - "}
          <CustomLink
            to={"/buy-more"}
            className="add-job-spec__link"
            trackingEvent={GA_EVENTS.JOB_SPEC.GET_MORE_UPSELL}
          >
            <FormattedMessage id="common.getMore" />
          </CustomLink>
        </p>
      </div>
    </div>
  )
}

export default AddJobSpecPage
