import React, { useEffect, useMemo, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { toast } from "react-toastify"

import { PaymentForm } from "./components"
import { getProducts } from "../../../services/productService"
import { Spinner } from "../../common"
import { getPendingPayment } from "../../../services/paymentService"
import { backToChoosePricing } from "../../../services/auth"
import { loginAction } from "../../../redux/slices/authSlice"
import { FormattedMessage, useIntl } from "react-intl"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const PendingPaymentPage = () => {
  const auth = useSelector((state) => state.auth)
  const [backLoading, setBackLoading] = useState(false)

  const [products, setProducts] = useState(null)
  const [data, setData] = useState(null)

  const setupIntent = auth.team?.pendingSetupIntent
  const pendingInvoice = auth.team?.pendingSinglePurchaseInvoice
  const pendingSubscriptionInvoice = auth.team?.pendingSubscriptionInvoice

  const hasPendingPayment =
    Boolean(setupIntent) ||
    Boolean(pendingInvoice) ||
    Boolean(pendingSubscriptionInvoice)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  useEffect(() => {
    const fetchProducts = async () => {
      getProducts().then((data) => setProducts(data.products))
    }

    hasPendingPayment && fetchProducts()
  }, [])

  useEffect(() => {
    const fetchPendingPayment = async () => {
      getPendingPayment().then((data) => setData(data))
    }

    hasPendingPayment && fetchPendingPayment()
  }, [])

  const productId = auth.team?.pendingProductId
  const pendingQuantity = auth.team?.pendingQuantity

  const pendingProduct = useMemo(
    () => products?.find(({ id }) => id === productId),
    [productId, products],
  )

  if (auth.onboardingStage !== "PaymentPending") {
    return <Navigate to={"/choose-pricing"} />
  }

  if (!products || !data?.clientSecret || !pendingProduct) {
    return (
      <div className="pending-payment">
        <div className="pending-payment__wrapper">
          <div className="pending-payment__content">
            <Spinner />
          </div>
        </div>
      </div>
    )
  }

  const handleBackClick = async () => {
    setBackLoading(true)

    try {
      const data = await backToChoosePricing()
      dispatch(loginAction(data))
      navigate("/choose-pricing")
    } catch (err) {
      toast.error(formatMessage({ id: "toast.failedToGoBackToChoosePricing" }))
    } finally {
      setBackLoading(false)
    }
  }

  const options = {
    // passing the client secret obtained from the server
    clientSecret: data.clientSecret,
  }

  return (
    <div className="pending-payment">
      <div className="pending-payment__wrapper">
        <button
          disabled={backLoading}
          className="payment-page__link"
          onClick={handleBackClick}
        >
          <FormattedMessage id="common.back" />
        </button>
        <div className="pending-payment__content">
          <Elements stripe={stripePromise} options={options}>
            <PaymentForm
              {...{
                ...data,
                pendingProduct,
                pendingQuantity,
              }}
            />
          </Elements>
        </div>
      </div>
    </div>
  )
}

export default PendingPaymentPage
