import { SORT_OPTIONS } from "../../../../constants";

export const filterCVsByWord = (cvs = [], word = "", sort = SORT_OPTIONS.DESC) => {
  const searchFiltered =
    cvs?.filter(({ information: { first_name, last_name, file_name } }) => {
      const searchLowered = word.toLowerCase()
      return (
        first_name?.toLowerCase().includes(searchLowered) ||
        last_name?.toLowerCase().includes(searchLowered) ||
        file_name?.toLowerCase().includes(searchLowered)
      )
    }) ?? []

  if (sort === SORT_OPTIONS.ASC) {
    searchFiltered.reverse()
  }

  return searchFiltered
}
