import React from "react"
import { useSelector } from "react-redux"
import { getDiffDays } from "../../../../util/util"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"

const Info = () => {
  const auth = useSelector((state) => state.auth)
  const credit = useSelector((state) => state.credit)

  const trialUser =
    auth.onboardingStage === "Trialing" && auth.team.trialEndAt ? true : false

  const trialEndDate = auth.team.trialEndAt
    ? new Date(auth.team.trialEndAt)
    : null

  const hasTrialEnded =
    trialEndDate < new Date() ||
    (credit?.cvCredits === 0 && credit?.jobAdvertCredits === 0)

  if (trialUser && !hasTrialEnded) {
    return (
      <div className="info">
        <p className="info__text info__text--highlight">
          <FormattedMessage
            id="message.yourFreeTrialEnds"
            values={{ days: getDiffDays(trialEndDate) }}
          />
        </p>
        <p className="info__text">
          {credit?.cvCredits > 0 && (
            <FormattedMessage
              id="message.uploadMoreCVs"
              values={{ cvCredits: credit?.cvCredits }}
            />
          )}
          {" "}
          <Link to={"/choose-pricing"}>
            <FormattedMessage id="message.chooseAPlanHere" />
          </Link>
        </p>
      </div>
    )
  }

  if (trialUser && hasTrialEnded) {
    return (
      <div className="info">
        <p className="info__text info__text--highlight">
          <FormattedMessage id="message.yourFreeTrialHasEnded" />{" "}
          <Link to={"/choose-pricing"}>
            <FormattedMessage id="common.chooseAPlan" />
          </Link>
        </p>
      </div>
    )
  }

  if (!trialUser && credit?.cvCredits === 0) {
    return (
      <div className="info">
        <p className="info__text info__text--highlight">
          <FormattedMessage id="message.youHaveUsedYourCredits" />{" "}
          <Link to={"/buy-more"}>
            <FormattedMessage id="common.addMoreHere" />
          </Link>
        </p>
      </div>
    )
  }

  return (
    <div className="info">
      <p className="info__text">
        <FormattedMessage
          id="message.youHaveCredits"
          values={{
            cvCredits: credit?.cvCredits,
            jobMatches: credit?.jobAdvertCredits
          }}
        />
      </p>
    </div>
  )
}

export default Info
