import React from "react"
import ReactGA from "react-ga4"
import { useDispatch, useSelector } from "react-redux"

import CustomLink from "../../../common/CustomLink"
import { Tabs } from "../../../common"
import { setForm } from "../../../../redux/slices/signupSlice"
import { GA_EVENTS } from "../../../../constants/analytics"
import { FormattedMessage, useIntl } from "react-intl"

const tabItems = [
  // {
  //   value: "PayAsYouGo",
  //   label: "common.payAsYouGo",
  // },
  // {
  //   value: "Subscription",
  //   label: "common.subscription",
  // },
  {
    value: "self-service",
    label: "common.selfService",
  },
  {
    value: "enterprise",
    label: "common.enterprise",
  }
]

const Hero = ({hires, setHires, planType, setPlanType}) => {
  // const subscriptionType = useSelector((state) => state.signup.subscriptionType)
  // const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const setSelected = (selected) => {
    ReactGA.event(GA_EVENTS.CHOOSE_PRICING[selected] ?? GA_EVENTS.CHOOSE_PRICING.TAB_SELECTED)
    // dispatch(setForm({ name: "subscriptionType", value: selected }))

    if (selected === "self-service") {
      setPlanType('self-service');
      if (hires > 30) setHires(30);
    }

    if (selected === "enterprise") {
      setPlanType('enterprise');
      if (hires < 30) setHires(30);
    }
  }

  return (
    <div className="hero">
      <img
        src={"/img/landing/PayAsYouGo.png"}
        alt={"payAsYouGo"}
        className="hero__image"
      />

      <div className="hero__wrapper">
        <Tabs
          variant={"secondary"}
          items={tabItems.map(({ value, label }) => ({
            value,
            label: formatMessage({ id: label }),
          }))}
          selected={planType}
          setSelected={setSelected}
        />

        <>
          <div className="hero__content">
            <p className="hero__text">
              <b>{"Let's calculate pricing to fit only what you need."}</b>
              {" "}
              {"For any help, feel free to"}
              {" "}
              <CustomLink to="/">{"ask us anything"}</CustomLink>
            </p>
          </div>
        </>
      </div>
    </div>
  )
}

export default Hero
