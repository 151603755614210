import React from "react"
import { FormattedMessage } from "react-intl"


const Requirements = ({ data }) => {
  const { skills , job_requirements } = data
  const hardSkills =
    skills?.filter((skill) => skill.type === "hard-skill") ?? []
  const softSkills =
    skills?.filter((skill) => skill.type === "soft-skill") ?? []



  return (
    <>
      <div className="requirement__highlights-wrapper">
        <div className="requirement__highlights-item">
          <p className="requirement__highlights-title">
            <FormattedMessage id="common.highlights" />
          </p>
        </div>

        {job_requirements.map((value) => (
          <div className="requirement__highlights-item" key={`Highlight - ${value}`}>
            <p className="requirement__highlights-info">{value}</p>
          </div>
        ))}
      </div>

      <div className="box">
        <h4 className="h4">
          <FormattedMessage id="common.hardSkills" />
        </h4>

        <div className="box__requirements">
          {hardSkills?.map((hardSkill) => (
            <div className="role flex-col skill" key={hardSkill.id}>
              <h4>{hardSkill.skill}</h4>
            </div>
          ))}
        </div>
      </div>
      <div className="box">
        <h4 className="h4">
          <FormattedMessage id="common.softSkills" />
        </h4>

        <div className="box__requirements">
          {softSkills?.map((softSkill) => (
            <div className="role flex-col skill" key={softSkill.id}>
              <h4>{softSkill.skill}</h4>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}


export default Requirements
