import ReactGA from "react-ga4"
import { NavLink } from "react-router-dom"
import { GA_EVENTS } from "../../../constants/analytics"


const CustomNavLink = ({
  children,
  trackingEvent = GA_EVENTS.GENERAL.NAV_LINK_CLICKED,
  onClick,
  ...props
}) => {
  const onClickHandler = (e) => {
    ReactGA.event(trackingEvent)
    onClick?.(e)
  }

  return (
    <NavLink
      onClick={onClickHandler}
      {...props}
    >
      {children}
    </NavLink>
  )
}

export default CustomNavLink;