import api from "../api"
import { getToken } from "../util/util"

export const createNewTeamUser = async ({
  email,
  name,
  address,
  phoneNumber,
  password,
}) =>
  api
    .post(
      "/teamUsers/create",
      { email, name, address, phoneNumber, password },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    )
    .then((res) => res.data)

export const getAllTeamUsers = async () =>
  api
    .post("/teamUsers/getAll", null, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
