import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import React, { useState } from "react"
import { SubscriptionButton } from "../../../common"
import { FormattedMessage, useIntl } from "react-intl"

const UpgradeDowngradeBillingItem = ({
  item,
  itemInfo,
  handleProductSelect,
  loading,
  currentSubscription,
}) => {
  const [isNextClicked, setIsNextClicked] = useState(false)
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const itemPrice = parseInt(itemInfo.price.replaceAll(",", ""))

  const isActive = currentSubscription.amountPerMonth === itemPrice

  const isDowngrade = currentSubscription.amountPerMonth > itemPrice

  return (
    <div className="billing-item">
      {isActive ? (
        <p className="billing-item__badge billing-item__badge--popular">
          <FormattedMessage id="common.yourCurrentPlan" />
        </p>
      ) : (
        itemInfo.isPopular && (
          <p className="billing-item__badge billing-item__badge--popular">
            <FormattedMessage id="common.mostPopularPack" />
          </p>
        )
      )}

      <div className="billing-item__content">
        <p className="billing-item__title">{item.productName}</p>

        {itemInfo.texts.map((text, i) => (
          <p
            className={classNames("billing-item__text", {
              "billing-item__text--highlighted": text.highlighted,
            })}
          >
            <FormattedMessage id={text.text} values={{ amount: text.amount }} />
          </p>
        ))}

        <div className="billing-item__price-wrapper mt-4">
          <p className="billing-item__price">
            <span>€{itemInfo.price}</span>{" "}
            <FormattedMessage id={itemInfo.unit} />
          </p>
        </div>
      </div>

      <div className="billing-item__button-wrapper">
        {!isNextClicked && itemInfo.saves && (
          <p
            className={classNames("billing-item__saves", {
              "billing-item__saves--popular": itemInfo.isPopular || isActive,
            })}
          >
            <FormattedMessage id="common.saves" /> {itemInfo.saves}
          </p>
        )}
        {!isNextClicked && (
          <SubscriptionButton
            isLowcost={false}
            isPopular={isActive || itemInfo.isPopular}
            onClick={
              isActive
                ? () => navigate("/plan-and-billing")
                : () => setIsNextClicked(true)
            }
            text={
              isActive
                ? formatMessage({ id: "common.stayWithThisPlan" })
                : isDowngrade
                ? formatMessage({ id: "common.downgrade" })
                : formatMessage({ id: "common.upgrade" })
            }
            loading={loading}
          />
        )}
        {isNextClicked && (
          <div className="billing-item__buttons">
            <SubscriptionButton
              onClick={() => {
                setIsNextClicked(false)
                handleProductSelect(item, { switchNow: true })
              }}
              text={formatMessage({ id: "common.changeNow" })}
              loading={loading}
              showArrow={false}
            />
            <SubscriptionButton
              onClick={() => {
                setIsNextClicked(false)
                handleProductSelect(item, { switchNow: false })
              }}
              text={formatMessage({ id: "common.fromNextBillingDate" })}
              loading={loading}
              showArrow={false}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default UpgradeDowngradeBillingItem
