import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js"
import { useState } from "react"
import { toast } from "react-toastify"

import { Spinner } from "../../../../common"
import { FormattedMessage, useIntl } from "react-intl"

const PaymentForm = ({
  clientSecret,
  invoiceId,
  setupIntentId,
  pendingQuantity,
  pendingProduct,
}) => {
  const [loading, setLoading] = useState(false)

  const stripe = useStripe()
  const elements = useElements()
  const { formatMessage } = useIntl()

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    let result

    setLoading(true)
    if (setupIntentId) {
      result = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url:
            window.location.href.split("?")[0].replace("/pending-payment", "") +
            `/payment-confirmation?setupIntentId=${setupIntentId}`,
        },
      })
    } else {
      result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url:
            window.location.href.split("?")[0].replace("/pending-payment", "") +
            `/payment-confirmation?invoiceId=${invoiceId}`,
        },
      })
    }

    setLoading(false)

    if (result.error) {
      toast.error(result?.error?.message)
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  if (!pendingProduct) {
    return null
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {loading && (
        <div className="flex items-center justify-center m-4">
          <Spinner />
        </div>
      )}
      {setupIntentId && (
        <p className="mt-2 text-center">
          <FormattedMessage
            id="message.subscriptionPayment"
            values={{
              totalPrice: pendingProduct.totalPrice,
            }}
          />
        </p>
      )}
      <button className="pending-payment__submit" disabled={!stripe || loading}>
        {setupIntentId
          ? formatMessage({ id: "common.submitPaymentDetails" })
          : formatMessage(
              { id: "common.payTotalPrice" },
              {
                payTotalPrice:
                  pendingProduct.totalPrice * (pendingQuantity || 1),
              },
            )}
      </button>
    </form>
  )
}

export default PaymentForm
