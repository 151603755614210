import { combineReducers } from "@reduxjs/toolkit"

// reducers
import cvSlice from "./slices/cvSlice"
import jobSlice from "./slices/jobSlice"
import authSlice from "./slices/authSlice"
import creditSlice from "./slices/creditSlice"
import signupSlice from "./slices/signupSlice"
import integrationSlice from "./slices/integrationSlice"

const reducers = combineReducers({
  cv: cvSlice,
  jobs: jobSlice,
  auth: authSlice,
  credit: creditSlice,
  signup: signupSlice,
  integrations: integrationSlice,
})

export default reducers
