import React from "react"
import { FormattedMessage } from "react-intl"

const TermsOfUse = () => {
  return (
    <div className="terms">
      <div className="terms__wrapper">
        <p className="terms__title">
          <FormattedMessage id="terms.title1" />
          <br /> <FormattedMessage id="terms.title2" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.description" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.accessAndUse" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.reviewTerms" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.privacyPolicy" />
        </p>
        <p className="terms__h-level-1">
          <FormattedMessage id="terms.saasAndUserRegistrationConditions" />
        </p>
        <p className="terms__h-level-2 mt-2">
          <FormattedMessage id="terms.servicesProvisionAndUserRegistration" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.servicesDescription" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.companyCommitment" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.userRegistrationConditions" />
        </p>
        <p className="terms__h-level-3">
          <FormattedMessage id="terms.userRegistrationTitle" />
        </p>
        <p className="terms__body mb-0">
          <FormattedMessage id="terms.accessRequirements" />
        </p>
        <ul className="terms__list">
          <li className="terms__list-item">
            <FormattedMessage id="terms.userRegistrationItems.1.2.1" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.userRegistrationItems.1.2.2" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.userRegistrationItems.1.2.3" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.userRegistrationItems.1.2.4" />
          </li>
        </ul>
        <p className="terms__h-level-2">
          <FormattedMessage id="terms.restrictionsAndResponsibilities" />
        </p>

        <ul className="terms__list">
          <li className="terms__list-item">
            <FormattedMessage id="terms.userEligibilityAndAgreement" />
            <ul className="terms__list">
              <li className="terms__list-item">
                <FormattedMessage id="terms.userEligibilityAndAgreementPoints.a" />
              </li>
              <li className="terms__list-item">
                <FormattedMessage id="terms.userEligibilityAndAgreementPoints.b" />
              </li>
              <li className="terms__list-item">
                <FormattedMessage id="terms.userEligibilityAndAgreementPoints.c" />
              </li>
              <li className="terms__list-item">
                <FormattedMessage id="terms.userEligibilityAndAgreementPoints.d" />
              </li>
            </ul>
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.userAffirmation" />
          </li>
        </ul>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.prohibitedActions" />
        </p>
        <ul className="terms__list">
          <li className="terms__list-item">
            <FormattedMessage id="terms.customerShallNot" />
            <ul className="terms__list ml-5">
              <li className="terms__list-item">
                <FormattedMessage id="terms.customerShallNotPoints.a" />
              </li>
              <li className="terms__list-item">
                <FormattedMessage id="terms.customerShallNotPoints.b" />
              </li>
              <li className="terms__list-item">
                <FormattedMessage id="terms.customerShallNotPoints.c" />
              </li>
              <li className="terms__list-item">
                <FormattedMessage id="terms.customerShallNotPoints.d" />
              </li>
            </ul>
          </li>
        </ul>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.complianceWithPoliciesAndLaws" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.customerCommitmentToCompliance" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.responsibilitiesCustomerTitle" />
        </p>
        <ul className="terms__list">
          <li className="terms__list-item">
            <FormattedMessage id="terms.responsibilitiesCustomer.item1" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.responsibilitiesCustomer.item2" />
          </li>
        </ul>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.confidentialityTitle" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.confidentialityToleTitle" />
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.confidentiality.proprietaryInfoTitle" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.confidentiality.proprietaryInfoText" />
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.confidentiality.informationSharingTitle" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.confidentiality.informationSharing.text1" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.confidentiality.informationSharing.text2" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.confidentiality.informationSharing.text3" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.confidentiality.informationSharing.text4" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.confidentiality.informationSharing.text5" />
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.confidentiality.protectionTitle" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.confidentiality.protectionText" />
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.confidentiality.accountSecurityTitle" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.confidentiality.accountSecurity.text1" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.confidentiality.accountSecurity.text2" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.licenseAndOwnershipTitle" />
        </p>
        <p className="terms__h-level-3">
          <FormattedMessage id="terms.licenseGrantedToCompanyTitle" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.licenseGrantedToCompanyText" />
        </p>
        <p className="terms__h-level-3">
          <FormattedMessage id="terms.ownershipOfCustomerDataTitle" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.ownershipOfCustomerDataText" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.intellectualPropertyRightsTitle" />
        </p>
        <p className="terms__h-level-3">
          <FormattedMessage id="terms.ownershipOfIntellectualPropertyTitle" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.ownershipOfIntellectualPropertyText" />
        </p>
        <p className="terms__h-level-3">
          <FormattedMessage id="terms.limitedRoleOfPlatformTitle" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.limitedRoleOfPlatformText" />
        </p>
        <p className="terms__h-level-3">
          <FormattedMessage id="terms.noResponsibilityForContentTitle" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.noResponsibilityForContentText" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.dataHandlingUserResponsibilitiesTitle" />
        </p>
        <p className="terms__h-level-3">
          <FormattedMessage id="terms.userResponsibilitiesTitle" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.userResponsibilitiesText" />
        </p>
        <p className="terms__h-level-3">
          <FormattedMessage id="terms.discoveryOfUnlawfulCustomerDataTitle" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.discoveryOfUnlawfulCustomerDataText" />
        </p>
        <p className="terms__h-level-3">
          <FormattedMessage id="terms.actionsInResponseToUnlawfulCustomerDataTitle" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.unlawfulDataAttention" />
          <ul className="terms__list">
            <li className="terms__list-item">
              <FormattedMessage id="terms.actionsInResponseToUnlawfulCustomerData.a" />
            </li>
            <li className="terms__list-item">
              <FormattedMessage id="terms.actionsInResponseToUnlawfulCustomerData.b" />
            </li>
            <li className="terms__list-item">
              <FormattedMessage id="terms.actionsInResponseToUnlawfulCustomerData.c" />
            </li>
            <li className="terms__list-item">
              <FormattedMessage id="terms.actionsInResponseToUnlawfulCustomerData.d" />
            </li>
          </ul>
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.companysDiscretionInDataRemovalTitle" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.companysDiscretionInDataRemovalText" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.dataResponsibilityAndComplianceTitle" />
        </p>
        <p className="terms__h-level-3">
          <FormattedMessage id="terms.customersDataOwnershipAndAuthorizationTitle" />
          <ul className="terms__list">
            <li className="terms__list-item">
              <FormattedMessage id="terms.customersDataOwnershipAndAuthorization.a" />
            </li>
            <li className="terms__list-item">
              <FormattedMessage id="terms.customersDataOwnershipAndAuthorization.b" />
            </li>
            <li className="terms__list-item">
              <FormattedMessage id="terms.customersDataOwnershipAndAuthorization.c" />
            </li>
          </ul>
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.complianceWithDataProtectionLawsTitle" />
          <ul class="terms__list">
            <li class="terms__list-item">
              <FormattedMessage id="terms.complianceWithDataProtectionLaws.a" />
            </li>
            <li class="terms__list-item">
              <FormattedMessage id="terms.complianceWithDataProtectionLaws.b" />
            </li>
          </ul>
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.platformsLimitedRoleTitle" />
          <ul class="terms__list">
            <li class="terms__list-item">
              <FormattedMessage id="terms.platformsLimitedRole.a" />
            </li>
            <li class="terms__list-item">
              <FormattedMessage id="terms.platformsLimitedRole.b" />
            </li>
          </ul>
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.securityMeasuresTitle" />
          <ul class="terms__list">
            <li class="terms__list-item">
              <FormattedMessage id="terms.securityMeasures.a" />
            </li>
            <li class="terms__list-item">
              <FormattedMessage id="terms.securityMeasures.b" />
            </li>
          </ul>
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.dataDeletionTitle" />
        </p>
        <p class="terms__body">
          <FormattedMessage id="terms.dataDeletionText" />
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.cooperationWithAuthoritiesTitle" />
        </p>
        <p class="terms__body">
          <FormattedMessage id="terms.cooperationWithAuthoritiesText" />
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.changesInTheLawTitle" />
        </p>
        <p class="terms__body">
          <FormattedMessage id="terms.changesInTheLawText" />
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.indemnificationTitle" />
        </p>
        <p class="terms__body">
          <FormattedMessage id="terms.indemnificationText" />
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.personalDataTitle" />
        </p>
        <p className="terms__h-level-3">
          <FormattedMessage id="terms.dataRetentionTitle" />
        </p>
        <ul class="terms__list">
          <p className="terms__body mb-0">
            <FormattedMessage id="terms.personalDataCollectionPurpose" />
          </p>
          <li class="terms__list-item">
            <FormattedMessage id="terms.dataRetention.a" />
          </li>
          <li class="terms__list-item">
            <FormattedMessage id="terms.dataRetention.b" />
          </li>
          <li class="terms__list-item">
            <FormattedMessage id="terms.dataRetention.c" />
          </li>
          <li class="terms__list-item">
            <FormattedMessage id="terms.dataRetention.d" />
          </li>
        </ul>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.contactOfDpoTitle" />
        </p>
        <p class="terms__body">
          <FormattedMessage id="terms.contactOfDpoText1" />
        </p>
        <p class="terms__body">
          <FormattedMessage id="terms.contactOfDpoText2" />
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.privacyPolicyTitle" />
        </p>
        <p class="terms__body">
          <FormattedMessage id="terms.privacyPolicyText1" />
        </p>
        <p class="terms__body">
          <FormattedMessage id="terms.privacyPolicyText2" />
        </p>
        <p class="terms__body">
          <FormattedMessage id="terms.privacyPolicyText3" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.definitionsTitle" />
        </p>
        <ul className="terms__list terms__list--bullet">
          <li class="terms__list-item">
            <FormattedMessage id="terms.ourServicesDefinition" />
          </li>
          <li class="terms__list-item">
            <FormattedMessage id="terms.customerDefinition" />
          </li>
          <li class="terms__list-item">
            <FormattedMessage id="terms.personalDataDefinition" />
          </li>
          <li class="terms__list-item">
            <FormattedMessage id="terms.controllerDefinition" />
          </li>
          <li class="terms__list-item">
            <FormattedMessage id="terms.processorDefinition" />
          </li>
          <li class="terms__list-item">
            <FormattedMessage id="terms.personalDataDefinitionExtended" />
          </li>
        </ul>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.changePrivacyPolicy.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.changePrivacyPolicy.body" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.thirdPartyLinks.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.thirdPartyLinks.body" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.failToProvideData.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.failToProvideData.body" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.dataWeCollect.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.dataWeCollect.body" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.collectPersonalData.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.collectPersonalData.you.title" />
        </p>
        <ul className="terms__list terms__list--bullet">
          <li className="terms__list-item">
            <FormattedMessage id="terms.collectPersonalData.you.items1" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.collectPersonalData.you.items2" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.collectPersonalData.you.items3" />
          </li>
        </ul>
        <p className="terms__body">
          <FormattedMessage id="terms.collectPersonalData.thirdParties.title" />
        </p>
        <ul className="terms__list terms__list--bullet">
          <li className="terms__list-item">
            <FormattedMessage id="terms.collectPersonalData.thirdParties.items1" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.collectPersonalData.thirdParties.items2" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.collectPersonalData.thirdParties.items3" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.collectPersonalData.thirdParties.items4" />
          </li>
        </ul>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.informationWeCollect.title" />
        </p>
        <p className="terms__body mb-0">
          <FormattedMessage id="terms.informationWeCollect.body" />
          <ul className="terms__list terms__list--bullet">
            <li className="terms__list-item">
              <FormattedMessage id="terms.informationWeCollect.items1" />
            </li>
            <li className="terms__list-item">
              <FormattedMessage id="terms.informationWeCollect.items2" />
            </li>
            <li className="terms__list-item">
              <FormattedMessage id="terms.informationWeCollect.items3" />
            </li>
            <li className="terms__list-item">
              <FormattedMessage id="terms.informationWeCollect.items4" />
            </li>
            <li className="terms__list-item">
              <FormattedMessage id="terms.informationWeCollect.items5" />
            </li>
          </ul>
        </p>

        <p className="terms__body mt-5">
          <FormattedMessage id="terms.fullNameEmailRequired.body" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.aggregatedData.body" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.categoriesPersonalData.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.categoriesPersonalData.body" />
        </p>

        <div className="terms__table-wrapper">
          <table className="terms__table">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="terms.table.headers.personalData" />
                </th>
                <th>
                  <FormattedMessage id="terms.table.headers.purposesOfUse" />
                </th>
                <th>
                  <FormattedMessage id="terms.table.headers.source" />
                </th>
                <th>
                  <FormattedMessage id="terms.table.headers.thirdParties" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p className="terms__body">
                    <FormattedMessage id="terms.table.rows.personalData1" />
                  </p>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes1" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes2" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes3" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes4" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes5" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes6" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes7" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes8" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes9" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes10" />
                    </li>
                  </ul>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.source1" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.source2" />
                    </li>
                  </ul>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.thirdParties1" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.thirdParties2" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.thirdParties3" />
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="terms__body">
                    <FormattedMessage id="terms.table.rows.commercialInfo1" />
                  </p>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes1" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes2" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes3" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes4" />
                    </li>
                  </ul>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <p className="terms__body">
                    <FormattedMessage id="terms.table.rows.networkActivity1" />
                  </p>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes1" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes2" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes3" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes4" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes5" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes6" />
                    </li>
                  </ul>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.source1" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.source2" />
                    </li>
                  </ul>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.thirdParties1" />
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="terms__body">
                    <FormattedMessage id="terms.table.rows.professionalInfo1" />
                  </p>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes1" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes2" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes3" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes4" />
                    </li>
                  </ul>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.source1" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.source2" />
                    </li>
                  </ul>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.thirdParties1" />
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="terms__body">
                    <FormattedMessage id="terms.table.rows.paymentInfo1" />
                  </p>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes1" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes2" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes3" />
                    </li>
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.purposes4" />
                    </li>
                  </ul>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.source1" />
                    </li>
                  </ul>
                </td>
                <td>
                  <ul className="terms__list terms__list--bullet">
                    <li className="terms__list-item">
                      <FormattedMessage id="terms.table.rows.thirdParties1" />
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.tailoredContent.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.tailoredContent.body" />
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.cookies.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.cookies.body1" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.cookies.body2" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.cookies.whatIsCookie.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.cookies.whatIsCookie.body" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.cookies.howFriblUsesCookies.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.cookies.howFriblUsesCookies.body" />
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.generativeAI.title" />
        </p>
        <p className="terms__body mb-0">
          <FormattedMessage id="terms.generativeAI.body" />
        </p>
        <ul className="terms__list terms__list--bullet">
          <li className="terms__list-item">
            <FormattedMessage id="terms.generativeAI.items1" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.generativeAI.items2" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.generativeAI.items3" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.generativeAI.items4" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.generativeAI.items5" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.generativeAI.items6" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.generativeAI.items7" />
          </li>
        </ul>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.changesToServices.title" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.changesToServices.companyChanges.title" />
        </p>
        <p className="terms__body mb-0">
          <FormattedMessage id="terms.changesToServices.companyChanges.body" />
        </p>
        <ul className="terms__list terms__list--bullet">
          <li className="terms__list-item">
            <FormattedMessage id="terms.changesToServices.companyChanges.items1" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.changesToServices.companyChanges.items2" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.changesToServices.companyChanges.items3" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.changesToServices.companyChanges.items4" />
          </li>
        </ul>

        <p className="terms__body">
          <FormattedMessage id="terms.changesToServices.body" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.changesToServices.thirdPartySites.title" />
        </p>

        <ul className="terms__list">
          <li className="terms__list-item">
            <FormattedMessage id="terms.thirdPartySites.linksResponsibility" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.thirdPartySites.liability" />
          </li>
        </ul>

        <p className="terms__body">
          <FormattedMessage id="terms.thirdPartySites.useAtOwnRisk" />
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.aiFeatures.title" />
        </p>

        <p className="terms__h-level-3">
          <FormattedMessage id="terms.aiFeatures.disclaimer.title" />
        </p>

        <p className="terms__body">
          <span className="terms__bold">
            <FormattedMessage id="terms.aiFeatures.natureOfAIFeatures.bold" />
          </span>
          &nbsp;
          <FormattedMessage id="terms.aiFeatures.natureOfAIFeatures.body" />
        </p>
        <p className="terms__body">
          <span className="terms__bold">
            <FormattedMessage id="terms.aiFeatures.advisoryNature.bold" />
          </span>
          &nbsp;
          <FormattedMessage id="terms.aiFeatures.advisoryNature.body" />
        </p>
        <p className="terms__body">
          <span className="terms__bold">
            <FormattedMessage id="terms.aiFeatures.customerResponsibility.bold" />
          </span>
          &nbsp;
          <FormattedMessage id="terms.aiFeatures.customerResponsibility.body" />
        </p>
        <p className="terms__body">
          <span className="terms__bold">
            <FormattedMessage id="terms.aiFeatures.noLiability.bold" />
          </span>
          &nbsp;
          <FormattedMessage id="terms.aiFeatures.noLiability.body" />
        </p>
        <p className="terms__body">
          <span className="terms__bold">
            <FormattedMessage id="terms.aiFeatures.continuousImprovement.bold" />
          </span>
          &nbsp;
          <FormattedMessage id="terms.aiFeatures.continuousImprovement.body" />
        </p>
        <p className="terms__body">
          <span className="terms__bold">
            <FormattedMessage id="terms.aiFeatures.acknowledgment.bold" />
          </span>
          &nbsp;
          <FormattedMessage id="terms.aiFeatures.acknowledgment.body" />
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.feesAndPayments.title" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.feesAndPayments.feesOutline" />
        </p>

        <ol className="terms__list terms__list--serial">
          <li className="terms__list-item">
            <FormattedMessage id="terms.feesAndPayments.paymentTiming" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.feesAndPayments.nonRefundable" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.feesAndPayments.updatingInformation" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.feesAndPayments.paymentAuthorization.title" />
            <ul className="terms__list terms__list--bullet">
              <li className="terms__list-item">
                <FormattedMessage id="terms.feesAndPayments.paymentAuthorization.creditCard" />
              </li>
              <li className="terms__list-item">
                <FormattedMessage id="terms.feesAndPayments.paymentAuthorization.oneOff" />
              </li>
              <li className="terms__list-item">
                <FormattedMessage id="terms.feesAndPayments.paymentAuthorization.directDebit" />
              </li>
              <li className="terms__list-item">
                <FormattedMessage id="terms.feesAndPayments.paymentAuthorization.stopService" />
              </li>
            </ul>
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.feesAndPayments.pricingRenewal.title" />
            <ul className="terms__list terms__list--bullet">
              <li className="terms__list-item">
                <FormattedMessage id="terms.feesAndPayments.pricingRenewal.increase" />
              </li>
            </ul>
            <p className="terms__body">
              <FormattedMessage id="terms.feesAndPayments.pricingRenewal.companySize" />
            </p>
            <p className="terms__body">
              <FormattedMessage id="terms.feesAndPayments.pricingRenewal.paymentAuthorization" />
            </p>
          </li>
        </ol>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.termAndTermination.title" />
        </p>

        <p className="terms__body">
          <FormattedMessage id="terms.termAndTermination.effectiveUponAcceptance" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.termAndTermination.uponTermination.title" />
        </p>

        <ul className="terms__list">
          <li className="terms__list-item">
            <FormattedMessage id="terms.termAndTermination.uponTermination.item1" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.termAndTermination.uponTermination.item2" />
          </li>
          <li className="terms__list-item">
            <FormattedMessage id="terms.termAndTermination.uponTermination.item3" />
          </li>
        </ul>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.subscription.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.subscription.body" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.payAsYouGo.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.payAsYouGo.body" />
        </p>

        <p className="terms__h-level-2">
          <FormattedMessage id="terms.earlyTermination.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.earlyTermination.body" />
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.dataProcessingAndPrivacy.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.dataProcessingAndPrivacy.body" />{" "}
          <a
            href="https://openai.com/policies/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://openai.com/policies/terms-of-use
          </a>
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.warrantyAndDisclaimer.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.warrantyAndDisclaimer.body" />
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.limitationOfLiability.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.limitationOfLiability.body1" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.limitationOfLiability.body2" />
        </p>
        <p className="terms__h-level-1">
          <FormattedMessage id="terms.indemnification.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.indemnification.body" />
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.miscellaneous.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.miscellaneous.body" />
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.modificationOfGeneralConditions.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.modificationOfGeneralConditions.body1" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.modificationOfGeneralConditions.body2" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.modificationOfGeneralConditions.body3" />
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.applicableLawAndJurisdiction.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.applicableLawAndJurisdiction.body" />
        </p>

        <p className="terms__h-level-1">
          <FormattedMessage id="terms.additionalInformation.title" />
        </p>
        <p className="terms__body">
          <FormattedMessage id="terms.additionalInformation.body1" />
        </p>
        <p className="terms__h-level-2 mt-3 text-center">
          <FormattedMessage id="terms.additionalInformation.addressLine1" />
        </p>
        <p className="my-1 text-center">
          <FormattedMessage id="terms.additionalInformation.addressLine2" />
          <br />
          <FormattedMessage id="terms.additionalInformation.addressLine3" />
          <br />
          <FormattedMessage id="terms.additionalInformation.addressLine4" />
          <br />
          <FormattedMessage id="terms.additionalInformation.email" />
          <br />
        </p>
        <p className="terms__h-level-2 text-center mt-10 mb-10">
          <FormattedMessage id="terms.additionalInformation.lastUpdated" />
        </p>
      </div>
    </div>
  )
}

export default TermsOfUse
