import React, { useContext, useState } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClose } from "@fortawesome/free-solid-svg-icons"
import CustomLink from "../CustomLink"
import { GA_EVENTS } from "../../../constants/analytics"
import { landingPageBaseUrl, SUPPORTED_LANGUAGES } from "../../../constants"
import { LanguageContext } from "../../../i18n/LanguageProvider"
import LanguageMenu from "./LanguageMenu"
import { FormattedMessage } from "react-intl"
import CustomButton from "../CustomButton"

const MobileNavbar = ({
  showMenu,
  closeMenu,
  menuItems,
  isAuthenticated,
  signUpUrl,
  loginUrl,
  handleLogout,
  showConsole,
}) => {
  const [showChooseLanguages, setShowChooseLanguages] = useState(false)
  const { language } = useContext(LanguageContext)

  const currentLanguageInfo = SUPPORTED_LANGUAGES.find(
    ({ code }) => code === language,
  )

  const menuButtons = isAuthenticated ? (
    <>
      <CustomButton
        trackingEvent={GA_EVENTS.USER_SESSION.NAV_LOGOUT}
        className="btn btn--small secondary btn--nav"
        onClick={handleLogout}
      >
        <FormattedMessage id="navbar.logOut" />
      </CustomButton>
      {showConsole && (
        <CustomLink
          trackingEvent={GA_EVENTS.NAV.CONSOLE}
          to={"/job-match/select-cv"}
          className="btn btn--small secondary btn--nav"
          onClick={handleLogout}
        >
          <FormattedMessage id="navbar.goToConsole" />
        </CustomLink>
      )}
    </>
  ) : (
    <>
      <CustomLink
        trackingEvent={GA_EVENTS.USER_REGISTRATION.NAV_GET_STARTED}
        className="primary btn btn--small btn--nav"
        to={signUpUrl}
      >
        <FormattedMessage id="navbar.getStarted" />
      </CustomLink>
      <CustomLink
        trackingEvent={GA_EVENTS.USER_SESSION.NAV_SIGN_IN}
        className="btn btn--small secondary btn--nav"
        to={loginUrl}
      >
        <FormattedMessage id="navbar.signIn" />
      </CustomLink>
    </>
  )

  return (
    <>
      <div
        onClick={() => closeMenu}
        className={classNames("landing-page-navbar__content-mobile", {
          "landing-page-navbar__content-mobile--visible": showMenu,
        })}
      >
        <div className="landing-page-navbar__mobile-header">
          <CustomLink
            trackingEvent={GA_EVENTS.NAV.MOBILE_LOGO}
            to={landingPageBaseUrl}
            className="mobile-logo"
          >
            <img src="/img/fribl-LOGO-spaced.png" alt="" />
          </CustomLink>

          <div
            className="landing-page-navbar__toggle"
            role={"button"}
            onClick={closeMenu}
          >
            <FontAwesomeIcon icon={faClose} color="white" size={"2x"} />
          </div>
        </div>
        <nav className="landing-page-navbar__mobile">
          {menuItems}
          <CustomLink
            className="landing-page-navbar__select-language"
            onClick={() => setShowChooseLanguages(true)}
          >
            <img
              src={currentLanguageInfo.flag}
              alt={currentLanguageInfo.name}
              className="landing-page-navbar__language-icon"
            />
            <FormattedMessage id="language.name" />
          </CustomLink>
        </nav>

        <div className="landing-page-navbar__divider" />
        <div className="landing-page-navbar__sticky-buttons">{menuButtons}</div>
      </div>
      <LanguageMenu
        showMenu={showChooseLanguages}
        closeMenu={() => setShowChooseLanguages(false)}
        isAuthenticated={isAuthenticated}
      />
    </>
  )
}

export default MobileNavbar
