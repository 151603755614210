import React, { useCallback, useEffect, useState } from "react"
import { getAllTeamUsers } from "../../../services/teamUsers"
import { toast } from "react-toastify"
import classNames from "classnames"
import {
  generateInviteToken,
  getInvites,
} from "../../../services/inviteService"
import Spinner from "../../common/Spinner"
import InviteTeamMemberDialog from "../../common/InviteTeamMemberDialog/InviteTeamMemberDialog"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { Tooltip } from "../../common"
import { FormattedMessage, useIntl } from "react-intl"

const ManageTeamPage = () => {
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  const { formatMessage } = useIntl()

  const fetchUsers = useCallback(async () => {
    setLoading(true)
    try {
      const [teamUsers, invites] = await Promise.all([
        getAllTeamUsers().then((res) => res.teamUsers),
        getInvites().then((res) => res.invites),
      ])

      setUsers(
        [
          ...teamUsers.map((teamUser) => ({
            name: teamUser.user.name,
            email: teamUser.user.email,
            position: teamUser.position,
            isAdmin: teamUser.role.role === "TeamOwner",
            phoneNumber: teamUser.user.phoneNumber,
          })),
          ...invites
            .filter(({ status }) => status === "Pending")
            .map((invite) => ({
              name: invite.name,
              email: invite.email,
              position: invite.position,
              invited: true,
              inviteId: invite.id,
            })),
        ].sort((a, b) => a.name.localeCompare(b.name)),
      )
    } catch (err) {
      toast.error(formatMessage({ id: "toast.errorFetchingTeamMembers" }))
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  const handleExport = () => {
    // Export users as csv. Use all attributes mentioned in file
    const csvRows = []
    const headers = [
      "Name",
      "Email",
      "Position",
      "Phone Number",
      "Admin",
      "Invited",
    ]
    csvRows.push(headers.join(","))
    users.forEach((user) => {
      const values = [
        user.name,
        user.email,
        user.position,
        user.phoneNumber,
        user.isAdmin,
        user.invited,
      ]
      csvRows.push(values.map((val) => `"${val || ""}"`).join(","))
    })

    const csvString = csvRows.join("\n")
    const a = document.createElement("a")
    a.href = "data:attachment/csv," + csvString
    a.target = "_Blank"
    a.download = "Team.csv"

    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
  }

  const handleInvite = () => setOpenInviteModal(true)

  const handleInviteClick = (id) => {
    generateInviteToken(id)
      .then((res) => {
        const token = res.token
        const url = `${window.location.origin}/invitation/complete-invitation/?token=${token}`
        navigator.clipboard.writeText(url)
        toast.success(formatMessage({ id:"toast.linkCopiedToClipboard"}))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="manage-team">
      <div className="manage-team__wrapper">
        <div className="manage-team__header">
          <p className="manage-team__title">
            <FormattedMessage id="common.team" />
          </p>
          <div className="manage-team__buttons">
            {users.length < 5 && !loading && (
              <button className="btn btn--small primary" onClick={handleInvite}>
                {inviteSvg}{" "}
                <span className="pl-6 btn__text">
                  <FormattedMessage id="common.inviteTeamMember" />
                </span>
              </button>
            )}
            <button className="btn btn--small tertiary" onClick={handleExport}>
              {exportSvg}{" "}
              <span className="pl-6 btn__text">
                <FormattedMessage id="common.export" />
              </span>
            </button>
          </div>
        </div>

        {/*  */}
        <div className="manage-team__users">
          {!loading &&
            (users.length === 5 ? (
              <p className="manage-team__count">
                <FormattedMessage id="common.teamSizeLimitReached" />{" "}
              </p>
            ) : (
              <p className="manage-team__count">
                <FormattedMessage id="common.inviteUpToFiveTeamMembers" />
              </p>
            ))}
          {loading && <Spinner className={"pt-3 pt-3"} />}
          {!loading &&
            users.map((user, i) => (
              <div
                className={classNames("user", {
                  "user--invite": user.invite,
                })}
                key={`User - ${i}`}
              >
                {userSvg}

                <div className="user__info">
                  <p className="user__name">
                    {user.name}
                    <span>{user.position}</span>
                  </p>
                  <div className="user__contact">
                    <span>{user.email}</span> <span>{user.phoneNumber}</span>
                  </div>
                </div>

                {user.invited && (
                  <div className="user__buttons">
                    <p
                      className={classNames("user__badge", {
                        "user__badge--invited": user.invited,
                      })}
                    >
                      {formatMessage({ id: "common.invited" }).toUpperCase()}
                    </p>
                    <Tooltip
                      tooltip={formatMessage({
                        id: "common.copyInvitationLink",
                      })}
                    >
                      <button
                        className="user__badge user__badge--invited"
                        onClick={() => handleInviteClick(user.inviteId)}
                      >
                        <FontAwesomeIcon icon={faCopy} size={"1x"} />
                      </button>
                    </Tooltip>
                  </div>
                )}
                {user.isAdmin && (
                  <p
                    className={classNames("user__badge", {
                      "user__badge--admin": user.isAdmin,
                    })}
                  >
                    {formatMessage({ id: "common.admin" }).toUpperCase()}
                  </p>
                )}
              </div>
            ))}
        </div>

        {/*  */}
      </div>
      <InviteTeamMemberDialog
        open={openInviteModal}
        setOpen={setOpenInviteModal}
        onSuccess={fetchUsers}
      />
    </div>
  )
}

const userSvg = (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 19V17C16 15.9391 15.5786 14.9217 14.8284 14.1716C14.0783 13.4214 13.0609 13 12 13H5C3.93913 13 2.92172 13.4214 2.17157 14.1716C1.42143 14.9217 1 15.9391 1 17V19M12.5 5C12.5 7.20914 10.7091 9 8.5 9C6.29086 9 4.5 7.20914 4.5 5C4.5 2.79086 6.29086 1 8.5 1C10.7091 1 12.5 2.79086 12.5 5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const inviteSvg = (
  <svg
    width="25"
    height="20"
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="diff"
  >
    <path
      d="M16.4873 19.0059V17.0059C16.4873 15.945 16.0659 14.9276 15.3157 14.1774C14.5656 13.4273 13.5482 13.0059 12.4873 13.0059H5.4873C4.42644 13.0059 3.40902 13.4273 2.65888 14.1774C1.90873 14.9276 1.4873 15.945 1.4873 17.0059V19.0059M20.4873 6.00586V12.0059M23.4873 9.00586H17.4873M12.9873 5.00586C12.9873 7.215 11.1964 9.00586 8.9873 9.00586C6.77817 9.00586 4.9873 7.215 4.9873 5.00586C4.9873 2.79672 6.77817 1.00586 8.9873 1.00586C11.1964 1.00586 12.9873 2.79672 12.9873 5.00586Z"
      stroke="#002060"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const exportSvg = (
  <svg
    width="25"
    height="20"
    viewBox="0 0 25 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="diff"
  >
    <path
      d="M8.99627 15.0058L12.9963 19.0058M12.9963 19.0058L16.9963 15.0058M12.9963 19.0058V10.0058M21.8763 16.0958C22.7457 15.4844 23.3976 14.6119 23.7376 13.6049C24.0775 12.5979 24.0877 11.5088 23.7667 10.4956C23.4457 9.48243 22.8101 8.59788 21.9523 7.97035C21.0945 7.34283 20.0591 7.00496 18.9963 7.00579H17.7363C17.4355 5.83367 16.8728 4.74504 16.0904 3.82187C15.308 2.89871 14.3264 2.16505 13.2195 1.67615C12.1125 1.18724 10.9091 0.95582 9.6998 0.999309C8.49048 1.0428 7.30678 1.36006 6.23782 1.92722C5.16886 2.49437 4.2425 3.29664 3.52846 4.27362C2.81442 5.2506 2.33132 6.37684 2.11553 7.56754C1.89974 8.75825 1.95688 9.98239 2.28265 11.1478C2.60842 12.3132 3.19433 13.3896 3.99627 14.2958"
      stroke="#002060"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ManageTeamPage
