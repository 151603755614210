import ReactGA from "react-ga4"
import { GA_EVENTS } from "../../../constants/analytics"

const CustomButton = ({
  children,
  trackingEvent = GA_EVENTS.GENERAL.BUTTON_PRESSED,
  onClick,
  ...props
}) => {
  const onClickHandler = (e) => {
    ReactGA.event(trackingEvent)
    onClick?.(e)
  }

  return (
    <button
      onClick={onClickHandler}
      {...props}
    >
      {children}
    </button>
  )
}

export default CustomButton;