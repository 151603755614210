import api from "../api"
import { getToken } from "../util/util"

export const getLanguage = async () =>
  api
    .get("/teams/language", {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const updateLanguage = async (body) =>
  api
    .patch("/teams/language", body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)

export const updateDetails = async (body) =>
  api
    .patch("/teams/details", body, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
    .then((res) => res.data)
