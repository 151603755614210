import React from "react"
import { CompanyForm, DetailsForm, DefaultLanguageForm } from "./components"
import { FormattedMessage } from "react-intl"

const ManageTeamPage = () => {
  return (
    <div className="settings">
      <div className="settings__wrapper">
        <p className="settings__title">
          <FormattedMessage id="common.yourDetails" />
        </p>
        <DetailsForm />
      </div>
      <div className="settings__wrapper">
        <p className="settings__title">
          <FormattedMessage id="common.company" />
        </p>
        <CompanyForm />
      </div>
      <div className="settings__wrapper">
        <p className="settings__title">
          <FormattedMessage id="common.language" />
        </p>
        <DefaultLanguageForm />
      </div>
    </div>
  )
}

export default ManageTeamPage
