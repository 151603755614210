import React from "react"
import Skill from "../Skill"
import { FormattedMessage, useIntl } from "react-intl"

const SkillList = ({ scoreDict }) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <div className="skills-card flex-col">
        <h3 className="skill">
          <FormattedMessage id="common.hardSkillsAssessment" />
        </h3>
        {Object.entries(scoreDict.hard_skills).map(([name, details]) => (
          <Skill
            key={name}
            score={details.score}
            skill={name}
            context={details.explanation}
            tag={formatMessage({ id: "common.skill" })}
          />
        ))}
      </div>
      <div className="skills-card flex-col">
        <h3 className="skill">
          <FormattedMessage id="common.softSkillsAssessment" />
        </h3>
        {Object.entries(scoreDict.soft_skills).map(([name, details]) => (
          <Skill
          key={name}
          score={details.score}
          skill={name}
          context={details.explanation}
            tag={formatMessage({ id: "common.skill" })}
          />
        ))}
      </div>
    </>
  )
}

export default SkillList
