import React from "react"
import { useCookies } from "react-cookie"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

import { logoutAction } from "../../../../redux/slices/authSlice"

import CustomLink from "../../CustomLink"
import CustomButton from "../../CustomButton"
import { GA_EVENTS } from "../../../../constants/analytics"
import { useIntl } from "react-intl"

const GeneralSettingsSidebar = ({ back }) => {
  // eslint-disable-next-line
  const [_, __, removeCookie] = useCookies(["token"])
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const auth = useSelector((state) => state.auth)

  const atsEnabled = auth?.atsEnabled && auth.role?.role === "TeamOwner"

  const isTrial = auth.onboardingStage === "Trialing"

  const items = [
    {
      name: backSvg,
      onClick: back,
      trackingEvent: GA_EVENTS.SIDE_BAR.BACK
    },
    !isTrial && {
      name: formatMessage({ id: "sidebar.teamMembers" }),
      path: "/manage-team",
      trackingEvent: GA_EVENTS.SIDE_BAR.TEAM_MEMBERS,
    },
    {
      name: formatMessage({ id: "sidebar.settings" }),
      path: "/settings",
      trackingEvent: GA_EVENTS.SIDE_BAR.SETTINGS,
    },
    atsEnabled && {
      name: formatMessage({ id: "sidebar.connectToATS" }),
      path: "/ats-integration",
      trackingEvent: GA_EVENTS.SIDE_BAR.CONNECT_TO_ATS,
    },
    !isTrial &&
      auth.role?.role === "TeamOwner" && {
        name: formatMessage({ id: "sidebar.planAndBilling" }),
        path: "/plan-and-billing",
        trackingEvent: GA_EVENTS.SIDE_BAR.PLAN_AND_BILLING,
      },
    {
      name: formatMessage({ id: "sidebar.logout" }),
      onClick: () => {
        removeCookie("token", { path: "/" })
        dispatch(logoutAction())
      },
      trackingEvent: GA_EVENTS.SIDE_BAR.LOGOUT
    },
  ]

  return (
    <div className="general-settings">
      {items
        ?.filter((val) => val)
        .map((item) =>
          item.onClick ? (
            <CustomButton
              key={`sidebar-${item.name}`}
              className="general-settings__item"
              onClick={item.onClick}
              trackingEvent={item.trackingEvent ?? GA_EVENTS.GENERAL.SIDE_BAR_CLICKED}
            >
              {item.name}
            </CustomButton>
          ) : (
            <CustomLink
              key={`sidebar-${item.name}`}
              to={item.path}
              className="general-settings__item"
              trackingEvent={item.trackingEvent ?? GA_EVENTS.GENERAL.SIDE_BAR_CLICKED}
            >
              {item.name}
            </CustomLink>
          ),
        )}
    </div>
  )
}

const backSvg = (
  <svg
    width="58"
    height="54"
    viewBox="0 0 58 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="58" height="54" rx="27" fill="#5EC58F" />
    <path
      d="M36 27H22M22 27L29 34M22 27L29 20"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default GeneralSettingsSidebar
