import React from "react"
import ReactDOM from "react-dom"

const Modal = ({
  children,
  open,
  setOpen,
  closeButton,
  closeOnOutsideClick = false,
  className,
}) => {
  return ReactDOM.createPortal(
    <div
      className={`modal ${className} ${open ? "open" : ""}`}
      onClick={() => closeOnOutsideClick && setOpen(false)}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {closeButton && (
          <span className="close" onClick={() => setOpen?.(false)}>
            &times;
          </span>
        )}
        {children}
      </div>
    </div>,
    document.getElementById("modal"),
  )
}

export default Modal
