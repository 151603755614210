import React from "react"
import { SkillList } from "../../../common"
import { FormattedMessage, useIntl } from "react-intl"

const Profile = ({ cv, jobData }) => {
  const { skill_descriptions } = cv
  const { score_dict } = jobData
  const { formatMessage } = useIntl()

  return (
    <div className="profile">
      <div className="profile__highlights-wrapper">
        <div className="profile__highlights-item">
          <p className="profile__highlights-title">
            <FormattedMessage id="common.highlights" />
          </p>
        </div>

        {skill_descriptions.map((value) => (
          <div
            className="profile__highlights-item"
            key={`Highlight - ${value}`}
          >
            <p className="profile__highlights-info">{value}</p>
          </div>
        ))}
      </div>

      <div>
        <div className="skills">
          <SkillList scoreDict={score_dict || []} />
        </div>

        <div className="work-experience flex-col">
          <div className="skill">
            <h3>
              <FormattedMessage id="common.experience" />
            </h3>
          </div>

          <div className="roles">
            {cv.experience?.map((experience, index) => (
              <div
                className="role flex-col skill"
                key={`experience - ${index}`}
              >
                <h4 className="strong">{experience.title}</h4>
                <h4>{experience.activity_sector}</h4>
                {experience.description && (
                  <p>{experience.description}</p>
                )}
                <div className="dates">
                  {`${experience.start_date} ${formatMessage({
                    id: "common.to",
                  })} ${experience.end_date}`}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="education flex-col">
          <div className="skill">
            <h3>
              <FormattedMessage id="common.education" />
            </h3>
          </div>

          <div className="schools">
            {cv.education?.map((education, index) => (
              <div key={`education-${index}`} className="role flex-col skill">
                <h4 className="strong">{education.degree}</h4>
                <h4>{education.institution_name}</h4>
                <div className="dates">
                  {`${
                    education.start_date && education.end_date
                      ? `${education.start_date}  ${formatMessage({
                          id: "common.to",
                        })} ${education.end_date}`
                      : ""
                  }`}
                </div>
              </div>
            ))}
          </div>
        </div>

        {cv.certifications?.[0]?.name && (
          <div className="education flex-col">
            <div className="skill">
              <h3>
                <FormattedMessage id="common.certifications" />
              </h3>
            </div>
            <div className="schools">
              {cv.certifications?.map((certification, index) => (
                <p className="role flex-col skill" key={`Cert - ${index}`}>
                  {certification.name}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Profile
