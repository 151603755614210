import React from "react"
import { pricingInfo } from "../../../../constants"
import UpgradeDowngradeBillingItem from "./UpgradeDowngradeBillingItem"
import { FormattedMessage } from "react-intl"

const UpgradeDowngradeSubscriptionBillingItems = ({
  products,
  handleSubmit,
  loading,
  subscription,
}) => {
  const handleProductSelect = (product, payload) => {
    handleSubmit(product, payload)
  }

  return (
    <div className="billing-items">
      <div className="billing-items__wrapper">
        <p className="h3 billing-items__title">
          <FormattedMessage id="common.changeYourPlan" />
        </p>
        <div className="billing-items__grid">
          {products?.map((item, i) => {
            const itemInfo = pricingInfo[item.productName]
            if (!itemInfo) {
              return null
            }

            return (
              <UpgradeDowngradeBillingItem
                item={item}
                itemInfo={itemInfo}
                handleProductSelect={handleProductSelect}
                key={`Billing Item - ${i}`}
                loading={loading}
                currentSubscription={subscription}
              />
            )
          })}
        </div>
        <p className="billing-items__info">
          <span>
            <FormattedMessage id="message.ifYouChange" />,
          </span>{" "}
          <FormattedMessage id="message.chooseStartOrWait" />
        </p>
      </div>
    </div>
  )
}

export default UpgradeDowngradeSubscriptionBillingItems
