import React from "react"
import UploadCvActions from "./UploadCvActions"
import { useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"

const NoCv = ({ onUploadClick }) => {
  const auth = useSelector((state) => state.auth)

  const isTrial = auth.onboardingStage === "Trialing"

  return (
    <div className="no-cv">
      <p className="no-cv__title">
        <FormattedMessage id="common.letsGetStarted" />
      </p>

      <div className="no-cv__steps">
        <div className="no-cv__step-item">
          <div className="no-cv__step-image-wrapper">{uploadCvSvg}</div>

          <span className="no-cv__step-title">
            <FormattedMessage id="common.uploadCVs" />
          </span>
        </div>
        <div className="no-cv__step-item">
          <div className="no-cv__step-image-wrapper">{jobDescriptionSvg}</div>

          <span className="no-cv__step-title">
            <FormattedMessage id="common.pasteAJobDescription" />
          </span>
        </div>
        <div className="no-cv__step-item">
          <div className="no-cv__step-image-wrapper">{compareMatchesSvg}</div>

          <span className="no-cv__step-title">
            <FormattedMessage id="common.compareTopMatches" />
          </span>
        </div>
      </div>

      <div className="no-cv__actions">
        {isTrial ? (
          <p className="no-cv__action-text">
            <FormattedMessage id="message.startUsingFriblFree" />,{" "}
            <span>
              <FormattedMessage id="message.addSomeCandidateCVsMax" />
            </span>
          </p>
        ) : (
          <p className="no-cv__action-text">
            <FormattedMessage id="message.first" />,{" "}
            <span>
              <FormattedMessage id="message.addSomeCandidateCVs" />
            </span>
          </p>
        )}

        <UploadCvActions onUploadClick={onUploadClick} />

        <p className="no-cv__file">
          <FormattedMessage id="common.pdfOrDoc" />
        </p>
      </div>
    </div>
  )
}

const uploadCvSvg = (
  <svg
    width="157"
    height="194"
    viewBox="0 0 157 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55.7774 193.108L4.25728 181.331H101.223L152.743 193.108H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 186.827L4.25728 175.051H101.223L152.743 186.827H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 180.546L4.25728 168.77H101.223L152.743 180.546H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 174.266L4.25728 162.49H101.223L152.743 174.266H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 167.985L4.25728 156.209H101.223L152.743 167.985H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 161.705L4.25728 149.929H101.223L152.743 161.705H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 155.424L4.25728 143.648H101.223L152.743 155.424H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 149.144L4.25728 137.368H101.223L152.743 149.144H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 142.863L4.25728 131.087H101.223L152.743 142.863H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 136.583L4.25728 124.807H101.223L152.743 136.583H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 130.302L4.25728 118.526H101.223L152.743 130.302H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 124.022L4.25728 112.246H101.223L152.743 124.022H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 117.741L4.25728 105.965H101.223L152.743 117.741H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 111.461L4.25728 99.6845H101.223L152.743 111.461H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 105.18L4.25728 93.4038H101.223L152.743 105.18H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 98.8995L4.25728 87.1235H101.223L152.743 98.8995H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M55.7774 92.6187L4.25728 80.8427H101.223L152.743 92.6187H55.7774Z"
      fill="#1E4CA9"
      stroke="#5EC58F"
      strokeWidth="0.785068"
    />
    <path
      d="M116.24 70.9648C120.625 68.5525 124.089 64.7355 126.085 60.1161C128.082 55.4967 128.497 50.3381 127.265 45.4543C126.033 40.5705 123.224 36.2397 119.282 33.1455C115.34 30.0512 110.489 28.3698 105.495 28.3664H99.8299C98.4691 23.0552 95.9327 18.1244 92.4115 13.9447C88.8903 9.76494 84.4758 6.44508 79.5 4.23467C74.5242 2.02426 69.1165 0.98083 63.6836 1.18281C58.2506 1.3848 52.9337 2.82694 48.1326 5.40083C43.3315 7.97472 39.1711 11.6134 35.9642 16.0432C32.7574 20.4731 30.5875 25.5789 29.6176 30.9767C28.6478 36.3746 28.9034 41.9241 30.365 47.208C31.8267 52.4918 34.4564 57.3726 38.0565 61.4833M78.5218 41.9762V124.021M78.5218 41.9762L96.5053 60.1224M78.5218 41.9762L60.5383 60.1224"
      stroke="#18A8D5"
      strokeWidth="1.57014"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const jobDescriptionSvg = (
  <svg
    width="102"
    height="193"
    viewBox="0 0 102 193"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M88.9616 71.3202C93.3466 68.908 96.8106 65.091 98.807 60.4716C100.803 55.8522 101.218 50.6935 99.9864 45.8097C98.7545 40.9259 95.9459 36.5952 92.0038 33.5009C88.0617 30.4067 83.2107 28.7252 78.2164 28.7219H72.5516C71.1908 23.4107 68.6544 18.4799 65.1332 14.3001C61.6119 10.1204 57.1975 6.80055 52.2217 4.59014C47.2459 2.37973 41.8382 1.3363 36.4052 1.53828C30.9723 1.74027 25.6554 3.18241 20.8543 5.7563C16.0531 8.33019 11.8928 11.9688 8.6859 16.3987C5.47903 20.8286 3.30913 25.9343 2.33932 31.3322C1.36952 36.7301 1.62504 42.2796 3.08669 47.5634C4.54834 52.8473 7.17807 57.7281 10.7782 61.8388M51.2435 42.3317V124.377M51.2435 42.3317L69.227 60.4779M51.2435 42.3317L33.26 60.4779"
      stroke="#18A8D5"
      strokeWidth="1.57014"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.5947 91.1685H84.782C87.749 91.1685 90.5945 92.343 92.6925 94.4336C94.7905 96.5243 95.9692 99.3598 95.9692 102.316V180.352C95.9692 183.309 94.7905 186.144 92.6925 188.235C90.5945 190.326 87.749 191.5 84.782 191.5H17.6587C14.6916 191.5 11.8461 190.326 9.74809 188.235C7.65008 186.144 6.47144 183.309 6.47144 180.352V102.316C6.47144 99.3598 7.65008 96.5243 9.74809 94.4336C11.8461 92.343 14.6916 91.1685 17.6587 91.1685H28.8459M34.4395 80.0205H68.0011C71.0904 80.0205 73.5947 82.5161 73.5947 85.5945V96.7425C73.5947 99.8209 71.0904 102.316 68.0011 102.316H34.4395C31.3502 102.316 28.8459 99.8209 28.8459 96.7425V85.5945C28.8459 82.5161 31.3502 80.0205 34.4395 80.0205Z"
      stroke="#5EC58F"
      strokeWidth="1.57014"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9578 120.059L25.313 122.414L33.1637 114.563M32.3787 120.844V126.339C32.3787 126.756 32.2132 127.155 31.9188 127.45C31.6243 127.744 31.2249 127.91 30.8085 127.91H19.8176C19.4011 127.91 19.0018 127.744 18.7073 127.45C18.4129 127.155 18.2474 126.756 18.2474 126.339V115.348C18.2474 114.932 18.4129 114.533 18.7073 114.238C19.0018 113.944 19.4011 113.778 19.8176 113.778H28.4533"
      stroke="#5EC58F"
      strokeWidth="1.57014"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9578 143.611L25.313 145.966L33.1637 138.116M32.3787 144.396V149.892C32.3787 150.308 32.2132 150.707 31.9188 151.002C31.6243 151.296 31.2249 151.462 30.8085 151.462H19.8176C19.4011 151.462 19.0018 151.296 18.7073 151.002C18.4129 150.707 18.2474 150.308 18.2474 149.892V138.901C18.2474 138.484 18.4129 138.085 18.7073 137.79C19.0018 137.496 19.4011 137.331 19.8176 137.331H28.4533"
      stroke="#5EC58F"
      strokeWidth="1.57014"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9578 167.163L25.313 169.518L33.1637 161.667M32.3787 167.948V173.443C32.3787 173.86 32.2132 174.259 31.9188 174.554C31.6243 174.848 31.2249 175.014 30.8085 175.014H19.8176C19.4011 175.014 19.0018 174.848 18.7073 174.554C18.4129 174.259 18.2474 173.86 18.2474 173.443V162.452C18.2474 162.036 18.4129 161.637 18.7073 161.342C19.0018 161.048 19.4011 160.882 19.8176 160.882H28.4533"
      stroke="#5EC58F"
      strokeWidth="1.57014"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const compareMatchesSvg = (
  <svg
    width="123"
    height="161"
    viewBox="0 0 123 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.2802 97.4796V93.2053C33.2802 90.9381 32.3779 88.7637 30.7718 87.1606C29.1657 85.5574 26.9873 84.6568 24.7159 84.6568H9.7282C7.45678 84.6568 5.2784 85.5574 3.67227 87.1606C2.06613 88.7637 1.16382 90.9381 1.16382 93.2053V97.4796M37.5624 69.6969L48.2679 80.3825M48.2679 69.6969L37.5624 80.3825M25.7864 67.5597C25.7864 72.281 21.952 76.1083 17.222 76.1083C12.492 76.1083 8.65765 72.281 8.65765 67.5597C8.65765 62.8385 12.492 59.0112 17.222 59.0112C21.952 59.0112 25.7864 62.8385 25.7864 67.5597Z"
      stroke="#5EC58F"
      strokeWidth="1.57014"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M105.756 97.4796V93.2053C105.756 90.9381 104.869 88.7637 103.29 87.1606C101.71 85.5574 99.5681 84.6568 97.3346 84.6568H82.5967C80.3631 84.6568 78.2211 85.5574 76.6417 87.1606C75.0623 88.7637 74.175 90.9381 74.175 93.2053V97.4796M109.967 69.6969L120.494 80.3825M120.494 69.6969L109.967 80.3825M98.3873 67.5597C98.3873 72.281 94.6168 76.1083 89.9656 76.1083C85.3145 76.1083 81.544 72.281 81.544 67.5597C81.544 62.8385 85.3145 59.0112 89.9656 59.0112C94.6168 59.0112 98.3873 62.8385 98.3873 67.5597Z"
      stroke="#5EC58F"
      strokeWidth="1.57014"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.2802 159.5V155.225C33.2802 152.958 32.3779 150.784 30.7718 149.181C29.1657 147.577 26.9873 146.677 24.7159 146.677H9.7282C7.45678 146.677 5.2784 147.577 3.67227 149.181C2.06613 150.784 1.16382 152.958 1.16382 155.225V159.5M37.5624 131.717L48.2679 142.403M48.2679 131.717L37.5624 142.403M25.7864 129.58C25.7864 134.301 21.952 138.128 17.222 138.128C12.492 138.128 8.65765 134.301 8.65765 129.58C8.65765 124.859 12.492 121.031 17.222 121.031C21.952 121.031 25.7864 124.859 25.7864 129.58Z"
      stroke="#5EC58F"
      strokeWidth="1.57014"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M105.756 159.5V155.225C105.756 152.958 104.869 150.784 103.29 149.181C101.71 147.577 99.5681 146.677 97.3346 146.677H82.5967C80.3631 146.677 78.2211 147.577 76.6417 149.181C75.0623 150.784 74.175 152.958 74.175 155.225V159.5M109.967 131.717L120.494 142.403M120.494 131.717L109.967 142.403M98.3873 129.58C98.3873 134.301 94.6168 138.128 89.9656 138.128C85.3145 138.128 81.544 134.301 81.544 129.58C81.544 124.859 85.3145 121.031 89.9656 121.031C94.6168 121.031 98.3873 124.859 98.3873 129.58Z"
      stroke="#5EC58F"
      strokeWidth="1.57014"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.2804 40.1695V35.8952C33.2804 33.628 32.378 31.4537 30.7719 29.8505C29.1658 28.2474 26.9874 27.3467 24.716 27.3467H9.72832C7.4569 27.3467 5.27852 28.2474 3.67239 29.8505C2.06626 31.4537 1.16394 33.628 1.16394 35.8952V40.1695M35.4214 18.7982L39.7036 23.0725L48.268 14.5239M25.7865 10.2497C25.7865 14.9709 21.9521 18.7982 17.2221 18.7982C12.4922 18.7982 8.65777 14.9709 8.65777 10.2497C8.65777 5.52847 12.4922 1.70117 17.2221 1.70117C21.9521 1.70117 25.7865 5.52847 25.7865 10.2497Z"
      stroke="#18A8D5"
      strokeWidth="1.57014"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.291 40.1695V35.8952C106.291 33.628 105.389 31.4537 103.783 29.8505C102.177 28.2474 99.9985 27.3467 97.7271 27.3467H82.7394C80.468 27.3467 78.2896 28.2474 76.6835 29.8505C75.0774 31.4537 74.175 33.628 74.175 35.8952V40.1695M110.574 12.3868L121.279 23.0725M121.279 12.3868L110.574 23.0725M98.7976 10.2497C98.7976 14.9709 94.9632 18.7982 90.2333 18.7982C85.5033 18.7982 81.6689 14.9709 81.6689 10.2497C81.6689 5.52847 85.5033 1.70117 90.2333 1.70117C94.9632 1.70117 98.7976 5.52847 98.7976 10.2497Z"
      stroke="#5EC58F"
      strokeWidth="1.57014"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default NoCv
