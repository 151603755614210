import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import ReactGA from "react-ga4"
import { getLocalStorage, setLocalStorage } from "../../../util/util"
import { FormattedMessage } from "react-intl"

ReactGA.initialize(process.env.REACT_APP_GA4)

const ReactGa = () => {
  const [init, setInit] = useState(false)
  const [cookieConsent, setCookieConsent] = useState(null)

  const { pathname } = useLocation()

  useEffect(() => {
    const storedCookieConsent = getLocalStorage("cookie_consent", null)
    setInit(true)
    setCookieConsent(storedCookieConsent)
  }, [setCookieConsent])

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0)

    // Send GA pageview
    if (process.env.NODE_ENV === "production" && cookieConsent) {
      ReactGA.send({ hitType: "pageview", page: pathname })
    }
  }, [pathname, cookieConsent])

  const handleAccept = () => {
    setCookieConsent(true)
    setLocalStorage("cookie_consent", true)
  }

  const handleReject = () => {
    setCookieConsent(false)
    setLocalStorage("cookie_consent", false)
  }

  return (
    <>
      {cookieConsent === null && init && (
        <div className="react-ga">
          <div className="react-ga__content">
            <p className="h3"><FormattedMessage id="ga.cookies" /></p>
            <p>
              <FormattedMessage id="ga.weNeedCookies" />{" "}
              <Link to={`/terms-of-use`}>
                <FormattedMessage id="ga.protectYourPrivacy" />
              </Link>
              .
            </p>
            <div className="react-ga__actions">
              <button className="btn primary btn--small" onClick={handleAccept}>
                <FormattedMessage id="common.accept" />
              </button>
              <button
                className="btn secondary btn--small"
                onClick={handleReject}
              >
                <FormattedMessage id="common.reject" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ReactGa
