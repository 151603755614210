import React from "react"
import { Spinner } from "./../../../common"
import InvoiceItem from "./InvoiceItem"
import { FormattedMessage } from "react-intl"

const Invoices = ({ invoices }) => {
  return (
    <div className="invoice">
      <p className="h3 invoice__title">
        <FormattedMessage id="common.invoices" />
      </p>

      {!invoices && <Spinner />}

      {invoices && invoices.length === 0 && (
        <p className="invoice__no-invoices">
          <FormattedMessage id="common.noInvoices" />
        </p>
      )}

      {invoices && invoices.length > 0 && (
        <div className="invoice__list">
          {invoices.map((invoice, i) => (
            <InvoiceItem key={`Invoice - ${i}`} invoice={invoice} />
          ))}
        </div>
      )}
    </div>
  )
}

export default Invoices
