import classNames from "classnames"
import React, { useState } from "react"
import { useIntl } from "react-intl"

const successSvg = (
  <svg width="25" height="26" viewBox="0 0 25 26" fill="none">
    <rect y="0.739258" width="25" height="25" rx="12.5" fill="#5EC58F" />
    <path
      d="M12.5788 18.1119L16.7598 10.8702L15.1828 9.95976L11.8104 15.8008L9.0507 14.2075L8.24205 15.6081L12.5788 18.1119Z"
      fill="white"
    />
  </svg>
)

const errorSvg = (
  <svg width="25" height="26" viewBox="0 0 25 26" fill="none">
    <rect y="0.739258" width="25" height="25" rx="12.5" fill="#FF6633" />
    <path
      d="M11.396 15.4433V13.6073C12.028 13.5833 12.5 13.4793 12.812 13.2953C13.132 13.1033 13.292 12.7833 13.292 12.3353V12.1673C13.292 11.8633 13.2 11.6313 13.016 11.4713C12.84 11.3113 12.608 11.2313 12.32 11.2313C12.008 11.2313 11.748 11.3273 11.54 11.5193C11.34 11.7113 11.204 11.9553 11.132 12.2513L9.668 11.6393C9.74 11.3993 9.844 11.1673 9.98 10.9433C10.116 10.7113 10.292 10.5073 10.508 10.3313C10.732 10.1473 10.996 9.99926 11.3 9.88726C11.612 9.77526 11.972 9.71926 12.38 9.71926C12.796 9.71926 13.176 9.77926 13.52 9.89926C13.864 10.0193 14.16 10.1873 14.408 10.4033C14.656 10.6193 14.844 10.8793 14.972 11.1833C15.108 11.4873 15.176 11.8233 15.176 12.1913C15.176 12.5273 15.12 12.8313 15.008 13.1033C14.896 13.3753 14.744 13.6153 14.552 13.8233C14.36 14.0233 14.136 14.1873 13.88 14.3153C13.624 14.4433 13.356 14.5313 13.076 14.5793V15.4433H11.396ZM12.26 18.3833C11.876 18.3833 11.6 18.2953 11.432 18.1193C11.272 17.9433 11.192 17.7273 11.192 17.4713V17.1833C11.192 16.9273 11.272 16.7113 11.432 16.5353C11.6 16.3593 11.876 16.2713 12.26 16.2713C12.644 16.2713 12.916 16.3593 13.076 16.5353C13.244 16.7113 13.328 16.9273 13.328 17.1833V17.4713C13.328 17.7273 13.244 17.9433 13.076 18.1193C12.916 18.2953 12.644 18.3833 12.26 18.3833Z"
      fill="#F4F4F4"
    />
  </svg>
)

const SignupInput = ({
  name,
  placeholder,
  helperText,
  register = {},
  error,
  touched,
  value,
  type: tp = "text",
  showEditButton = false,
  editable,
  setEditable,
  disabled = false,
  label,
  editButtonText = null,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [focused, setFocused] = useState(false)
  const { formatMessage } = useIntl()
  editButtonText =
    editButtonText ?? formatMessage({id:"common.edit"}).toLocaleLowerCase()


  let type = tp

  if (type === "password") {
    if (showPassword) {
      type = "text"
    }
  }

  const showSuccessSvg =
    touched && !error && value && (showEditButton ? editable : true)

  const showErrorSvg = touched && error && (showEditButton ? editable : true)

  const showLabel = label && (showEditButton ? editable : true)

  if (type === "textarea") {
    return (
      <div className="signup-input">
        <textarea
          className={classNames({ error: showErrorSvg })}
          placeholder={placeholder}
          id={name}
          name={name}
          {...register}
          onFocus={() => setFocused(true)}
          onBlur={(e) => {
            register.onBlur(e)
            setFocused(false)
          }}
          type={type}
          {...props}
        ></textarea>
        {showLabel && <label htmlFor={name}>{label}</label>}
        {showSuccessSvg && successSvg}
        {showErrorSvg && errorSvg}
        {focused && helperText && !error && !value && (
          <p className="signup-input__helper-text">{helperText}</p>
        )}
        {error && showErrorSvg && (
          <p className="signup-input__error-message">{error}</p>
        )}
      </div>
    )
  }

  return (
    <div className="signup-input">
      <input
        className={classNames({ error: showErrorSvg })}
        placeholder={placeholder}
        name={name}
        id={name}
        type={type}
        disabled={(showEditButton && !editable) || disabled}
        {...register}
        onFocus={() => setFocused(true)}
        onBlur={(e) => {
          register.onBlur(e)
          setFocused(false)
        }}
        {...props}
      />
      {showLabel && <label htmlFor={name}>{label}</label>}
      {showSuccessSvg && successSvg}
      {showErrorSvg && errorSvg}
      {focused && helperText && !error && !showSuccessSvg && (
        <p className="signup-input__helper-text">{helperText}</p>
      )}
      {error && showErrorSvg && (
        <p className="signup-input__error-message">{error}</p>
      )}
      {tp === "password" && (
        <p
          className="signup-input__password-toggle"
          role={"button"}
          onClick={() => setShowPassword(!showPassword)}
          tabIndex={0}
          aria-label={formatMessage({ id: "login.showPassword" })}
        >
          {showPassword
            ? formatMessage({ id: "common.hide" })
            : formatMessage({ id: "common.show" })}
        </p>
      )}
      {showEditButton && !editable && (
        <p
          className="signup-input__password-toggle"
          role={"button"}
          onClick={() => setEditable(true)}
          tabIndex={0}
          aria-label={"edit"}
        >
          {editButtonText}
        </p>
      )}
    </div>
  )
}

export default SignupInput
