import React, { useState } from "react"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { SignupInput } from "../../../common"
import { changeTeamDetailsAction } from "../../../../redux/slices/authSlice"
import classNames from "classnames"
import { updateDetails } from "../../../../services/team"
import { FormattedMessage, useIntl } from "react-intl"

const DetailsForm = () => {
  const [loading, setLoading] = useState(false)
  const auth = useSelector((state) => state.auth)
  const [editable, setEditable] = useState({
    name: false,
    registrationNumber: false,
  })

  const isReadOnly = auth.role.role === "TeamMember"

  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: auth.team.name,
      registrationNumber: auth.team.registrationNumber,
    },
  })

  watch(["name", "registrationNumber"])

  const values = getValues()

  const onSubmit = async () => {
    if (loading) return

    //
    try {
      const toUpdate = {}

      if (editable.name) toUpdate.name = values.name
      if (editable.registrationNumber)
        toUpdate.registrationNumber = values.registrationNumber

      if (Object.keys(toUpdate).length === 0) return

      setLoading(true)
      await updateDetails(toUpdate)
      dispatch(changeTeamDetailsAction(values))
      toast.success(formatMessage({ id: "toast.companyDetailsUpdated" }))

      setEditable({
        name: false,
        phoneNumber: false,
        email: false,
        position: false,
      })
    } catch {
      toast.error(formatMessage({ id: "toast.somethingWentWrongTryAgain" }))
    }
    setLoading(false)
    //
  }

  const handleCancel = () => {
    setEditable({
      name: false,
      phoneNumber: false,
      email: false,
      position: false,
    })

    // Set previous defaults
    setValue("name", auth.user.name)
    setValue("phoneNumber", auth.user.phoneNumber)
    setValue("email", auth.user.email)
    setValue("position", auth.teamUser.position)
  }

  return (
    <form className="details-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="details-form__inputs">
        <SignupInput
          {...{
            name: "name",
            placeholder: formatMessage({ id: "common.whatsYourName" }),
            register: register("name", {
              required: formatMessage({ id: "common.nameRequired" }),
            }),
            error: errors.name?.message,
            touched: touchedFields.name,
            value: values.name,
            showEditButton: !isReadOnly,
            editable: editable.name,
            disabled: isReadOnly,
            setEditable: () => setEditable({ ...editable, name: true }),
          }}
        />
        <SignupInput
          {...{
            name: "registrationNumber",
            placeholder: formatMessage({
              id: "common.registrationNumber",
            }),
            register: register("registrationNumber", {
              required: formatMessage({
                id: "common.registrationNumberRequired",
              }),
            }),
            error: errors.registrationNumber?.message,
            touched: touchedFields.registrationNumber,
            value: values.registrationNumber,
            showEditButton: !isReadOnly,
            editable: editable.registrationNumber,
            disabled: isReadOnly,
            setEditable: () =>
              setEditable({ ...editable, registrationNumber: true }),
          }}
        />
      </div>
      {!isReadOnly && (
        <div className="details-form__buttons">
          <button
            className={classNames("btn btn--small primary", {
              disabled: loading,
            })}
          >
            <FormattedMessage id="common.saveChanges" />
          </button>
          <button
            className="btn btn--small tertiary"
            type="button"
            onClick={handleCancel}
          >
            <FormattedMessage id="common.cancel" />
          </button>
        </div>
      )}
    </form>
  )
}

export default DetailsForm
