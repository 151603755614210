import React, { useContext } from "react"
import classNames from "classnames"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { SUPPORTED_LANGUAGES } from "../../../constants"
import CustomButton from "../CustomButton"
import { LanguageContext } from "../../../i18n/LanguageProvider"
import { updateLanguage } from "../../../services/team"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const LanguageMenu = ({ closeMenu, showMenu, isAuthenticated }) => {
  const { language, changeLanguage } = useContext(LanguageContext)

  return (
    <div
      className={classNames("landing-page-navbar__content-mobile", {
        "landing-page-navbar__content-mobile--visible": showMenu,
      })}
    >
      <div className="landing-page-navbar__mobile-header">
        <div
          className="landing-page-navbar__toggle"
          role={"button"}
          onClick={closeMenu}
        >
          <FontAwesomeIcon icon={faArrowLeft} color="white" size={"2x"} />
        </div>
      </div>
      {SUPPORTED_LANGUAGES.map(({ code, name }) => (
        <CustomButton
          key={code}
          className={classNames("btn btn--small text btn--nav", {
            active: code === language,
          })}
          onClick={async () => {
            closeMenu()
            if (code === language) return
            if (isAuthenticated) {
              updateLanguage({ language: code })
            }
            changeLanguage(code)
          }}
        >
          {name}
        </CustomButton>
      ))}
    </div>
  )
}

export default LanguageMenu
