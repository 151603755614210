import ReactGA from "react-ga4"
import { Link } from "react-router-dom"
import { GA_EVENTS } from "../../../constants/analytics"


const CustomLink = ({
  children,
  trackingEvent = GA_EVENTS.GENERAL.LINK_CLICKED,
  onClick,
  ...props
}) => {
  const onClickHandler = (e) => {
    ReactGA.event(trackingEvent)
    onClick?.(e)
  }

  return (
    <Link
      onClick={onClickHandler}
      {...props}
    >
      {children}
    </Link>
  )
}

export default CustomLink;