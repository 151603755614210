import React from "react"
import { useIntl } from "react-intl"

const Search = ({ search, setSearch }) => {
  const { formatMessage } = useIntl()
  return (
    <div className="search">
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={formatMessage({ id: "common.find" })}
      />
      {searchSvg}
    </div>
  )
}

const searchSvg = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3397 19.0625L14.9897 14.7125M17.3397 9.0625C17.3397 13.4808 13.758 17.0625 9.33972 17.0625C4.92144 17.0625 1.33972 13.4808 1.33972 9.0625C1.33972 4.64422 4.92144 1.0625 9.33972 1.0625C13.758 1.0625 17.3397 4.64422 17.3397 9.0625Z"
      stroke="#9A8787"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Search
