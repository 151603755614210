import classNames from "classnames"
import moment from "moment"
import React from "react"
import { Tooltip } from "../../../common"
import { useIntl } from "react-intl"

const InvoiceItem = ({ invoice }) => {
  const { formatMessage } = useIntl()
  return (
    <Tooltip
      tooltip={
        invoice.isUnpaid
          ? formatMessage({ id: "common.clickToPayInvoice" })
          : formatMessage({ id: "common.clickToViewInvoice" })
      }
    >
      <div
        className="invoice-item"
        onClick={() => window.open(invoice.invoiceUrl, "_blank")}
      >
        {icon}
        <p className="invoice-item__date tiny">
          {moment(new Date(invoice.date)).format("DD MMM YYYY")}
        </p>
        <p className="invoice-item__title tiny">{invoice.title}</p>
        <p
          className={classNames("invoice-item__amount tiny", {
            "invoice-item__amount--unpaid": invoice.isUnpaid,
          })}
        >
          {invoice.isUnpaid && formatMessage({ id: "common.due" })} €
          {invoice.isUnpaid ? invoice.remainingAmount : invoice.totalAmount}
        </p>
      </div>
    </Tooltip>
  )
}

const icon = (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19V3C15 2.46957 14.7893 1.96086 14.4142 1.58579C14.0391 1.21071 13.5304 1 13 1H9C8.46957 1 7.96086 1.21071 7.58579 1.58579C7.21071 1.96086 7 2.46957 7 3V19M3 5H19C20.1046 5 21 5.89543 21 7V17C21 18.1046 20.1046 19 19 19H3C1.89543 19 1 18.1046 1 17V7C1 5.89543 1.89543 5 3 5Z"
      stroke="#18A8D5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default InvoiceItem
