import classNames from "classnames"
import React from "react"
import { SubscriptionButton } from "../../../common"
import { GA_EVENTS } from "../../../../constants/analytics"
import { FormattedMessage, useIntl } from "react-intl"

const BillingItem = ({
  item,
  itemInfo,
  handleProductSelect,
  loading,
  trialCompletedAlready,
}) => {
  const onNextClick = (quantity = 1) => {
    handleProductSelect(item, quantity)
  }

  const { formatMessage } = useIntl()

  const subscriptionButtonLabel =
    itemInfo.isSubscription && trialCompletedAlready
      ? formatMessage({ id: "common.startPlan" })
      : formatMessage({ id: itemInfo.buttonText })

  return (
    <div className="billing-item">
      {itemInfo.isPopular && (
        <p className="billing-item__badge billing-item__badge--popular">
          <FormattedMessage id="common.mostPopularPack" />
        </p>
      )}

      <div className="billing-item__content">
        <p className="billing-item__title">{item.productName}</p>

        {/* // TODO: instead of using text, use database values */}
        {itemInfo.texts && itemInfo.texts.map((text, i) => (
          <p
            className={classNames("billing-item__text", {
              "billing-item__text--highlighted": text.highlighted,
            })}
          >
            <FormattedMessage id={text.text} values={{ amount: text.amount }} />
          </p>
        ))}

        <div className="billing-item__price-wrapper mt-4">
          <p className="billing-item__price">
            <span>€{item.totalPrice}</span> {item.currency}
            {/* <span>€{itemInfo.price}</span>{" "}
            <FormattedMessage id={itemInfo.unit} /> */}
          </p>
        </div>
      </div>

      <div className="billing-item__button-wrapper">
        {itemInfo.saves && (
          <p
            className={classNames("billing-item__saves", {
              "billing-item__saves--popular": itemInfo.isPopular,
              "billing-item__saves--lowcost": itemInfo.isLowcost,
            })}
          >
            <FormattedMessage id="common.saves" /> {itemInfo.saves}
          </p>
        )}

        <SubscriptionButton
          isLowcost={false}
          isPopular={itemInfo.isPopular}
          onClick={onNextClick}
          text={subscriptionButtonLabel}
          loading={loading}
          quantityInput={itemInfo.shouldChoseQuantity}
          variant={itemInfo.shouldChoseQuantity ? "secondary" : "primary"}
          trackingEvent={{
            ...GA_EVENTS.CHOOSE_PRICING.BILLING_SELECTED,
            label: `${subscriptionButtonLabel}: ${item.productName}`,
          }}
        />
      </div>
    </div>
  )
}

export default BillingItem
