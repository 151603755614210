import React, { useState } from "react"

const Tooltip = ({ children, tooltip }) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleMouseEnter = () => {
    setIsVisible(true)
  }

  const handleMouseLeave = () => {
    setIsVisible(false)
  }

  return (
    <div className="tooltip-container">
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>

      {isVisible && (
        <div className="tooltip">
          <p className="tooltip__content">{tooltip}</p>
          <div className="tooltip__arrow"></div>
        </div>
      )}
    </div>
  )
}

export default Tooltip
