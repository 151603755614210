// components/Sidebar.js
import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"

import { useProcessedCVsWebSocket } from "../../../services/useProcessedCVsWebSocket"
import { fetchCvs } from "../../../services/utils/fetchCVs"

import logo from "../../../assets/fribl-LOGO-white-aug23.svg"
import {
  GeneralSettingsSidebar,
  JobMatchSidebar,
  MatchesPageSidebar,
} from "./components"

const useFetchCVs = () => {
  const dispatch = useDispatch()
  const cvs = useSelector((state) => state.cv.cvs)

  useEffect(() => {
    if (!cvs) {
      fetchCvs(dispatch)
    }
  }, [dispatch, cvs])

  const cvsNotProcessed = !!cvs?.find((cv) => !cv.isProcessed)
  useProcessedCVsWebSocket(cvsNotProcessed)
}

const Sidebar = () => {
  const auth = useSelector((state) => state.auth)
  const location = useLocation()
  
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showJobMatchSidebar, setShowJobMatchSidebar] = useState(true)

  useFetchCVs();

  const pathname = location.pathname
  const showMatchesPageSidebar =
    pathname.includes("/matches") ||
    (pathname.includes("/candidates") && pathname.includes("/job-match"))

  const firstName = auth?.user?.name?.split(" ")[0]

  const content = showMatchesPageSidebar ? (
    <MatchesPageSidebar key={"SIDEBAR"} />
  ) : showJobMatchSidebar ? (
    <JobMatchSidebar />
  ) : (
    <GeneralSettingsSidebar back={() => setShowJobMatchSidebar(true)} />
  )

  return (
    <div className="sidebar">
      <div className="sidebar__imagecontainer">
        <Link to={"/job-match/select-cv"}>
          <img src={logo} alt="logo" className="sidebar__logo" />
        </Link>

        {!showMatchesPageSidebar && (
          <button
            className="sidebar__user-icon sidebar__user-icon--desktop"
            onClick={() => setShowJobMatchSidebar((prev) => !prev)}
          >
            {firstName}&nbsp;{userSvg}
          </button>
        )}

        {/* Hamburger */}
        <div
          className="sidebar__toggle"
          role={"button"}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <FontAwesomeIcon icon={faBars} color="white" size={"2x"} />
        </div>
      </div>

      <div className="sidebar__content">{content}</div>

      <div
        className={classNames("sidebar__backdrop", {
          "sidebar__backdrop--visible": showMobileMenu,
        })}
        onClick={() => setShowMobileMenu(false)}
      />

      <div
        onClick={() => setShowMobileMenu(false)}
        className={classNames("sidebar__content-mobile", {
          "sidebar__content-mobile--visible": showMobileMenu,
        })}
      >
        <div className="sidebar__mobile-header">
          <Link to={"/"}>
            <img src={logo} className="sidebar__logo" alt="logo" />
          </Link>

          {!showMatchesPageSidebar && (
            <button
              className="sidebar__user-icon sidebar__user-icon--mobile"
              onClick={(e) => {
                e.stopPropagation()
                setShowJobMatchSidebar((prev) => !prev)
              }}
            >
              {firstName}&nbsp;{userSvg}
            </button>
          )}

          <div
            className="sidebar__toggle"
            role={"button"}
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <FontAwesomeIcon icon={faClose} color="white" size={"2x"} />
          </div>
        </div>

        {content}
      </div>
    </div>
  )
}

const userSvg = (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 19.5V17.5C17 16.4391 16.5786 15.4217 15.8284 14.6716C15.0783 13.9214 14.0609 13.5 13 13.5H5C3.93913 13.5 2.92172 13.9214 2.17157 14.6716C1.42143 15.4217 1 16.4391 1 17.5V19.5M13 5.5C13 7.70914 11.2091 9.5 9 9.5C6.79086 9.5 5 7.70914 5 5.5C5 3.29086 6.79086 1.5 9 1.5C11.2091 1.5 13 3.29086 13 5.5Z"
      stroke="#18A8D5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Sidebar
