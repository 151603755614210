import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"
import { getIntegrationsAction } from "../../../redux/slices/integrationSlice"
import { Spinner } from "../../common"
import {
  ActiveIntegration,
  AddIntegrationForm,
  LeverConfigurationForm,
} from "./components"

const ATSIntegrationPage = () => {
  const { loading, integrations } = useSelector((state) => state.integrations)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { atsEnabled, enabledAts } = useSelector((state) => state.auth)

  useEffect(() => {
    !integrations && atsEnabled && dispatch(getIntegrationsAction())
  }, [dispatch])

  const handleBackClick = () => {
    navigate("/job-match/select-cv")
  }

  const hasIntegration = useMemo(() => integrations?.length > 0, [integrations])

  if (!atsEnabled) {
    return <Navigate to="/job-match/select-cv" />
  }

  const activeIntegration = integrations?.[0]

  return (
    <div className="ats">
      <div className="ats__wrapper">
        <button className="ats__back-btn" onClick={handleBackClick}>
          {backSvg}
        </button>
        {loading ? (
          <Spinner />
        ) : hasIntegration ? (
          <ActiveIntegration active={integrations[0]} />
        ) : (
          <AddIntegrationForm enabledAts={enabledAts} />
        )}
      </div>
      {activeIntegration?.name === "lever" && (
        <LeverConfigurationForm data={activeIntegration.data.config} />
      )}
    </div>
  )
}

const backSvg = (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 8H1.5M1.5 8L8.5 1M1.5 8L8.5 15"
      stroke="#18A8D5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ATSIntegrationPage
