import React, { useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { toast } from "react-toastify"

import { backToChoosePricing } from "../../../services/auth"
import { loginAction } from "../../../redux/slices/authSlice"

import { PaymentForm } from "./components"
import { CustomButton } from "../../common"
import { GA_EVENTS } from "../../../constants/analytics"
import { FormattedMessage, useIntl } from "react-intl"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const PaymentPage = () => {
  const auth = useSelector((state) => state.auth)
  const [backLoading, setBackLoading] = useState(false)
  const clientSecret = useSelector((state) => state.signup.clientSecret)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  if (!clientSecret) {
    return <Navigate to={"/"} />
  }

  const options = {
    // passing the client secret obtained from the server
    clientSecret,
  }

  const handleBackClick = async () => {
    setBackLoading(true)

    try {
      if (auth.onboardingStage !== "Complete") {
        const data = await backToChoosePricing()
        dispatch(loginAction(data))
        navigate("/choose-pricing")
      } else {
        navigate("/buy-more")
      }
    } catch (err) {
      toast.error(formatMessage({ id: "toast.failedToGoBackToChoosePricing" }))
    } finally {
      setBackLoading(false)
    }
  }


  return (
    <div className="payment-page">
      <div className="payment-page__wrapper">
        <CustomButton
          disabled={backLoading}
          className="payment-page__link"
          onClick={handleBackClick}
          trackingEvent={GA_EVENTS.PAYMENT.BACK}
        >
          <FormattedMessage id="common.back" />
        </CustomButton>
        <div className="payment-page__content">
          <Elements stripe={stripePromise} options={options}>
            <PaymentForm />
          </Elements>
        </div>
      </div>
    </div>
  )
}

export default PaymentPage
