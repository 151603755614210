import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"

import { isTokenExpired } from "../../../../util/util"
import { resendVerificationEmail, verifyEmail } from "../../../../services/auth"
import { loginAction } from "../../../../redux/slices/authSlice"
import { CustomButton, CustomLink, Spinner } from "../../../common"
import { GA_EVENTS } from "../../../../constants/analytics"
import { FormattedMessage, useIntl } from "react-intl"

const EmailVerificationSuccess = ({ token }) => {
  const [loading, setLoading] = useState(true)
  const [expired, setExpired] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [sendingNewTokenLoading, setSendingNewTokenLoading] = useState(false)
  const { formatMessage } = useIntl()

  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(true)

    const expired = isTokenExpired(token)
    setExpired(expired)

    if (expired) {
      setLoading(false)
    } else {
      // Verify token with server
      verifyEmail({ token })
        .then((data) => {
          dispatch(loginAction(data))

          setExpired(false)
          setLoading(false)
        })
        .catch((err) => {
          const isVerified = err?.response?.data?.isVerified
          if (isVerified) {
            setIsVerified(true)
          } else {
            setExpired(true)
          }
          setLoading(false)
        })
    }
  }, [token, dispatch])

  const parsedTokenData = JSON.parse(atob(token.split(".")[1]))

  const handleSendNewLink = () => {
    setSendingNewTokenLoading(true)
    resendVerificationEmail({ email: parsedTokenData.user.email })
      .then(() => {
        toast.success(formatMessage({ id: "toast.emailSentSuccessfully" }))
      })
      .catch(() => {
        toast.error(formatMessage({ id: "toast.errorSendingEmail" }))
      })
      .finally(() => {
        setSendingNewTokenLoading(false)
      })
  }

  if (sendingNewTokenLoading) {
    return (
      <div className="create-account">
        <div className="create-account__wrapper">
          {expiredSvg}
          <h1 className="create-account__title mb-8 mt-6">
            <span>
              <FormattedMessage id="message.pleaseWaitSendingVerificationLink" />
            </span>
          </h1>

          <Spinner />
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="create-account">
        <div className="create-account__wrapper">
          {expiredSvg}
          <h1 className="create-account__title mb-8 mt-6">
            <span>
              <FormattedMessage id="message.pleaseWaitVerifyingEmail" />
            </span>
          </h1>

          <Spinner />
        </div>
      </div>
    )
  }

  const badToken = !Boolean(parsedTokenData?.user?.email)

  if (badToken) {
    return (
      <div className="create-account">
        <div className="create-account__wrapper">
          {expiredSvg}
          <h1 className="create-account__title mb-8 mt-6">
            <span>
              <FormattedMessage id="common.tokenMalformed" />
            </span>
          </h1>

          <div className="create-account__content sent-to-email">
            <p>
              <span>
                <FormattedMessage id="common.contactSupport" />
              </span>
            </p>
          </div>
        </div>
      </div>
    )
  }

  if (expired) {
    return (
      <div className="create-account">
        <div className="create-account__wrapper">
          {expiredSvg}
          <h1 className="create-account__title mb-8 mt-6">
            <span>
              <FormattedMessage id="common.emailLinkExpired" />
            </span>
          </h1>

          <div className="create-account__content sent-to-email">
            <p>
              <span>
                <FormattedMessage id="common.noProblem" />
              </span>{" "}
              - <FormattedMessage id="message.emailNewLinkTo" />{" "}
              <span>{parsedTokenData?.user?.email}</span>
            </p>

            <CustomButton
              trackingEvent={GA_EVENTS.USER_REGISTRATION.EMAIL_LINK_EXPIRED}
              onClick={handleSendNewLink}
              className="btn btn--small primary mt-15 sent-to-email__button"
            >
              <FormattedMessage id="common.sendNewLink" />
            </CustomButton>
          </div>
        </div>
      </div>
    )
  }

  if (isVerified) {
    return (
      <div className="create-account">
        <div className="create-account__wrapper">
          {successSvg}
          <h1 className="create-account__title mb-8 mt-6">
            <span>
              <FormattedMessage id="common.emailAlreadyVerified" />
            </span>
          </h1>

          <div className="create-account__content sent-to-email">
            <p>
              <span>
                <FormattedMessage id="message.thanksForVerifyingEmail" />
              </span>
              &nbsp; <FormattedMessage id="common.proceedToLogin" />
            </p>
            <CustomLink
              trackingEvent={GA_EVENTS.USER_REGISTRATION.EMAIL_VERIFIED}
              to={"/login"}
              className="btn btn--small primary mt-15 sent-to-email__button"
            >
              <FormattedMessage id="common.login" />
            </CustomLink>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="create-account">
      <div className="create-account__wrapper">
        {successSvg}
        <h1 className="create-account__title mb-8 mt-6">
          <span>
            <FormattedMessage id="common.youAreIn" />
          </span>
        </h1>

        <div className="create-account__content sent-to-email">
          <p>
            <span>
              <FormattedMessage id="message.thanksForVerifyingEmail" />
            </span>
            &nbsp; <FormattedMessage id="message.letsGetYouSetUp" />
          </p>
          <CustomLink
            trackingEvent={
              GA_EVENTS.USER_REGISTRATION.EMAIL_VERIFICATION_DEFAULT
            }
            to={"/company-info"}
            className="btn btn--small primary mt-15 sent-to-email__button"
          >
             <FormattedMessage id="common.continue" />
          </CustomLink>
        </div>
      </div>
    </div>
  )
}

const successSvg = (
  <svg
    width="96"
    height="95"
    viewBox="0 0 96 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M94.4829 43.2486V47.5266C94.4772 57.554 91.2302 67.3108 85.2263 75.3421C79.2224 83.3733 70.7832 89.2486 61.1673 92.0917C51.5514 94.9348 41.2742 94.5934 31.8682 91.1184C22.4622 87.6434 14.4316 81.2209 8.97387 72.809C3.5162 64.397 0.923939 54.4461 1.58371 44.4405C2.24348 34.4348 6.11992 24.9105 12.6349 17.288C19.1499 9.66547 27.9544 4.35314 37.7352 2.14329C47.516 -0.0665538 57.7491 0.944482 66.9084 5.02562M94.4829 10.3266L47.9829 56.8731L34.0329 42.9231"
      stroke="#3FA06E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const expiredSvg = (
  <svg
    width="90"
    height="89"
    viewBox="0 0 90 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M88.9829 1L41.1329 48.85M88.9829 1L58.5329 88L41.1329 48.85M88.9829 1L1.98291 31.45L41.1329 48.85"
      stroke="#3FA06E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EmailVerificationSuccess
