import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js"
import { useSelector } from "react-redux"
import { CustomButton, Spinner } from "../../../../common"
import { useState } from "react"
import { GA_EVENTS } from "../../../../../constants/analytics"
import { FormattedMessage, useIntl } from "react-intl"

const PaymentForm = () => {
  const { isPurchaseAfterSignup, setupIntentId, invoiceId } = useSelector(
    (state) => state.signup,
  )
  const [loading, setLoading] = useState(false)
  const selectedProduct = useSelector((state) => state.signup.product)
  const productQuantity = useSelector((state) => state.signup.productQuantity)

  const stripe = useStripe()
  const elements = useElements()
  const { formatMessage } = useIntl()

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    let result

    setLoading(true)
    if (setupIntentId) {
      result = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url:
            window.location.href.split("?")[0].replace("/payment", "") +
            `/payment-confirmation?setupIntentId=${setupIntentId}`,
        },
      })
    } else {
      result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url:
            window.location.href.split("?")[0].replace("/payment", "") +
            `/payment-confirmation?invoiceId=${invoiceId}`,
        },
      })
    }

    setLoading(false)

    if (result.error) {
      alert(formatMessage({ id: "common.error" }).toLocaleUpperCase())
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  const submitButtonLabel = setupIntentId
    ? formatMessage({ id: "common.submitPaymentDetails" })
    : formatMessage(
        { id: "common.payTotalPrice" },
        { payTotalPrice: selectedProduct.totalPrice * (productQuantity || 1) },
      )

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {loading && (
        <div className="flex items-center justify-center m-4">
          <Spinner />
        </div>
      )}
      {selectedProduct.productType === "Subscription" &&
        !invoiceId &&
        !isPurchaseAfterSignup && (
          <p className="mt-2 text-center">
            <FormattedMessage
              id="message.subscriptionPayment"
              values={{
                totalPrice: selectedProduct.totalPrice,
              }}
            />
          </p>
        )}

      <CustomButton
        className="pending-payment__submit"
        disabled={!stripe || loading}
        trackingEvent={{
          ...GA_EVENTS.PAYMENT.SUBMIT,
          label: submitButtonLabel
        }}
      >
        {submitButtonLabel}
      </CustomButton>
    </form>
  )
}

export default PaymentForm
