import classNames from "classnames"
import React from "react"

const items = [
  {
    label: "up to 10 people",
    value: "Starter",
  },
  {
    label: "10-250 people",
    value: "Professional",
  },
  {
    label: "over 250 people",
    value: "Enterprise",
  },
]

const StaffNumberSelector = ({ value, onChange }) => {
  return (
    <div className="staff-selector">
      {items.map((item) => (
        <button
          onClick={() => onChange({ target: { value: item.value } })}
          className={classNames("staff-selector__item", {
            selected: value === item.value,
          })}
        >
          <span>{item.label}</span>
        </button>
      ))}
    </div>
  )
}

export default StaffNumberSelector
