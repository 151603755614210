import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  createIntegration,
  deleteIntegration,
  getIntegrations,
  getThirdPartyCandidates,
  getThirdPartyJobs,
  updateLeverConfig,
} from "./../../services/integrationService"
import { importCvs } from "../../services/cv"
import { PER_PAGE_GREENHOUSE_CANDIDATES } from "../../constants"
import { toast } from "react-toastify"
import intl from "../../i18n/intl"

const getIntegrationsAction = createAsyncThunk(
  "integrations/getAll",
  async () => {
    const response = await getIntegrations()
    return response.integrations
  },
)

const createIntegrationAction = createAsyncThunk(
  "integrations/create",
  async (body) => {
    await createIntegration(body)
    return null
  },
)

const updateLeverIntegrationConfigAction = createAsyncThunk(
  "integrations/lever-config-update",
  async (body) => {
    const { formatMessage } = intl()
    try {
      const data = await updateLeverConfig(body)
      toast.success(formatMessage({ id: "toast.leverConfigSuccess" }))
      return data.data
    } catch (err) {
      toast.error(formatMessage({ id: "toast.leverConfigFail" }))
      throw err
    }
  },
)
const deleteIntegrationAction = createAsyncThunk(
  "integrations/delete",
  async (name) => {
    await deleteIntegration(name)
    return null
  },
)

const getThirdPartyJobsAction = createAsyncThunk(
  "integrations/getJobs",
  async (body) => {
    const { jobs } = await getThirdPartyJobs(body)
    return jobs
  },
)

const getThirdPartyCandidatesAction = createAsyncThunk(
  "integrations/getCandidates",
  async (body) => {
    const { candidates } = await getThirdPartyCandidates(body)
    return candidates
  },
)

const getMoreThirdPartyCandidatesAction = createAsyncThunk(
  "integrations/getMoreCandidates",
  async (body) => {
    const { candidates } = await getThirdPartyCandidates(body)
    return candidates
  },
)

const importThirdPartyCandidatesAction = createAsyncThunk(
  "integrations/importCandidates",
  async (body) => {
    return await importCvs(body)
  },
)

const initialState = {
  integrations: null,
  loading: true,
  error: false,

  createIntegrationLoading: false,
  createIntegrationError: false,

  deleteIntegrationLoading: false,
  deleteIntegrationError: false,

  upateLeverConfigLoading: false,

  jobs: null,
  jobsLoading: false,
  jobsError: false,

  candidates: null,
  hasMoreCandidates: true,
  candidatesLoading: false,
  candidatesError: false,

  fetchMoreCandidatesLoading: false,

  importCandidatesLoading: false,
  importCandidatesError: false,
}

// Then, handle actions in your reducers:
const integrationSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetIntegrationSlice: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIntegrationsAction.pending, (state, action) => {
      state.loading = true
      state.error = false
      state.integrations = null
    })

    builder.addCase(getIntegrationsAction.fulfilled, (state, action) => {
      state.integrations = action.payload
      state.loading = false
    })
    builder.addCase(getIntegrationsAction.rejected, (state) => {
      state.loading = false
      state.error = false
    })

    builder.addCase(createIntegrationAction.pending, (state) => {
      state.createIntegrationLoading = true
      state.createIntegrationError = false
    })

    builder.addCase(createIntegrationAction.fulfilled, (state) => {
      state.createIntegrationLoading = false

      // Clean the state
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    })

    builder.addCase(createIntegrationAction.rejected, (state) => {
      state.createIntegrationLoading = false
      state.createIntegrationError = true
    })

    builder.addCase(getThirdPartyJobsAction.pending, (state) => {
      state.jobsLoading = true
      state.jobsError = false
      state.jobs = null
    })

    builder.addCase(getThirdPartyJobsAction.fulfilled, (state, action) => {
      state.jobs = action.payload
      state.jobsLoading = false
    })

    builder.addCase(getThirdPartyJobsAction.rejected, (state) => {
      state.jobsLoading = false
      state.jobsError = true
    })

    builder.addCase(getThirdPartyCandidatesAction.pending, (state) => {
      state.candidatesLoading = true
      state.candidatesError = false
      state.candidates = null
    })

    builder.addCase(
      getThirdPartyCandidatesAction.fulfilled,
      (state, action) => {
        state.candidates = action.payload
        state.hasMoreCandidates =
          action.payload.length === PER_PAGE_GREENHOUSE_CANDIDATES
        state.candidatesLoading = false
      },
    )

    builder.addCase(getThirdPartyCandidatesAction.rejected, (state) => {
      state.candidatesLoading = false
      state.candidatesError = true
    })

    builder.addCase(importThirdPartyCandidatesAction.pending, (state) => {
      state.importCandidatesLoading = true
      state.importCandidatesError = false
    })

    builder.addCase(importThirdPartyCandidatesAction.fulfilled, (state) => {
      state.importCandidatesLoading = false
    })

    builder.addCase(importThirdPartyCandidatesAction.rejected, (state) => {
      state.importCandidatesLoading = false
      state.importCandidatesError = true
    })

    builder.addCase(deleteIntegrationAction.pending, (state) => {
      state.deleteIntegrationLoading = true
      state.deleteIntegrationError = false
    })

    builder.addCase(deleteIntegrationAction.fulfilled, (state) => {
      state.deleteIntegrationLoading = false
      state.integrations = []
      state.jobs = null
      state.candidates = null
    })

    builder.addCase(deleteIntegrationAction.rejected, (state) => {
      state.deleteIntegrationLoading = false
      state.deleteIntegrationError = true
    })

    builder.addCase(getMoreThirdPartyCandidatesAction.pending, (state) => {
      state.fetchMoreCandidatesLoading = true
    })

    builder.addCase(
      getMoreThirdPartyCandidatesAction.fulfilled,
      (state, action) => {
        state.candidates = [...state.candidates, ...action.payload]
        state.hasMoreCandidates =
          action.payload.length === PER_PAGE_GREENHOUSE_CANDIDATES
        state.fetchMoreCandidatesLoading = false
      },
    )

    builder.addCase(getMoreThirdPartyCandidatesAction.rejected, (state) => {
      state.fetchMoreCandidatesLoading = false
    })

    builder.addCase(updateLeverIntegrationConfigAction.pending, (state) => {
      state.upateLeverConfigLoading = true
    })

    builder.addCase(
      updateLeverIntegrationConfigAction.fulfilled,
      (state, action) => {
        state.upateLeverConfigLoading = false
        state.integrations[0].data = action.payload.data
      },
    )

    builder.addCase(updateLeverIntegrationConfigAction.rejected, (state) => {
      state.upateLeverConfigLoading = false
    })
  },
})

export {
  getIntegrationsAction,
  createIntegrationAction,
  deleteIntegrationAction,
  getThirdPartyJobsAction,
  getThirdPartyCandidatesAction,
  getMoreThirdPartyCandidatesAction,
  importThirdPartyCandidatesAction,
  updateLeverIntegrationConfigAction,
}

export const { resetIntegrationSlice } = integrationSlice.actions
export default integrationSlice.reducer
