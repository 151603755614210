import React, { useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { SignupInput } from "../../common"
import { resetPassword } from "../../../services/auth"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"

function ResetPasswordPage() {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
    getValues,
    setError,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      password2: "",
    },
  })

  watch(["password", "password2"])

  const values = getValues()

  // Get token from url
  const url = new URL(window.location.href)
  const token = url.searchParams.get("token")

  const onSubmit = (data) => {
    if (values.password !== values.password2) {
      setError("password2", {
        type: "manual",
        message: formatMessage({ id: "common.passwordsDoNotMatch" }),
      })

      return
    }
    if (loading) {
      return
    }
    setLoading(true)
    resetPassword({ ...data, token: token })
      .then(() => {
        setLoading(false)
        toast.success(formatMessage({ id: "toast.passwordResetSuccessfully" }))
        navigate("/login")
      })
      .catch((err) => {
        const error = err.response?.data?.message
        toast.error(error)
      })
      .finally(() => setLoading(false))
  }

  if (!token) {
    return <Navigate to={"/login"} />
  }

  return (
    <div className="login-page">
      <div className="login-page__wrapper">
        <h1 className="login-page__title ">
          <FormattedMessage id="common.createNewPassword" />
        </h1>
        <form className="login-page__content" onSubmit={handleSubmit(onSubmit)}>
          <div className="login-page__inputs">
            <SignupInput
              register={register("password", {
                required: formatMessage({ id: "common.passwordRequired" }),
              })}
              placeholder={formatMessage({ id: "common.newPassword" })}
              error={errors.password?.message}
              touched={touchedFields.password}
              value={values.password}
              type="password"
              helperText={formatMessage({
                id: "message.passwordSafetyRequirement",
              })}
            />
            <SignupInput
              register={register("password2", {
                required: formatMessage({ id: "common.passwordRequired" }),
              })}
              placeholder={formatMessage({ id: "common.retypeYourPassword" })}
              error={errors.password2?.message}
              touched={touchedFields.password2}
              value={values.password2}
              type="password"
              helperText={formatMessage({
                id: "message.passwordSafetyRequirement",
              })}
            />
          </div>

          <button
            className={classNames("btn btn--small primary", {
              disabled: !isValid || loading,
            })}
            onClick={handleSubmit(onSubmit)}
          >
            <FormattedMessage id="common.resetPassword" />
          </button>
        </form>
      </div>
    </div>
  )
}

export default ResetPasswordPage
