import React, { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"

import CandidateScore from "../../../common/Candidate"
import { getCV } from "../../../../services/cv"
import { CV, CustomButton, Spinner } from "../../../common"
import Profile from "./Profile"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { getRating } from "../../../../util/util"
import { GA_EVENTS } from "../../../../constants/analytics"
import { FormattedMessage, useIntl } from "react-intl"

const actionItems = [
  {
    name: "common.matchScore",
    key: "job-match",
    trackingEvent: GA_EVENTS.JOB_MATCHES.ACTION_MATCH_SCORE,
  },
  {
    name: "common.profile",
    key: "profile",
    trackingEvent: GA_EVENTS.JOB_MATCHES.ACTION_PROFILE_TAB,
  },
  {
    name: "common.cv",
    key: "cv",
    trackingEvent: GA_EVENTS.JOB_MATCHES.ACTION_CV,
  },
]

const Candidate = ({ tab, jobQa, selectedCandidateInfo, switchTab }) => {
  const [cvData, setCV] = useState(null)
  const [loading, setLoading] = useState(false)
  const { formatMessage } = useIntl()

  const cvs = useSelector((state) => state.cv.cvs)

  const id = selectedCandidateInfo?.id

  useEffect(() => {
    if (loading || cvData) {
      return
    }

    if (id && tab !== "job-match") {
      setLoading(true)
      getCV(id)
        .then((res) => {
          setCV(res.data)
        })
        .catch((err) => {
          switchTab("job-match")
          toast.error(
            err?.response?.data?.message ||
              formatMessage({ id: "toast.failedToFetchCV" }),
          )
        })
        .finally(() => setLoading(false))
    }
  }, [id, tab])


  const jobScore = Math.round(selectedCandidateInfo?.score)

  const exists = useMemo(() => cvs?.find((cv) => cv.id === id), [cvs, id])

  return (
    <>
      <div className="matches-page__content">
        <div className="matches-page__header">
          {tab === "job-match" && (
            <div style={{ background: "white" }} className={"score"}>
              <div style={{ opacity: jobScore / 10 }} className={"score"}>
                {jobScore}
              </div>
            </div>
          )}
          <div className="matches-page__header-info">
            <h2 className="matches-page__name h2">
              {`${selectedCandidateInfo?.cv?.information?.first_name} ${selectedCandidateInfo?.cv?.information?.last_name}`}
            </h2>
            {tab === "job-match" && (
              <h2 className="matches-page__name h2">{getRating(jobScore)}</h2>
            )}
          </div>

          <div className="actions">
            <div className="matches-page__nav-btns">
              {actionItems.slice(0, exists ? undefined : 1).map((action) => (
                <CustomButton
                  key={action.key}
                  className={classNames("matches-page__nav-btn", {
                    selected: tab === action.key,
                  })}
                  onClick={() => switchTab(action.key)}
                  trackingEvent={action.trackingEvent}
                >
                  <FormattedMessage id={action.name} />
                </CustomButton>
              ))}
            </div>
          </div>
        </div>

        {tab === "job-match" && (
          <CandidateScore jobQa={jobQa} data={selectedCandidateInfo} />
        )}

        {["cv", "profile"].includes(tab) && !cvData && <Spinner />}

        {tab === "cv" && cvData?.cv_url && <CV url={cvData?.cv_url} />}

        {tab === "profile" && cvData && (
          <Profile
            cv={cvData}
            jobData={selectedCandidateInfo}
            showCheckbox={false}
          />
        )}
      </div>
    </>
  )
}

export default Candidate
