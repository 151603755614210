import { toast } from "react-toastify"
import { getCvs } from "../cv"
import { setCvs } from "../../redux/slices/cvSlice"
import intl from "../../i18n/intl"

export const fetchCvs = async (dispatch, callback = () => {}) => {
  getCvs()
    .then((cvs) => {
      callback()
      dispatch(setCvs(cvs))
    })
    .catch(() => {
      const { formatMessage } = intl()
      toast.error(formatMessage({ id: "toast.fetchCVsFail" }))
    })
}
